import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ScheduledReport } from "./Models";

export function useScheduledReports(customerID: number | undefined) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied customerID
  const queryKey = keys.queryKey(customerID);

  return useQuery<Array<ScheduledReport>, Error>(
    queryKey,
    async ({ signal }) => {
      //now make the actual data call
      const { data } = await ax.get<Array<ScheduledReport>>(`schedreports/yatsinfo/${customerID}`, {
        signal,
      });
      return data;
    },
    {
      enabled: !!customerID,
    }
  );
}
