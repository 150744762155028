import React, { useCallback, useRef } from "react";
import { Box, Button, Divider, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import ContentSection from "../../components/content-section/ContentSection";
import { CallUrlRequest } from "../../queries/call-url/CallUrlRequest";
import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import { faPaperPlane } from "@elynx/pro-regular-svg-icons";
import { useCallUrl } from "../../queries/call-url/UseCallUrl";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";

const defaultCallUrlRequest: CallUrlRequest = {
  verb: 2,
  url: "copies/copyTagData",
  data: "",
};

export default function EnvironmentConfiguration() {
  const theme = useTheme();
  const { isOpsCenterWriterMember } = useUserRole();
  const [callUrlRequest, setCallUrlRequest] = React.useState(defaultCallUrlRequest);
  const [editorTextIsValid, setEditorTextIsValid] = React.useState(false);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const { mutate: callUrl } = useCallUrl();
  const { displayToast } = useToastNotification();

  const onActionButtonClicked = useCallback(() => {
    //if we have mounted the editor (we should have, but just in case)
    if (editorRef.current) {
      //get the current editor value
      const currentCallUrlRequest = {
        ...callUrlRequest,
        data: editorRef.current.getValue(),
      } as CallUrlRequest;

      //if we have a non-blank url and non-blank data
      //call the url
      if (currentCallUrlRequest.url.trim() !== "" && currentCallUrlRequest.data.trim() !== "") {
        callUrl(currentCallUrlRequest, {
          onSuccess: () =>
            displayToast({
              message: `Called URL ${currentCallUrlRequest.url} successfully`,
              severity: "success",
            }),
          onError: (error) =>
            displayToast({
              message: `Error calling URL ${currentCallUrlRequest.url}: ${error}`,
              severity: "error",
              duration: 15000,
            }),
        });
      } else {
        setEditorTextIsValid(false);
      }

      //in any case, save the current request
      setCallUrlRequest(currentCallUrlRequest);
    }
  }, []);

  //monaco editor callbacks
  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleEditorValidation = useCallback((markers: Array<monaco.editor.IMarker>) => {
    if (markers.length > 0) {
      setEditorTextIsValid(false);
    } else {
      setEditorTextIsValid(true);
    }
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"Call URL"} />
        <Stack
          direction="row"
          spacing={3}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            select
            variant="filled"
            label="HTTP Method"
            value={callUrlRequest.verb}
            onChange={(e) => setCallUrlRequest({ ...callUrlRequest, verb: +e.target.value })}
            sx={{ width: "16ch" }}
          >
            <MenuItem value={2}>POST</MenuItem>
            <MenuItem value={1}>GET</MenuItem>
          </TextField>
          <TextField
            size="small"
            variant="filled"
            label="URL"
            value={callUrlRequest.url}
            onChange={(e) => setCallUrlRequest({ ...callUrlRequest, url: e.target.value })}
            sx={{ width: "36ch" }}
          />
          <Box
            sx={{
              "& > button": { m: 1 },
              display: "flex",
              width: "12ch",
              marginLeft: "auto !important",
              marginRight: "1em !important",
            }}
          >
            <Button
              disabled={!editorTextIsValid}
              onClick={() => onActionButtonClicked()}
              endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              variant="contained"
            >
              {callUrlRequest.verb === 2 ? "Post" : "Get"}
            </Button>
          </Box>
        </Stack>
        <ContentSection>
          {isOpsCenterWriterMember && (
            <Editor
              height="100%"
              theme={theme.palette.mode === "dark" ? "vs-dark" : "light"}
              defaultLanguage="json"
              defaultValue={callUrlRequest.data}
              onMount={handleEditorDidMount}
              onValidate={handleEditorValidation}
            />
          )}
          {!isOpsCenterWriterMember && (
            <Box sx={{ padding: "1em" }}>
              <p>You do not have permission to call URLs. Please contact DevOps if you need this permission.</p>
            </Box>
          )}
        </ContentSection>
      </ContentView>
    </>
  );
}
