import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Autocomplete, TextField, FormControlLabel, Checkbox, Button, MenuItem, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack, Box, useTheme } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import { ETDOrganizationData } from "../../queries/tag-data-export/Models";
import { useETDOrganizationData } from "../../queries/tag-data-export/UseETDOrganizationData";
import { defaultFirstLastTagValueRequest } from "../../queries/tag-value/FirstLastTagValueRequest";
import { tagValueActionReducer, TagValueActionTypeEnum } from "./TagValueActions";
import { useFirstLastTagValueData } from "../../queries/tag-value/UseFirstLastTagValueData";
import { FirstLastTagValueData } from "../../queries/tag-value/Models";
import { queryClient } from "../../services/query-client-service/QueryClientService";
import { keys } from "../../queries/tag-value/Keys";
import isEqual from "react-fast-compare";
import { ColDef } from "ag-grid-community";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { faLineColumns } from "@elynx/pro-regular-svg-icons";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";
import { TagActionRequest } from "./TagActionRequest";
import { TagActionEnum } from "./TagActionEnum";
import CalcFKVDialog from "./CalcFKVDialog";
import TagActionDialog from "./TagActionDialog";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { DataTypeEnum } from "../../queries/tag-value/DataTypeEnum";

export default function TagValue() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { isOpsCenterWriterMember } = useUserRole();
  const confirm = useConfirm();
  const [tagActionDialogOpen, setTagActionDialogOpen] = useState(false);
  const tagActionRequestRef = useRef<TagActionRequest | null>(null);
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [organizationList, setOrganizationList] = useState<Array<ETDOrganizationData>>([]);
  const [tagValues, setTagValues] = useState<Array<FirstLastTagValueData>>([]);
  const [deviceQueriesEnabled, setDeviceQueriesEnabled] = useState(false);
  const [retrieveData, setRetrieveData] = useState(false);
  const [firstLastTagValueRequest, dispatchFirstLastTagValueRequest] = useReducer(
    tagValueActionReducer,
    defaultFirstLastTagValueRequest
  );
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultFirstLastTagValueRequest);
  const [calcFKVDialogOpen, setCalcFKVDialogOpen] = useState(false);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();
  const { data: newOrganizationList, isSuccess: isOrganizationSuccess } = useETDOrganizationData({
    customerID: firstLastTagValueRequest.customerID ?? 0,
    enabled: deviceQueriesEnabled,
  });

  const {
    data: newTagValueData,
    isFetching,
    isSuccess,
    refetch,
  } = useFirstLastTagValueData({
    enabled: retrieveData,
    firstLastTagValueRequest: runningQueryProperties,
  });

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    setDeviceQueriesEnabled(firstLastTagValueRequest?.customerID ? true : false);
  }, [firstLastTagValueRequest?.customerID]);

  useEffect(() => {
    if (isOrganizationSuccess === true && newOrganizationList && newOrganizationList.length > 0) {
      const orgList = [
        { organizationID: -1, organizationName: "(All)", organizationNameAndID: "(All)" } as ETDOrganizationData,
      ].concat(newOrganizationList.sort((a, b) => a.organizationName.localeCompare(b.organizationName)));
      setOrganizationList(orgList);
    }
  }, [newOrganizationList, isOrganizationSuccess]);

  //if the user changes the customer, organization, or any flags
  //wipe out current data to avoid confusion
  //and cancel a running query, if there is one
  //since the reducer always returns a new object
  //we need to compare the query request properties
  //in case the user goes back to what they had before
  useEffect(() => {
    setTagValues([]);
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
    }

    //don't retrieve new data until the <Refresh> button is clicked
    setRetrieveData(false);

    //because of structural sharing in React Query
    //we need to remove the current query from the cache
    //in case the user goes back to these same propertes and click <Refresh>
    //if we don't do this, newTagValueData will not have "changed",
    //i.e. it will reference the same data as before and won't trigger the next useEffect()
    queryClient.removeQueries(keys.queryKey(runningQueryProperties));
  }, [
    firstLastTagValueRequest.customerID,
    firstLastTagValueRequest.organizationID,
    firstLastTagValueRequest.disabledDevices,
    firstLastTagValueRequest.disabledTags,
    firstLastTagValueRequest.hiddenDevices,
    firstLastTagValueRequest.hiddenTags,
  ]);

  //save the new data
  useEffect(() => {
    if (newTagValueData) {
      setTagValues(newTagValueData);
    }
  }, [isSuccess, newTagValueData]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, firstLastTagValueRequest)) {
        setRunningQueryProperties(firstLastTagValueRequest);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Selected",
        colId: "selected",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 100,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.isSelected() === nodeB.isSelected()) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else {
            return nodeA.isSelected() ? 1 : -1;
          }
        },
      },
      {
        headerName: "Object Type",
        field: "obj_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "notContains", "equals"],
          buttons: ["reset", "apply"],
          debounceMs: 300,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Object Name",
        field: "obj_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "notContains", "equals"],
          debounceMs: 300,
          suppressAndOrCondition: false,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Object ID",
        field: "obj_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Object GUID",
        field: "obj_guid",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: false,
        },
      },
      {
        headerName: "Tag Name",
        field: "tag_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "notContains", "equals"],
          debounceMs: 300,
          suppressAndOrCondition: false,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Tag ID",
        field: "tag_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Tag GUID",
        field: "tag_guid",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: false,
        },
      },
      {
        headerName: "Tat Name",
        field: "tat_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "notContains", "equals"],
          debounceMs: 300,
          suppressAndOrCondition: false,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Tat ID",
        field: "tat_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Data Type",
        field: "tat_datatype",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "First Known Value",
        field: "fkv_data_value",
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "FKV Timestamp",
        field: "fkv_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Last Known Value",
        field: "lkv_data_value",
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "LKV Timestamps",
        field: "lkv_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Object Enabled",
        field: "obj_enabled",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        editable: false,
        hide: !(
          firstLastTagValueRequest.disabledDevices ||
          firstLastTagValueRequest.hiddenDevices ||
          firstLastTagValueRequest.disabledTags ||
          firstLastTagValueRequest.hiddenTags
        ),
      },
      {
        headerName: "Object Visible",
        field: "obj_visible",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        editable: false,
        hide: !(
          firstLastTagValueRequest.disabledDevices ||
          firstLastTagValueRequest.hiddenDevices ||
          firstLastTagValueRequest.disabledTags ||
          firstLastTagValueRequest.hiddenTags
        ),
      },
      {
        headerName: "Tag Enabled",
        field: "tag_enabled",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        editable: false,
        hide: !(
          firstLastTagValueRequest.disabledDevices ||
          firstLastTagValueRequest.hiddenDevices ||
          firstLastTagValueRequest.disabledTags ||
          firstLastTagValueRequest.hiddenTags
        ),
      },
      {
        headerName: "Tag Visible",
        field: "tag_visible",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        editable: false,
        hide: !(
          firstLastTagValueRequest.disabledDevices ||
          firstLastTagValueRequest.hiddenDevices ||
          firstLastTagValueRequest.disabledTags ||
          firstLastTagValueRequest.hiddenTags
        ),
      },
    ];
  }, [firstLastTagValueRequest]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const getExportFileName = useCallback(() => {
    return `FirstAndLastValues_CID${runningQueryProperties.customerID}`;
  }, [runningQueryProperties]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows: Array<FirstLastTagValueData> | undefined = gridRef.current?.api.getSelectedRows();
    if (selectedRows) {
      dispatchFirstLastTagValueRequest({
        type: TagValueActionTypeEnum.SetSelectedTags,
        tagIDs: selectedRows.map((t) => t.tag_id),
      });
    }
  }, []);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `${getExportFileName()}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "lkv_timestamp" || colId == "fkv_timestamp"
            ? FormatTimestampLocal(params.value)
            : params.value?.toString();
        }
      },
    });
  }, [runningQueryProperties]);

  const handleTagActionButtonClick = (action: TagActionEnum) => {
    //get the set of tags here so we can check for a mix of data types
    const tags = tagValues.filter((t) => firstLastTagValueRequest.tagIDs.some((i) => i === t.tag_id));

    //make sure there are some active devices and tags
    //if not, do not let the user proceed
    if (!tags.some((t) => (null == t.obj_enabled || t.obj_enabled === true) && t.tag_enabled)) {
      confirm({
        title: `Cannot ${action}`,
        description: `Cannot ${action} on devices and tags unless they are enabled`,
        allowClose: false,
        hideCancelButton: true,
      });
    } else {
      //if this is a tag value reset
      //and there is a mix of data types, do not let the user proceed
      if (
        TagActionEnum.ResetDataValues === action &&
        tags.some((t) => DataTypeEnum.Numeric === t.tat_datatype) &&
        tags.some((t) => DataTypeEnum.String === t.tat_datatype)
      ) {
        //cheat and use the confirmation dialog simply to provide feedback
        confirm({
          title: "Cannot reset data values",
          description: "Data values can be reset for only one type of data (Double or String) at a time",
          allowClose: false,
          hideCancelButton: true,
        });
      } else {
        tagActionRequestRef.current = {
          action: action,
          tags: tags,
        } as TagActionRequest;

        setTagActionDialogOpen(true);
      }
    }
  };

  const closeTagActionDialog = useCallback(() => {
    //since leaving tags selected seems to confuse Randy and Chris
    //clear any current selections when the TagActionDialog is closed
    gridRef.current?.api.deselectAll();
    setTagActionDialogOpen(false);
  }, []);

  return (
    <ContentView>
      <ContentHeader title="Tag Values and Actions" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            if (value) {
              dispatchFirstLastTagValueRequest({
                type: TagValueActionTypeEnum.SetCustomer,
                customerID: value.id,
              });
            }
          }}
          sx={{ width: "42ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Organization"
          value={firstLastTagValueRequest?.organizationID ?? ""}
          onChange={(e) =>
            dispatchFirstLastTagValueRequest({
              type: TagValueActionTypeEnum.SetOrganization,
              organizationID: +e.target.value,
            })
          }
          sx={{ width: "30ch" }}
        >
          {organizationList?.map((o, inx) => (
            <MenuItem key={inx} value={o.organizationID}>
              {o.organizationNameAndID}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={firstLastTagValueRequest.disabledDevices}
              onChange={(e) =>
                dispatchFirstLastTagValueRequest({
                  type: TagValueActionTypeEnum.SetIncludeDisabledDevices,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Disabled Devices"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={firstLastTagValueRequest.hiddenDevices}
              onChange={(e) =>
                dispatchFirstLastTagValueRequest({
                  type: TagValueActionTypeEnum.SetIncludeHiddenDevices,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Hidden Devices"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={firstLastTagValueRequest.disabledTags}
              onChange={(e) =>
                dispatchFirstLastTagValueRequest({
                  type: TagValueActionTypeEnum.SetIncludeDisabledTags,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Disabled Tags"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={firstLastTagValueRequest.hiddenTags}
              onChange={(e) =>
                dispatchFirstLastTagValueRequest({
                  type: TagValueActionTypeEnum.SetIncludeHiddenTags,
                  include: e.target.checked,
                })
              }
            />
          }
          label="Hidden Tags"
          labelPlacement="end"
        />
        <FontAwesomeIconButton
          icon={faLineColumns}
          iconSize={32}
          title="Size all columns to fit data"
          onClick={() => autoSizeColumns()}
          sx={{ marginLeft: "24px !important" }}
        />
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "24px",
          }}
        >
          <Button onClick={handleExportCSVClick} variant="contained" disabled={!(tagValues && tagValues.length > 0)}>
            Export CSV
          </Button>
        </Box>
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "14ch",
            marginLeft: "auto !important",
            marginRight: "1em !important",
          }}
        >
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>

        {
          /* Members of the OpsCenterWriter group get to perform actions controlled
        by these buttons */
          isOpsCenterWriterMember && (
            <>
              {/* force a new row */}
              <Box sx={{ flexBasis: "100%", height: "0" }} />
              <Box
                sx={{
                  "& > button": { m: 1 },
                  display: "flex",
                  width: "22ch",
                  marginLeft: "20px !important",
                }}
              >
                <Button
                  onClick={() => setCalcFKVDialogOpen(true)}
                  variant="contained"
                  disabled={firstLastTagValueRequest?.customerID == null}
                >
                  Run FKV Service
                </Button>
              </Box>
              <Box
                sx={{
                  "& > button": { m: 1 },
                  display: "flex",
                  width: "27ch",
                  marginLeft: "15px !important",
                }}
              >
                <Button
                  fullWidth={true}
                  onClick={() => handleTagActionButtonClick(TagActionEnum.RecalcContractDays)}
                  variant="contained"
                  disabled={!(firstLastTagValueRequest.tagIDs.length > 0)}
                >
                  Re-calc Contract Days
                </Button>
              </Box>
              <Box
                sx={{
                  "& > button": { m: 1 },
                  display: "flex",
                  width: "25ch",
                  marginLeft: "15px !important",
                }}
              >
                <Button
                  fullWidth={true}
                  onClick={() => handleTagActionButtonClick(TagActionEnum.RecalcFormulas)}
                  variant="contained"
                  disabled={!(firstLastTagValueRequest.tagIDs.length > 0)}
                >
                  Re-calc Formulas
                </Button>
              </Box>
              <Box
                sx={{
                  "& > button": { m: 1 },
                  display: "flex",
                  width: "25ch",
                  marginLeft: "15px !important",
                }}
              >
                <Button
                  fullWidth={true}
                  onClick={() => handleTagActionButtonClick(TagActionEnum.ResetDataValues)}
                  variant="contained"
                  disabled={!(firstLastTagValueRequest.tagIDs.length > 0)}
                >
                  Reset Data Values
                </Button>
              </Box>
            </>
          )
        }
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={tagValues}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            onSelectionChanged={onSelectionChanged}
            onFirstDataRendered={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            columnMenu="legacy"
          />
        </div>
      </Box>

      {/* This dialog shows the calc FKV dialog */}
      {firstLastTagValueRequest.customerID != null && (
        <CalcFKVDialog
          customerId={firstLastTagValueRequest.customerID ?? 0}
          open={calcFKVDialogOpen}
          onClose={() => setCalcFKVDialogOpen(false)}
        />
      )}

      {/* This dialog shows the Tag Action Dialog */}
      {tagActionRequestRef.current && (
        <TagActionDialog
          open={tagActionDialogOpen}
          onClose={() => closeTagActionDialog()}
          action={tagActionRequestRef.current.action}
          customerId={firstLastTagValueRequest.customerID ?? 0}
          tags={tagActionRequestRef.current.tags}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs.filter((c) => c.colId !== "selected")}
        />
      )}
    </ContentView>
  );
}
