import React, { useEffect, useState } from "react";
import { TatActionEnum, TatInfoItem } from "../../../queries/copy-device/Models";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";

export type SelectTatProperties = {
  tatInfo: TatInfoItem;
  tatList: Array<AutocompleteOption>;
  open: boolean;
  onClose: () => void;
  selectTat: (tatInfo: TatInfoItem) => void;
};

export default function SelectTat({ tatInfo, tatList, open, onClose, selectTat }: SelectTatProperties) {
  const [selectedTat, setSelectedTat] = useState<AutocompleteOption>({ id: -1, label: "" });

  //if we have just been opened, reset our selected TAT
  useEffect(() => {
    setSelectedTat(
      null != tatInfo.dst_tat_name && null != tatInfo.dst_tat_id
        ? { id: tatInfo.dst_tat_id, label: tatInfo.dst_tat_name }
        : { id: -1, label: "" }
    );
  }, [open]);

  const onClick = () => {
    //create a new TatInfoItem with the selected ID
    //and send it to the passed in function and close the dialog
    const newTatInfo = {
      ...tatInfo,
      dst_tat_id: selectedTat.id > 0 ? selectedTat.id : null,
      dst_tat_name: selectedTat.label,
      tat_action: selectedTat.id > -1 ? TatActionEnum.Copy : TatActionEnum.DoNotCopy,
    } as TatInfoItem;
    selectTat(newTatInfo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Select Destination Tat</DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          autoHighlight
          size="small"
          options={tatList}
          getOptionLabel={(option) => (option.id > 0 ? `${option.label} [${option.id}]` : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedTat}
          onChange={(event, value) => {
            if (value) {
              setSelectedTat(value);
            } else {
              setSelectedTat({ id: -1, label: "" });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Destination TAT" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
