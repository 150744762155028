import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { ConfirmOptions } from "./ConfirmOptions";

export type ConfirmationDialogProps = {
  options: ConfirmOptions;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
};
export default function ConfirmationDialog({ open, options, onCancel, onConfirm, onClose }: ConfirmationDialogProps) {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    confirmationKeyword,
    confirmationKeywordTextFieldProps,
    hideCancelButton,
  } = options;

  const [confirmationKeywordValue, setConfirmationKeywordValue] = useState("");

  const confirmationButtonDisabled =
    !!confirmationKeyword && confirmationKeyword.length > 0 && confirmationKeywordValue !== confirmationKeyword;

  const confirmationContent = (
    <>
      {confirmationKeyword && (
        <TextField
          onChange={(e) => setConfirmationKeywordValue(e.target.value)}
          value={confirmationKeywordValue}
          fullWidth
          {...confirmationKeywordTextFieldProps}
        />
      )}
    </>
  );

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : undefined}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
          {confirmationContent}
        </DialogContent>
      ) : description ? (
        <DialogContent {...contentProps}>
          <DialogContentText>{description}</DialogContentText>
          {confirmationContent}
        </DialogContent>
      ) : (
        confirmationKeyword && <DialogContent {...contentProps}>{confirmationContent}</DialogContent>
      )}
      <DialogActions {...dialogActionsProps}>
        {!hideCancelButton && (
          <Button {...cancellationButtonProps} onClick={onCancel}>
            {cancellationText}
          </Button>
        )}
        <Button color="primary" disabled={confirmationButtonDisabled} {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
