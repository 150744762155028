import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AutoWrapHeaderGrid from "../../components/wrap-header-grid/AutoWrapHeaderGrid";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { AllUserModel, AllUsersQueryProperties } from "../../queries/all-users/Models";
import { useAllUsers } from "../../queries/all-users/UseAllUsers";
import { ColDef } from "ag-grid-community";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import ContentSection from "../../components/content-section/ContentSection";
import useDebounce from "../../hooks/use-debounce/UseDebounce";
import { parseISO } from "date-fns";
import { faTableCells, faTableCellsLarge, faLineColumns } from "@elynx/pro-light-svg-icons";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";
import { ReportTypeEnum, SystemUsageData, SystemUsageQueryProperties } from "../../queries/system-usage/Models";
import { useSystemUsageData } from "../../queries/system-usage/UseSystemUsageData";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import { leftOuterJoinCombined } from "../../utilities/Joins";
import { CustomerTypeEnum } from "../../queries/customer/Models";

const defaultSystemUsageQueryProperties = {
  reportType: ReportTypeEnum.UserLastAccess,
  timeframe: 7,
  startDate: null,
  endDate: null,
  includeElynx: false,
  customerType: CustomerTypeEnum.AllCustomers,
} as SystemUsageQueryProperties;

type UserDisplayData = AllUserModel & SystemUsageData;

export default function AllUserList() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const shouldResize = useRef(true);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [userDisplayData, setUserDisplayData] = useState<Array<UserDisplayData>>([]);
  const [userQueryProperties, setUserQueryProperties] = useState<AllUsersQueryProperties>({
    includeDisabled: false,
    includeElynx: false,
    customerType: CustomerTypeEnum.AllCustomers,
  });
  const [systemUsageQueryProperties, setSystemUsageQueryProperties] = useState(defaultSystemUsageQueryProperties);
  const [simpleMode, setSimpleMode] = useState(false);
  const [apiKeyFilter, setApiKeyFilter] = useState("");
  const debouncedFilter = useDebounce(apiKeyFilter, 300);
  const { data: newUserList, isSuccess: isAllUsersSuccess } = useAllUsers(userQueryProperties);
  const { data: newUsageData, isSuccess: isUsageDataSuccess } = useSystemUsageData({
    enabled: true,
    systemUsageQueryProperties: systemUsageQueryProperties,
  });

  //if we get a new user list, put it in our state
  useEffect(() => {
    if (newUserList && newUsageData) {
      //do a left outer join between the user list and the usage data
      const newUserDisplayData = leftOuterJoinCombined(
        newUserList,
        newUsageData,
        (l) => `${l.glcbr_customer_id}-${l.glcbr_user_id}`,
        (r) => `${r.customerID}-${r.userID}`
      );

      //now store the result of that left outer join
      setUserDisplayData(newUserDisplayData);
    }
    //to get a display faster, start with just the user list
    //and no usage data if that is what we have
    else if (newUserList) {
      const newUserDisplayData = newUserList.map((u) => {
        return { ...u } as AllUserModel & SystemUsageData;
      });
      setUserDisplayData(newUserDisplayData);
    }
  }, [isAllUsersSuccess, newUserList, isUsageDataSuccess, newUsageData]);

  //setup all the columns - lots of them!
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Global Login Name",
        headerTooltip: "Global Login Name",
        field: "gl_login_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "User Name",
        headerTooltip: "User Name",
        field: "user_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "External Login Name",
        headerTooltip: "External Login Name",
        field: "gl_external_signin_name",
        hide: simpleMode,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Customer ID",
        headerTooltip: "Customer ID",
        field: "glcbr_customer_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: "Customer Name",
        headerTooltip: "Customer Name",
        field: "glcbr_customer_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Customer Type",
        field: "cust_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
      },
      {
        headerName: "Enabled",
        headerTooltip: "Enabled in customer",
        field: "glcbr_enabled",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        minWidth: 120,
      },
      {
        headerName: "Set PWD GUID",
        headerTooltip: "GUID for initial login or forgot password",
        field: "gl_GUID",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Password Hash",
        headerTooltip: "User's password hash",
        field: "password_hash",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Primary API Key",
        headerTooltip: "Primary API Key",
        field: "glcbr_api_key",
        hide: simpleMode,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Primary API Key Expiration",
        headerTooltip: "Primary API key expiration timestamp",
        field: "glcbr_api_key_expiration",
        hide: simpleMode,
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Secondary API Key",
        headerTooltip: "Secondary API Key",
        field: "glcbr_api_key_secondary",
        hide: simpleMode,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Secondary API Key Expiration",
        headerTooltip: "Secondary API key expiration timestamp",
        field: "glcbr_api_key_secondary_expiration",
        hide: simpleMode,
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "MFA Source",
        headerTooltip: "MFA Source",
        valueGetter: (params) => {
          return 1 == params.data.glcbr_mfa_source
            ? "Email"
            : 2 == params.data.glcbr_mfa_source
            ? "Authenticator"
            : "Unconfigured";
        },
        hide: simpleMode,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "MFA Last Verified",
        headerTooltip: "Timestamp when MFA last verified",
        field: "glcbr_mfa_last_verified",
        hide: simpleMode,
        valueFormatter: LocalTimestampFormatter,
      },
      // {
      //   headerName: "Mobile Access",
      //   headerTooltip: "User has mobile access",
      //   field: "glcbr_mobile_access",
      //   filter: "agTextColumnFilter",
      //   filterParams: {
      //     filterOptions: ["contains", "startsWith"],
      //     debounceMs: 300,
      //     suppressAndOrCondition: true,
      //   },
      //   cellRenderer: CheckboxCellRenderer,
      //   cellRendererParams: { disabled: true } as CheckboxRendererProps,
      //   minWidth: 120,
      // },
      // {
      //   headerName: "Email Validated",
      //   headerTooltip: "Email address has been validated",
      //   field: "gl_email_validated",
      //   filter: "agTextColumnFilter",
      //   filterParams: {
      //     filterOptions: ["contains", "startsWith"],
      //     debounceMs: 300,
      //     suppressAndOrCondition: true,
      //   },
      //   cellRenderer: CheckboxCellRenderer,
      //   cellRendererParams: { disabled: true } as CheckboxRendererProps,
      //   minWidth: 120,
      // },
      {
        headerName: "Failed Attempts",
        headerTooltip: "Failed login attempts",
        field: "gl_access_failed_count",
        hide: simpleMode,
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
      },
      {
        headerName: "Lockout End",
        headerTooltip: "Timestamp last account lockout ended",
        field: "gl_lockout_end_date_utc",
        hide: simpleMode,
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "User ID",
        headerTooltip: "User ID in Customer",
        field: "glcbr_user_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        headerName: "User Email",
        headerTooltip: "User Email (should be same as Global Login Name)",
        field: "user_email",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "User Created Timestamp",
        headerTooltip: "User Created Timestamp",
        field: "usr_created_timestamp",
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: ["greaterThan", "lessThan"],
          debounceMs: 600,
          comparator: (filterDate: Date, cellValue: string) => {
            if (cellValue == null) {
              return -1;
            }
            const cellDate = parseISO(cellValue);
            if (cellDate < filterDate) {
              return -1;
            }
            if (cellDate > filterDate) {
              return 1;
            }
            return 0;
          },
        },
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "SCADALynx Desktop Last Access",
        field: "sl_last_access",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "HTML5 Mobile Last Access",
        field: "m5_last_access",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "React App Last Access",
        field: "r_last_access",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Public API Last Access",
        field: "api_last_access",
        valueFormatter: LocalTimestampFormatter,
      },
    ] as Array<ColDef>;

    return columns;
  }, [simpleMode]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //auto-size all columns to fit their content
  const autoSizeColumns = useCallback(() => {
    if (gridRef.current?.api && true === shouldResize.current) {
      //shouldResize.current = false;
      setTimeout(() => {
        //because this is timing based,
        //there are sometimes errors because the grid is already doing something else
        //when this is called
        //that siutation should not be fatal
        try {
          gridRef.current?.api.autoSizeAllColumns(true);
        } catch (error) {
          console.error(`Error sizing all EventHubPartitionGrid columns: ${error}`);
        }
      }, 1000);
    }
  }, []);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `AllUsers_${FormatDateYMD(new Date())}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "glcbr_api_key_expiration" ||
            colId == "glcbr_api_key_secondary_expiration" ||
            colId == "glcbr_mfa_last_verified" ||
            colId == "gl_lockout_end_date_utc" ||
            colId == "usr_created_timestamp"
            ? FormatTimestampLocal(params.value)
            : params.value?.toString();
        }
      },
    });
  }, [userQueryProperties]);

  //if the debounced filter string has changed,
  //notify the grid that the filter has changed
  useEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [debouncedFilter]);

  //called by the grid to determine if the user wants external filtering
  const isApiKeyFilterPresent = useCallback(() => {
    //if apiKeyFilter has a length, we are filtering
    return debouncedFilter.length > 0;
  }, [debouncedFilter]);

  //actual filter method that tells the grid whether or not a row should be visible
  const doesApiKeyFilterPass = useCallback(
    (node) => {
      //if there is not filter, every row passes
      if (0 == debouncedFilter.length) {
        return true;
      }

      //if either API key field matches the filter
      //then the filter passes
      const lcKey = debouncedFilter.toLowerCase();
      return (
        (node.data.glcbr_api_key && node.data.glcbr_api_key.toLowerCase().startsWith(lcKey)) ||
        (node.data.glcbr_api_key_secondary && node.data.glcbr_api_key_secondary.toLowerCase().startsWith(lcKey))
      );
    },
    [debouncedFilter]
  );

  return (
    <>
      <ContentView>
        <ContentHeader title={"All Users"} />
        <Stack
          direction="row"
          spacing={3}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value="ml"
            control={
              <Checkbox
                sx={{ paddingLeft: "2em", paddingRight: "4px" }}
                checked={userQueryProperties.includeDisabled}
                onChange={(e) => setUserQueryProperties({ ...userQueryProperties, includeDisabled: e.target.checked })}
              />
            }
            label="Include disabled users"
            labelPlacement="end"
          />
          <FormControlLabel
            value="ml"
            control={
              <Checkbox
                sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                checked={userQueryProperties.includeElynx}
                onChange={(e) => {
                  setUserQueryProperties({ ...userQueryProperties, includeElynx: e.target.checked });
                  setSystemUsageQueryProperties({ ...systemUsageQueryProperties, includeElynx: e.target.checked });
                }}
              />
            }
            label="Include eLynx users"
            labelPlacement="end"
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Customer Type"
            value={userQueryProperties.customerType}
            onChange={(e) => {
              setUserQueryProperties({ ...userQueryProperties, customerType: +e.target.value });
              setSystemUsageQueryProperties({ ...systemUsageQueryProperties, customerType: +e.target.value });
            }}
            sx={{ width: "18ch" }}
          >
            <MenuItem value={CustomerTypeEnum.AllCustomers}>All Customers</MenuItem>
            <MenuItem value={CustomerTypeEnum.Energy}>Energy</MenuItem>
            <MenuItem value={CustomerTypeEnum.Water}>Water</MenuItem>
          </TextField>
          <TextField
            size="small"
            variant="filled"
            label="API Key Filter"
            value={apiKeyFilter}
            onChange={(e) => setApiKeyFilter(e.target.value)}
            sx={{ width: "25ch" }}
          />
          <Box
            sx={{
              "& > button": { m: 1 },
              display: "flex",
              width: "20ch",
              marginLeft: "24px",
            }}
          >
            <Button
              onClick={handleExportCSVClick}
              variant="contained"
              disabled={!(userDisplayData && userDisplayData.length > 0)}
            >
              Export CSV
            </Button>
          </Box>
          <FontAwesomeIconButton
            icon={faTableCells}
            iconSize={32}
            title="Show all columns"
            onClick={() => setSimpleMode(false)}
            sx={{ marginLeft: "24px !important" }}
          />
          <FontAwesomeIconButton
            icon={faTableCellsLarge}
            iconSize={32}
            title="Show subset of columns"
            onClick={() => setSimpleMode(true)}
          />
          <FontAwesomeIconButton
            icon={faLineColumns}
            iconSize={32}
            title="Size all columns to fit data"
            onClick={() => autoSizeColumns()}
            sx={{ marginLeft: "24px !important" }}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Timeframe for last access"
            value={systemUsageQueryProperties.timeframe}
            onChange={(e) =>
              setSystemUsageQueryProperties({ ...systemUsageQueryProperties, timeframe: +e.target.value })
            }
            sx={{ width: "20ch" }}
          >
            <MenuItem value={7}>Past 7 days</MenuItem>
            <MenuItem value={30}>Past 30 days</MenuItem>
            <MenuItem value={90}>Past 90 days</MenuItem>
            <MenuItem value={180}>Past 180 days</MenuItem>
          </TextField>
        </Stack>
        <ContentSection>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AutoWrapHeaderGrid
                ref={gridRef}
                defaultColDef={defaultColDef}
                rowData={userDisplayData}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                onModelUpdated={autoSizeColumns}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
                tooltipShowDelay={500}
                isExternalFilterPresent={isApiKeyFilterPresent}
                doesExternalFilterPass={doesApiKeyFilterPass}
              />
            </div>
          </Box>
        </ContentSection>
      </ContentView>
    </>
  );
}
