import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { eLynxUserModel } from "./Models";

export function useUpdateElynxUserState() {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, eLynxUserModel>(
    async (user) => {
      const { data } = await ax.post<boolean>("elynxusers/updatestate", user);
      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(keys.all);
      },
    }
  );
}
