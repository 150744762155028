import { ReportTestConfiguration } from "./Models";

export enum UILanguage {
  NotSet = 0,
  USEnglish = 1,
  Spanish = 2,
  Russian = 3,
}

export type RunScheduledReportsRequest = {
  overrideReportDates: boolean;
  startDateOverride: Date | null;
  endDateOverride: Date | null;
  customerID: number;
  taskIDs: Array<number>;
  includeUnscheduledTasks: boolean;
  deliveryOverrideType: number;
  overrideEmail: boolean;
  emailOverrides: Array<string>;
  ftpOverride: string;
  deleteReportAfterDelivery: boolean;
  disableDBUpdate: boolean;
  //deliveryOverrides?: Array<any>;
  isTestMessage: boolean;
  //runKey?: any;
  logPrefix?: string;
  outputFormat?: string;
  groupOverrideID?: number;
  tatOverrideID?: number;
  includeSubgroupsWithOverride?: boolean;
  languageOverride?: UILanguage;
  timeZoneOverride?: number;
  templatePath?: string;
  emailBody?: string;
  emailSubject?: string;
  useSingleSchedule: boolean;
};

export function getRunScheduledReportsRequest(testConfiguration: ReportTestConfiguration): RunScheduledReportsRequest {
  return {
    overrideReportDates: testConfiguration.overrideReportDates ?? false,
    startDateOverride: testConfiguration.reportStartDto,
    endDateOverride: testConfiguration.reportEndDto,
    customerID: testConfiguration.selectedCustomerID ?? -1,
    taskIDs: testConfiguration.selectedTaskIDs ?? [],
    includeUnscheduledTasks: testConfiguration.includeUnscheduledTasks ?? false,
    deliveryOverrideType: testConfiguration.deliveryOverride ?? 0,
    overrideEmail: testConfiguration.overrideEmail ?? false,
    emailOverrides: testConfiguration.selectedEmailAddresses ?? [],
    ftpOverride: "",
    deleteReportAfterDelivery: testConfiguration.deleteReportAfterDelivery ?? true,
    disableDBUpdate: testConfiguration.disableDBUpdate ?? false,
    deliveryOverrides: [],
    isTestMessage: true,
    runKey: {},
    logPrefix: "",
    outputFormat: "",
    groupOverrideID: undefined,
    tatOverrideID: undefined,
    includeSubgroupsWithOverride: false,
    languageOverride: undefined,
    timeZoneOverride: undefined,
    templatePath: undefined,
    emailBody: testConfiguration.emailBodyOverride,
    emailSubject: testConfiguration.emailSubjectOverride,
    useSingleSchedule: testConfiguration.useSingleSchedule ?? true,
  } as RunScheduledReportsRequest;
}
