import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CacheConfiguration } from "./Models";

export type UseCacheConfigurationParameters = {
  autoRefreshSeconds: number;
};

//custom query hook
export function useCacheConfigurations({ autoRefreshSeconds }: UseCacheConfigurationParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.all;

  return useQuery<Array<CacheConfiguration>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<CacheConfiguration>>("cache/caches");
      return data;
    },
    {
      staleTime: 10 * 60 * 1000, //we will use data that is slightly stale because the data doesn't change often
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
    }
  );
}
