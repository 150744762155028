import React, { useMemo, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { EventHubStatusSummaryData } from "../../queries/event-hub-status/Models";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";

//our only prop for now is the actual partition data
export type EventHubSummaryGridProps = {
  summaryData: EventHubStatusSummaryData;
  gridSize: number; //will be 4, 6, or 8 (out of 12 grid slots)
};

export default function EventHubSummaryGrid({ summaryData, gridSize }: EventHubSummaryGridProps) {
  const gridRef = useRef<AgGridReact>(null);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const cols = [
      {
        headerName: "Max Min Behind",
        field: "totalMaxMinutesBehind",
      },
    ] as Array<ColDef>;

    //only put this column in if we have room for it
    if (true === isLarge || gridSize > 6) {
      cols.push({
        headerName: "Avg Min Behind",
        field: "totalAvgMinutesBehind",
      });
    }

    cols.push(
      {
        headerName: "Total Msgs Behind",
        field: "totalSequenceDelta",
      },
      {
        headerName: "Msg Gain/Loss",
        field: "totalSequenceGainLoss",
      }
    );

    return cols;
  }, [isLarge, gridSize]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = () => {
    setTimeout(() => {
      //because this is timing based,
      //there are sometimes errors because the grid is already doing something else
      //when this is called
      //that siutation should not be fatal
      try {
        if (null !== gridRef.current) {
          gridRef.current.api.sizeColumnsToFit();
        }
      } catch (error) {
        console.error(`Error sizing all EventHubSummaryGrid columns: ${error}`);
      }
    }, 300);
  };

  return (
    <>
      {/* It appears that in order to make the grid fit into a flex scheme 
it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "0 0 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-center-cols-clipper": {
            minHeight: "unset !important",
          },
        }}
      >
        <div className={gridTheme} style={{ width: "100%", marginTop: ".5em" }}>
          <AgGridReact
            ref={gridRef}
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            rowData={[summaryData ?? { lastUpdateTimestamp: new Date() }]}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={() => autoSizeColumns()}
            //onGridReady={(event) => autoSizeColumns(event?.api)}
            //onModelUpdated={(event) => autoSizeColumns(event?.api)}
          />
        </div>
      </Box>
      <Typography
        variant="body2"
        noWrap
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "1em",
        }}
      >
        Last update time: {summaryData?.lastUpdateTimestamp && FormatTimestampLocal(summaryData?.lastUpdateTimestamp)}
      </Typography>
    </>
  );
}
