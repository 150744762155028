import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { UseSendVoiceMsgParameters } from "./Models";

export function useSendVoiceMsg() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, UseSendVoiceMsgParameters>(async (voiceMsg) => {
    await ax.post("outboundmsg/voice", voiceMsg);
  });
}
