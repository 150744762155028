import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { AuditTrailItem, AuditTrailQueryProperties } from "./Models";
import { keys } from "./Keys";
import { useQuery } from "react-query";
import { addHours } from "date-fns";

export type UseAudiTrailParameters = {
  enabled: boolean;
  auditTrailQueryProperties: AuditTrailQueryProperties;
};

export function makeQueryKey(auditTrailQueryProperties: AuditTrailQueryProperties) {
  return [keys.all, auditTrailQueryProperties];
}

const wrapInPercent = (val: string | undefined) => {
  return val && `%${val}%`;
};

export function useAuditTrail({ enabled, auditTrailQueryProperties }: UseAudiTrailParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = makeQueryKey(auditTrailQueryProperties);

  return useQuery<Array<AuditTrailItem>, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done

      //setup the SQL wildcards
      //this is done here since we may want to control them
      //via the UI at some point in the future
      let queryProperties = {
        ...auditTrailQueryProperties,
        ocat_username: wrapInPercent(auditTrailQueryProperties.ocat_username),
        ocat_object_name: wrapInPercent(auditTrailQueryProperties.ocat_object_name),
        ocat_old_value: wrapInPercent(auditTrailQueryProperties.ocat_old_value),
        ocat_new_value: wrapInPercent(auditTrailQueryProperties.ocat_new_value),
        ocat_description: wrapInPercent(auditTrailQueryProperties.ocat_description),
      } as AuditTrailQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (auditTrailQueryProperties && auditTrailQueryProperties.timeframe && auditTrailQueryProperties.timeframe > 0) {
        const endTime = new Date();
        const startTime = addHours(endTime, 0 - auditTrailQueryProperties.timeframe);
        queryProperties = {
          ...queryProperties,
          ocat_start_timestamp: startTime,
          ocat_end_timestamp: endTime,
        };
      }

      //now make the actual data call
      const { data } = await ax.post<Array<AuditTrailItem>>("audit/query", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
