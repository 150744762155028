import { AuditTrailQueryProperties } from "../../queries/audit-trail/Models";

const defaultAuditTrailQueryProperties: AuditTrailQueryProperties = {
  ocat_max_count: 100,
  timeframe: 24,
  ocat_start_timestamp: null,
  ocat_end_timestamp: null,
};

export default defaultAuditTrailQueryProperties;
