export type FirstLastTagValueRequest = {
  customerID: number | null;
  organizationID: number;
  disabledDevices: boolean;
  hiddenDevices: boolean;
  disabledTags: boolean;
  hiddenTags: boolean;
  tagIDs: Array<number>;
};

export const defaultFirstLastTagValueRequest = {
  customerID: null,
  organizationID: -1,
  disabledDevices: false,
  hiddenDevices: false,
  disabledTags: false,
  hiddenTags: false,
  tagIDs: [],
} as FirstLastTagValueRequest;
