import React from "react";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const FormInputCheckbox = ({ name, label, helperText, control, rules, sx }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <>
          <FormControlLabel
            control={<Checkbox size="small" onChange={onChange} checked={value} sx={sx} />}
            label={label}
            labelPlacement="end"
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  );
};
