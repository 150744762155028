/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CacheDataQueryProperties } from "./Models";

export type UseCacheDataParameters = {
  enabled: boolean;
  autoRefreshSeconds: number;
  cacheDataQueryProperties: CacheDataQueryProperties;
};

//custom query hook
export function useCacheData({ enabled, autoRefreshSeconds, cacheDataQueryProperties }: UseCacheDataParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.cacheDataQuery(cacheDataQueryProperties);

  return useQuery<any, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<any>(
        `caches/cachedata/${cacheDataQueryProperties.cacheName}/${cacheDataQueryProperties.customerID}`
        // , {
        //   transformResponse: (rawData) => { return rawData; }
        // }
      );
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
    }
  );
}
