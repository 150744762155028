import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";

//custom query hook
export function useAllEnvironmentConfigurations() {
  const { axios: ax } = useAxios();

  return useMutation<string, Error, undefined>(async () => {
    const { data } = await ax.get<string>("environments/current/AllConfigurationsText", {
      responseType: "text",
      transformResponse: (res) => res,
    });
    return data;
  });
}
