import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import { isMobile, isTablet, isMobileSafari } from "react-device-detect";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentSection from "../../components/content-section/ContentSection";
import ContentView from "../../components/content-view/ContentView";
import {
  ConsolidatedEventHubStatusData,
  EventHubStatusQueryProperties,
  EventHubStatusSummaryData,
} from "../../queries/event-hub-status/Models";
import { useEventHubStatus } from "../../queries/event-hub-status/UseEventHubStatus";
import EventHubPartitionGrid from "./EventHubPartitionGrid";
import EventHubSummaryGrid from "./EventHubSummaryGrid";
import EventProcessingChart from "./EventProcessingChart";
import eventProcessingItems, { EventProcessingItem } from "./EventProcessingItems";
import { format, parseISO } from "date-fns";
import { UseEventHubProcessingMonitorMetrics } from "../../queries/metrics-data/UseEventHubProcessingMonitorMetrics";
import { EventHubProcessingMonitorData } from "../../queries/metrics-data/Models";
import {
  faSidebar,
  faTableCellsLarge,
  faSidebarFlip,
  faSquare1,
  faSquare2,
  faSquare3,
} from "@elynx/pro-light-svg-icons";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";

type EPMGridLayout = {
  leftSize: number;
  rightSize: number;
};

export default function EventProcessingMonitor() {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const [retrieveData, setRetrieveData] = useState(false);
  const [selectedProcessingItem, setSelectedProcessingItem] = useState<EventProcessingItem>(eventProcessingItems[0]);
  const [statusData, setStatusData] = useState<ConsolidatedEventHubStatusData>();
  const [hourMetricsData, setHourMetricsData] = useState<Array<EventHubProcessingMonitorData>>();
  const [dayMetricsData, setDayMetricsData] = useState<Array<EventHubProcessingMonitorData>>();
  const [monthMetricsData, setMonthMetricsData] = useState<Array<EventHubProcessingMonitorData>>();
  const [runningQueryProperties, setRunningQueryProperties] = useState<EventHubStatusQueryProperties>({
    configurationKey: "",
  });
  const [epmGridLayout, setEpmGridLayout] = useState<EPMGridLayout>({ leftSize: 6, rightSize: 6 });
  const [epmGraphCount, setEpmGraphCount] = useState(isTablet ? 2 : 3);

  //use the event hub status query to retrieve data for the currently running set of properties
  const { data: newStatusData } = useEventHubStatus({
    enabled: retrieveData,
    eventHubStatusQueryProperties: runningQueryProperties,
  });

  //use event hub processing monitor metrics query to retrieve minutes and messages behind data
  const { data: newHourMetricsData } = UseEventHubProcessingMonitorMetrics({
    enabled: retrieveData,
    queryProperties: { configurationKey: runningQueryProperties.configurationKey, timeframe: "currenthour" },
    refetchSeconds: 30,
  });

  const { data: newDayMetricsData } = UseEventHubProcessingMonitorMetrics({
    enabled: retrieveData,
    queryProperties: { configurationKey: runningQueryProperties.configurationKey, timeframe: "currentday" },
    refetchSeconds: 15 * 60,
  });

  const { data: newMonthMetricsData } = UseEventHubProcessingMonitorMetrics({
    enabled: retrieveData,
    queryProperties: { configurationKey: runningQueryProperties.configurationKey, timeframe: "currentmonth" },
    refetchSeconds: 240 * 60,
  });

  const sectionMinHeightString = isPhone ? `calc(${isMobileSafari ? "100dvh" : "100vh"} - 172px)` : undefined;
  //const sectionMinHeightString = isPhone ? "75vh" : undefined;

  const fixStatusDataTimestamps = useCallback((data: ConsolidatedEventHubStatusData) => {
    const processedData = { ...data } as ConsolidatedEventHubStatusData;

    processedData.valuesByMinute = data.valuesByMinute.map((v) => {
      return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
    });

    processedData.valuesByHour = data.valuesByHour.map((v) => {
      return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
    });

    //daily data uses the literal date w/o conversion
    processedData.valuesByDay = data.valuesByDay.map((v) => {
      return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
    });

    return processedData;
  }, []);

  //store new status data
  useEffect(() => {
    if (newStatusData) {
      try {
        setStatusData(fixStatusDataTimestamps(newStatusData));
      } catch (error) {
        console.error(`Error converting timestamps in newStatusData: ${error}`);
      }
    }
  }, [newStatusData]);

  //and new metrics data
  useEffect(() => {
    if (newHourMetricsData) {
      try {
        newHourMetricsData.sort((a, b) => parseISO(a.timestamp).valueOf() - parseISO(b.timestamp).valueOf());
        const processedData = newHourMetricsData.map((v) => {
          return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
        });

        setHourMetricsData(processedData);
      } catch (error) {
        console.error(`Error converting timestamps for newHourMetricsData: ${error}`);
      }
    }
  }, [newHourMetricsData]);

  useEffect(() => {
    if (newDayMetricsData) {
      try {
        newDayMetricsData.sort((a, b) => parseISO(a.timestamp).valueOf() - parseISO(b.timestamp).valueOf());
        const processedData = newDayMetricsData.map((v) => {
          return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
        });

        setDayMetricsData(processedData);
      } catch (error) {
        console.error(`Error converting timestamps for newDayMetricsData: ${error}`);
      }
    }
  }, [newDayMetricsData]);

  useEffect(() => {
    if (newMonthMetricsData) {
      try {
        newMonthMetricsData.sort((a, b) => parseISO(a.timestamp).valueOf() - parseISO(b.timestamp).valueOf());
        const processedData = newMonthMetricsData.map((v) => {
          return { ...v, tsEpoch: parseISO(v.timestamp).valueOf() };
        });

        setMonthMetricsData(processedData);
      } catch (error) {
        console.error(`Error converting timestamps for newMonthMetricsData: ${error}`);
      }
    }
  }, [newMonthMetricsData]);

  const onSelectedItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    //find the newly selected item
    const sel = eventProcessingItems.find((i) => i.index === +e.target.value);
    if (sel && sel.index > -1) {
      setSelectedProcessingItem(sel);
      setRunningQueryProperties({ configurationKey: sel.monitorName });
      setRetrieveData(true);
      setStatusData(undefined);
      setHourMetricsData(undefined);
      setDayMetricsData(undefined);
      setMonthMetricsData(undefined);
    } else {
      setRetrieveData(false);
    }
  };

  //timestamp formatters to pass to charts for x-axis and tooltip label
  const hourDataTsFormatter = useCallback((ts: number) => {
    return format(new Date(ts), "p");
  }, []);

  const dayDataTsFormatter = useCallback((ts: number) => {
    return format(new Date(ts), "P p");
  }, []);

  const monthDataTsFormatter = useCallback((ts: number) => {
    return format(new Date(ts), "MM/dd/yyyy");
  }, []);

  return (
    <ContentView>
      <ContentHeader title={"Event Processing Monitor"} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Event Processor"
          value={selectedProcessingItem?.index}
          onChange={onSelectedItemChange}
          sx={{ width: "25ch" }}
        >
          {eventProcessingItems.map((item, inx) => (
            <MenuItem key={inx} value={item.index}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        {!isPhone && (
          <>
            <FontAwesomeIconButton
              icon={faSidebar}
              fontSize="large"
              onClick={() => setEpmGridLayout({ leftSize: 4, rightSize: 8 })}
            />
            <FontAwesomeIconButton
              icon={faTableCellsLarge}
              fontSize="large"
              onClick={() => setEpmGridLayout({ leftSize: 6, rightSize: 6 })}
            />
            <FontAwesomeIconButton
              icon={faSidebarFlip}
              fontSize="large"
              onClick={() => setEpmGridLayout({ leftSize: 8, rightSize: 4 })}
            />
            <FontAwesomeIconButton
              icon={faSquare1}
              fontSize="large"
              onClick={() => setEpmGraphCount(1)}
              sx={{ marginLeft: "auto !important" }}
            />
            <FontAwesomeIconButton icon={faSquare2} fontSize="large" onClick={() => setEpmGraphCount(2)} />
            <FontAwesomeIconButton
              icon={faSquare3}
              fontSize="large"
              onClick={() => setEpmGraphCount(3)}
              sx={{ marginRight: ".5em !important" }}
            />
          </>
        )}
      </Stack>
      <ContentSection>
        <Grid container columnSpacing={1} rowSpacing={2} sx={{ minHeight: "100%" }}>
          {/* Partition table */}
          <Grid item container xs={12} md={epmGridLayout.leftSize} sx={{ minHeight: sectionMinHeightString }}>
            <ContentSection>
              <EventHubPartitionGrid
                partitionData={statusData?.partitionData ?? []}
                gridSize={epmGridLayout.leftSize}
                selectedIndex={selectedProcessingItem.index}
              />
              <EventHubSummaryGrid
                summaryData={statusData?.summaryData ?? ({} as EventHubStatusSummaryData)}
                gridSize={epmGridLayout.leftSize}
              />
            </ContentSection>
          </Grid>
          {/* Graphs */}
          <Grid item container xs={12} md={epmGridLayout.rightSize} sx={{ minHeight: sectionMinHeightString }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 auto",
                overflow: "hidden",
                position: "relative",
                marginTop: "0px",
                marginBottom: "0px",
                marginLeft: isPhone ? "6px" : "0px",
                marginRight: isPhone ? "6px" : "4px",
              }}
            >
              <EventProcessingChart
                chartLabel="Last 60 Minutes"
                valuesProcessed={statusData?.valuesByMinute ?? []}
                metricsData={hourMetricsData ?? []}
                timestampFormatter={hourDataTsFormatter}
              />
              {epmGraphCount > 1 ? (
                <EventProcessingChart
                  chartLabel="Last 24 Hours"
                  valuesProcessed={statusData?.valuesByHour ?? []}
                  metricsData={dayMetricsData ?? []}
                  timestampFormatter={dayDataTsFormatter}
                />
              ) : (
                <></>
              )}
              {epmGraphCount > 2 ? (
                <EventProcessingChart
                  chartLabel="Last 30 Days"
                  valuesProcessed={statusData?.valuesByDay ?? []}
                  metricsData={monthMetricsData ?? []}
                  timestampFormatter={monthDataTsFormatter}
                />
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </ContentSection>
    </ContentView>
  );
}
