import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export type GridItemDialogProps = {
  gridText: string;
  dialogOpen: boolean;
  onClose: () => void;
};

export default function PreformattedGridTextDialog({ gridText, dialogOpen, onClose }: GridItemDialogProps) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="xl"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Full Cell Text</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-message" tabIndex={-1} sx={{ whiteSpace: "pre-wrap" }}>
          {gridText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
