import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { ISystemJob } from "../../queries/system-jobs/Models";
import { useSystemJobs } from "../../queries/system-jobs/UseSystemJobs";
import ListFilter from "../../components/list-filter/ListFilter";
import { ILogItem } from "../../queries/log-data/Models";
import LogItemDialog from "../../components/log-item-dialog/LogItemDialog";
import PodListDialog from "../../components/pod-list-dialog/PodListDialog";
import { ServicePanelList, ServicePanelListItemType } from "./ServicePanelList";
import LogDataViewer from "../../components/log-data-viewer/LogDataViewer";
import RunJob from "./RunJob";
import { isMobile, isTablet } from "react-device-detect";

export default function SystemServices() {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const [filterText, setFilterText] = useState("");
  const [deploymentFilter, setDeploymentFilter] = useState("");
  const [logRowsPerService, setLogRowsPerService] = useState(5);
  const [maxLinesPerLogRows, setMaxLinesPerLogRows] = useState(4);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const [logItemDialogOpen, setLogItemDialogOpen] = useState(false);
  const logItemRef = useRef<ILogItem | null>(null);
  const [podListDialogOpen, setPodListDialogOpen] = useState(false);
  const podListRef = useRef<Array<string> | null>(null);
  const [logDataViewerOpen, setLogDataViewerOpen] = useState(false);
  const currentJobRef = useRef<ISystemJob | null>(null);
  const [runJobDialogOpen, setRunJobDialogOpen] = useState(false);
  const {
    data: newSystemJobs,
    isFetching: isFetchingJobs,
    refetch: refetchJobs,
  } = useSystemJobs({ autoRefreshSeconds: autoRefreshSeconds });
  const [systemJobs, setSystemJobs] = useState<Array<ISystemJob>>(newSystemJobs || []);
  const [currentJobs, setCurrentJobs] = useState<Array<ISystemJob> | undefined>(newSystemJobs);

  useEffect(() => {
    if (newSystemJobs) {
      setSystemJobs(newSystemJobs);
    }
  }, [newSystemJobs]);

  useEffect(() => {
    let filteredJobs = systemJobs?.filter((job) => {
      return job.name.toLowerCase().includes(filterText.toLowerCase());
    });

    if (filteredJobs && deploymentFilter.length > 0) {
      filteredJobs = filteredJobs.filter((job) => {
        const dn = job.deployments?.find((d) => d.deploymentName?.includes(deploymentFilter.toLowerCase()));
        return !!dn;
      });
    }

    if (filteredJobs) {
      setCurrentJobs(filteredJobs);
    }
  }, [filterText, deploymentFilter, systemJobs]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetchJobs();
    }
  }, [autoRefreshSeconds]);

  //handlers for log item message/error text dialog
  const handleMessageCellClick = useCallback((logItem: ILogItem) => {
    if (logItem) {
      logItemRef.current = logItem;
      setLogItemDialogOpen(true);
    }
  }, []);

  //hide the dialog
  const handleLogItemDialogClose = () => {
    setLogItemDialogOpen(false);
  };

  //handlers for pod list dialog
  const handleShowPodListClick = useCallback((podList: Array<string>) => {
    if (podList && podList.length > 0) {
      podListRef.current = podList;
      setPodListDialogOpen(true);
    }
  }, []);

  //hide the pod list dialog
  const handlePodListDialogClose = () => {
    setPodListDialogOpen(false);
  };

  //handlers for the log data viewer dialog
  const handleShowLogDataViewerClick = useCallback((job: ISystemJob) => {
    currentJobRef.current = job;
    setLogDataViewerOpen(true);
  }, []);

  //handlers for the run job dialog
  const handleShowRunJobDialogClick = useCallback((job: ISystemJob) => {
    currentJobRef.current = job;
    setRunJobDialogOpen(true);
  }, []);

  return (
    <ContentView>
      <ContentHeader title={"System Services"} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
        }}
      >
        {!isPhone && (
          <>
            <TextField
              size="small"
              select
              variant="filled"
              label="Max Row Count"
              value={logRowsPerService}
              onChange={(e) => setLogRowsPerService(+e.target.value)}
              sx={{ width: "12ch" }}
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={9}>9</MenuItem>
            </TextField>
            <TextField
              size="small"
              select
              variant="filled"
              label="Max lines per log entry"
              value={maxLinesPerLogRows}
              onChange={(e) => setMaxLinesPerLogRows(+e.target.value)}
              sx={{ width: "16ch" }}
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={16}>16</MenuItem>
              <MenuItem value={32}>32</MenuItem>
            </TextField>
          </>
        )}
        <ListFilter
          onFilterValueChange={(value) => setFilterText(value)}
          debounceDelay={300}
          placeholder="service name"
          width={isPhone ? "20ch" : "40ch"}
        />
        <ListFilter
          onFilterValueChange={(value) => setDeploymentFilter(value)}
          debounceDelay={300}
          placeholder="deployment"
          width={isPhone ? "18ch" : "20ch"}
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => refetchJobs()}
            endIcon={isFetchingJobs ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflowY: "auto",
          overflowX: "auto",
          position: "relative",
        }}
      >
        {currentJobs && (
          <ServicePanelList
            items={currentJobs.map((job, index) => {
              return {
                key: index.toString(),
                job: job,
                smallRowCount: logRowsPerService,
                openLogItemDialog: handleMessageCellClick,
                maxLinesPerLogRow: maxLinesPerLogRows,
                autoRefreshSeconds: autoRefreshSeconds,
                openPodListDialog: handleShowPodListClick,
                openLogDataViewer: handleShowLogDataViewerClick,
                openRunJobDialog: handleShowRunJobDialogClick,
              } as ServicePanelListItemType;
            })}
          />
        )}
      </Box>

      {/* This dialog shows the message text and exception text if a message cell is clicked */}
      {logItemRef.current && (
        <LogItemDialog logItem={logItemRef.current} dialogOpen={logItemDialogOpen} onClose={handleLogItemDialogClose} />
      )}

      {/* This dialog shows the pod list */}
      {podListRef.current && (
        <PodListDialog podList={podListRef.current} dialogOpen={podListDialogOpen} onClose={handlePodListDialogClose} />
      )}

      {/* This dialog shows the run service dialog */}
      {currentJobRef.current && (
        <RunJob job={currentJobRef.current} open={runJobDialogOpen} onClose={() => setRunJobDialogOpen(false)} />
      )}

      {/* This dialog shows the LogDataViewer */}
      {currentJobRef.current && (
        <Dialog
          open={logDataViewerOpen}
          onClose={() => setLogDataViewerOpen(false)}
          scroll="paper"
          fullWidth={true}
          //maxWidth="xl"
          maxWidth={false}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{ height: "100%", "& .MuiPaper-root": { height: "100%" } }}
        >
          <DialogTitle id="scroll-dialog-title">
            {`Job ${currentJobRef.current.name} Log Details`}
            <IconButton
              aria-label="close"
              onClick={() => setLogDataViewerOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Stack direction="column" height="100%">
              <LogDataViewer onlyLoggerName={currentJobRef.current.loggerName} />
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </ContentView>
  );
}
