import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { NonPolledDataItem } from "./Models";

export type UseUploadFileParameters = {
  item: NonPolledDataItem;
  file: File;
};

export function useUploadFile() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, UseUploadFileParameters>(async ({ item, file }: UseUploadFileParameters) => {
    //create a FormData object and put the file into the form value named "uploadFile"
    //in order to match the parameter name in the backend call:
    //UploadFile(IFormFile uploadFile....
    const formData = new FormData();
    formData.append("uploadFile", file);

    const url = `nonpolleddataops/file/upload/${item.container}/${item.directory}/${item.name}`;

    await ax.post(url, formData);
  });
}
