import { AuditTrailQueryProperties } from "../../queries/audit-trail/Models";

//these are the strings used by the reducer
export enum AuditTrailActionTypeEnum {
  SetMaxCount = "set-max-count",
  SetTimeframe = "set-timeframe",
  SetStartTime = "set-start-time",
  SetEndTime = "set-end-time",
  SetUsername = "set-user-name",
  SetIpAddress = "set-ip-address",
  SetAction = "set-action",
  SetView = "set-view",
  SetObjectType = "set-object-type",
  SetObjectID = "set-object-id",
  SetObjectName = "set-object-name",
  SetOldValue = "set-old-value",
  SetNewValue = "set-new-value",
  SetDescription = "set-description",
}

//define a type per action to enforce typing very strictly
export type SetMaxCountAction = {
  type: AuditTrailActionTypeEnum.SetMaxCount;
  maxCount: number;
};

export type SetTimeframeAction = {
  type: AuditTrailActionTypeEnum.SetTimeframe;
  timeframe: number;
};

export type SetStartTimeAction = {
  type: AuditTrailActionTypeEnum.SetStartTime;
  startTime: Date;
};

export type SetEndTimeAction = {
  type: AuditTrailActionTypeEnum.SetEndTime;
  endTime: Date;
};

export type SetUserNameAction = {
  type: AuditTrailActionTypeEnum.SetUsername;
  username: string;
};

export type SetIpAddressAction = {
  type: AuditTrailActionTypeEnum.SetIpAddress;
  ipAddress: string;
};

export type SetActionAction = {
  type: AuditTrailActionTypeEnum.SetAction;
  action: string;
};

export type SetViewAction = {
  type: AuditTrailActionTypeEnum.SetView;
  view: string;
};

export type SetObjectTypeAction = {
  type: AuditTrailActionTypeEnum.SetObjectType;
  objectType: number;
};

export type SetObjectIDAction = {
  type: AuditTrailActionTypeEnum.SetObjectID;
  objectID: number;
};

export type SetObjectNameAction = {
  type: AuditTrailActionTypeEnum.SetObjectName;
  objectName: string;
};

export type SetOldValueAction = {
  type: AuditTrailActionTypeEnum.SetOldValue;
  oldValue: string;
};

export type SetNewValueAction = {
  type: AuditTrailActionTypeEnum.SetNewValue;
  newValue: string;
};

export type SetDescriptionAction = {
  type: AuditTrailActionTypeEnum.SetDescription;
  description: string;
};

//an action sent to the reducer can be any of the above individual actions
export type AuditTrailActions =
  | SetMaxCountAction
  | SetTimeframeAction
  | SetStartTimeAction
  | SetEndTimeAction
  | SetUserNameAction
  | SetIpAddressAction
  | SetActionAction
  | SetViewAction
  | SetObjectTypeAction
  | SetObjectIDAction
  | SetObjectNameAction
  | SetOldValueAction
  | SetNewValueAction
  | SetDescriptionAction;

const undefinedOnBlank = (val: string) => {
  return val?.length > 0 ? val : undefined;
};

const undefinedOnZero = (val: number) => {
  return val !== 0 ? val : undefined;
};

//and now the reducer itself
export const auditTrailActionReducer = (
  state: AuditTrailQueryProperties,
  action: AuditTrailActions
): AuditTrailQueryProperties => {
  switch (action.type) {
    case AuditTrailActionTypeEnum.SetMaxCount:
      return {
        ...state,
        ocat_max_count: action.maxCount,
      };
    case AuditTrailActionTypeEnum.SetTimeframe:
      return {
        ...state,
        timeframe: action.timeframe,
      };
    case AuditTrailActionTypeEnum.SetStartTime:
      return {
        ...state,
        ocat_start_timestamp: action.startTime,
      };
    case AuditTrailActionTypeEnum.SetEndTime:
      return {
        ...state,
        ocat_end_timestamp: action.endTime,
      };
    case AuditTrailActionTypeEnum.SetUsername:
      return {
        ...state,
        ocat_username: undefinedOnBlank(action.username),
      };
    case AuditTrailActionTypeEnum.SetIpAddress:
      return {
        ...state,
        ocat_ip_address: undefinedOnBlank(action.ipAddress),
      };
    case AuditTrailActionTypeEnum.SetAction:
      return {
        ...state,
        ocat_action: undefinedOnBlank(action.action),
      };
    case AuditTrailActionTypeEnum.SetView:
      return {
        ...state,
        ocat_view: undefinedOnBlank(action.view),
      };
    case AuditTrailActionTypeEnum.SetObjectType:
      return {
        ...state,
        ocat_object_type_id: undefinedOnZero(action.objectType),
      };
    case AuditTrailActionTypeEnum.SetObjectID:
      return {
        ...state,
        ocat_object_id: undefinedOnZero(action.objectID),
      };
    case AuditTrailActionTypeEnum.SetObjectName:
      return {
        ...state,
        ocat_object_name: undefinedOnBlank(action.objectName),
      };
    case AuditTrailActionTypeEnum.SetOldValue:
      return {
        ...state,
        ocat_old_value: undefinedOnBlank(action.oldValue),
      };
    case AuditTrailActionTypeEnum.SetNewValue:
      return {
        ...state,
        ocat_new_value: undefinedOnBlank(action.newValue),
      };
    case AuditTrailActionTypeEnum.SetDescription:
      return {
        ...state,
        ocat_description: undefinedOnBlank(action.description),
      };

    default:
      return state;
  }
};
