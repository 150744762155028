import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Box } from "@mui/material";

export type PreFormattedRendererProps = {
  maxLines: number | null
};

export type PreFormattedCellRendererProps = PreFormattedRendererProps & ICellRendererParams;

export default function PreFormattedCellRenderer({ value, valueFormatted, maxLines }: PreFormattedCellRendererProps) {
  const lineClamp = maxLines ?? 8;
  return (
    <Box
      sx={{
        display: "-webkit-box",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        "-webkit-line-clamp": `${lineClamp}`,
        "-webkit-box-orient": "vertical",
      }}
    >
      {valueFormatted || value}
    </Box>
  );
}
