import { formatISO } from "date-fns";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { AccountingInvoiceDescription, AccountingInvoiceQueryProperties } from "./Models";

export type UseAccountingInvoiceListParameters = {
  enabled: boolean;
  accountingInvoiceQueryProperties: AccountingInvoiceQueryProperties;
};

export function useAccountingInvoiceList({
  enabled,
  accountingInvoiceQueryProperties,
}: UseAccountingInvoiceListParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied accountingInvoiceQueryProperties
  const queryKey = keys.queryKey(accountingInvoiceQueryProperties);

  //create a properly formatted date string
  const isoDate = formatISO(accountingInvoiceQueryProperties.timestamp ?? new Date());
  const queryParams = {
    timestamp: isoDate,
    blobType: accountingInvoiceQueryProperties.blobType,
  };

  return useQuery<Array<AccountingInvoiceDescription>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<AccountingInvoiceDescription>>("accounting/blobs", {
        params: queryParams,
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
