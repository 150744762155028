import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { useUserRoles } from "../../queries/user-roles/UseUserRoles";

//input and output types
export type UserRoleContextType = {
  isDevOpsMember: boolean;
  isOpsCenterWriterMember: boolean;
};

export type UserRoleProviderProps = {
  children: ReactNode;
};

//the context itself
const UserRoleContext = createContext<UserRoleContextType | undefined>(undefined);

//the provider component
export function UserRoleProvider({ children }: UserRoleProviderProps) {
  const { isLoading, isError, data } = useUserRoles();

  const providerValue = useMemo<UserRoleContextType>(() => {
    let isDevOpsMember = false;
    let isOpsCenterWriterMember = false;

    if (!isLoading && !isError && !!data) {
      isDevOpsMember = !!data.find((r) => r.name === "DevOps");

      //2022-08-05 RMS
      //for purposes of permissions inside OpsCenter
      //being a member of DevOps grants you full writer access
      isOpsCenterWriterMember = isDevOpsMember || !!data.find((r) => r.name === "OpsCenterWriter");
    }

    return {
      isDevOpsMember: isDevOpsMember,
      isOpsCenterWriterMember: isOpsCenterWriterMember,
    };
  }, [isLoading, isError, data]);

  return <UserRoleContext.Provider value={providerValue}>{children}</UserRoleContext.Provider>;
}

//the hook to use the UserRoleContext
export function useUserRole() {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }

  return context;
}
