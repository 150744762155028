import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ClientAcessTypeEnum, PageUsageSummary } from "../../queries/page-usage/Models";

export type PageUsageSummaryChartProps = {
  chartLabel: string;
  summaryData: Array<PageUsageSummary>;
  clientAccessType: ClientAcessTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartRef: React.MutableRefObject<any>;
};

export default function PageUsageSummaryChart({
  chartLabel,
  summaryData,
  clientAccessType,
  chartRef,
}: PageUsageSummaryChartProps) {
  const theme = useTheme();
  const yAxisWidth = clientAccessType == ClientAcessTypeEnum.HTML5Mobile ? 360 : 280;
  const barWidth = 21;
  const barCategoryGap = 6;
  const chartHeight = (summaryData?.length ?? 0) * (barWidth + barCategoryGap);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: theme.palette.neutral.mediumContrast,
          marginBottom: "5px",
        }}
      >
        <Typography variant="body2" color="#82ca9d" sx={{ display: "flex", flex: "0 0 auto", margin: "auto" }}>
          {chartLabel}
        </Typography>
        {summaryData.length > 1 && (
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              overflowY: "auto",
            }}
          >
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <ResponsiveContainer height={chartHeight} width="100%" debounce={100}>
                  <BarChart
                    ref={chartRef}
                    layout="vertical"
                    data={summaryData}
                    barSize={barWidth}
                    barCategoryGap={barCategoryGap}
                  >
                    <XAxis type="number" tickFormatter={(value) => value.toLocaleString()} />
                    <YAxis
                      yAxisId={0}
                      type="category"
                      dataKey="viewName"
                      tickLine={false}
                      width={yAxisWidth}
                      interval={0}
                    />
                    <YAxis
                      yAxisId={1}
                      orientation="right"
                      type="category"
                      dataKey="accessCount"
                      tickFormatter={(value) => value.toLocaleString()}
                      tickLine={false}
                      width={100}
                      interval={0}
                    />
                    <Tooltip
                      formatter={(value: number | string) => value.toLocaleString()}
                      contentStyle={{ backgroundColor: theme.palette.background.default }}
                    />
                    <Legend />
                    <Bar name="Access Count" dataKey="accessCount" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Box>
        )}
      </Box>
    </>
  );
}
