import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  Configuration,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import settings from "../../app-settings.json";

//export login and new token requests
export const loginRequest: RedirectRequest = {
  scopes: [settings.aadScopes],
  redirectUri: window.location.origin,
};

//for our use case, we want to bypass the cache when we make a silent request
//React Query will be doing the first level of caching for us
export const newTokenRequest: SilentRequest = {
  ...loginRequest,
  forceRefresh: true,
};

export function createPCA(clientID: string): PublicClientApplication {
  const msalConfig: Configuration = {
    auth: {
      clientId: clientID,
      authority: "https://login.microsoftonline.com/organizations/",
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  const pca = new PublicClientApplication(msalConfig);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }

  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      pca.setActiveAccount(account);
    }
  });

  return pca;
}
