import React, { useEffect, useState } from "react";
import { TatGroupActionEnum, TatGroupInfoItem } from "../../../queries/copy-device/Models";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";

export type SelectTatGroupProperties = {
  tatGroupInfo: TatGroupInfoItem;
  tatGroupList: Array<AutocompleteOption>;
  open: boolean;
  onClose: () => void;
  selectTatGroup: (tatGroupInfo: TatGroupInfoItem) => void;
};

export default function SelectTatGroup({
  tatGroupInfo,
  tatGroupList,
  open,
  onClose,
  selectTatGroup,
}: SelectTatGroupProperties) {
  const [selectedTatGroup, setSelectedTatGroup] = useState<AutocompleteOption>({ id: -1, label: "" });

  //if we have just been opened, reset our selected TAT Group
  useEffect(() => {
    setSelectedTatGroup(
      null != tatGroupInfo.dst_tg_name && null != tatGroupInfo.dst_tg_id
        ? { id: tatGroupInfo.dst_tg_id, label: tatGroupInfo.dst_tg_name }
        : { id: -1, label: "" }
    );
  }, [open]);

  const onClick = () => {
    //create a new TatGroupInfoItem with the selected ID
    //and send it to the passed in function and close the dialog
    const newTatGroupInfo = {
      ...tatGroupInfo,
      dst_tg_id: selectedTatGroup.id > 0 ? selectedTatGroup.id : null,
      dst_tg_name: selectedTatGroup.label,
      dst_isTatGroup: true,
      tatGroup_action: selectedTatGroup.id > -1 ? TatGroupActionEnum.Copy : TatGroupActionEnum.DoNotCopy,
    } as TatGroupInfoItem;
    selectTatGroup(newTatGroupInfo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Select Destination TAT Group</DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          autoHighlight
          size="small"
          options={tatGroupList}
          getOptionLabel={(option) => (option.id > 0 ? `${option.label} [${option.id}]` : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedTatGroup}
          onChange={(event, value) => {
            if (value) {
              setSelectedTatGroup(value);
            } else {
              setSelectedTatGroup({ id: -1, label: "" });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Destination TAT Group" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
