import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "react-query";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { auditTrailActionReducer, MetricsActionTypeEnum } from "./MetricsViewerActions";
import {
  getEmptyMetricFilterQueryParam,
  MetricItem,
  MetricRow,
  MetricsQueryProperties,
} from "../../queries/metrics-data/Models";
import { UseQueryMetrics } from "../../queries/metrics-data/UseQueryMetrics";
import { keys } from "../../queries/metrics-data/Keys";
import isEqual from "react-fast-compare";
import { useMetricTypes } from "../../queries/metrics-data/UseMetricTypes";
import { useMetricsPrimaryKeys } from "../../queries/metrics-data/UseMetricsPrimaryKeys";
import { ColDef } from "ag-grid-community";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { faLineColumns } from "@elynx/pro-light-svg-icons";
import MetricsFilter from "./MetricsFilter";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";

const defaultMetricsQueryProperties: MetricsQueryProperties = {
  maxCount: 10,
  startTime: null,
  endTime: null,
  primaryKey: null,
  metricType: "",
  filterItems: [getEmptyMetricFilterQueryParam()],
  timeframe: 1,
};

type DynamicColumn = {
  key: string;
  isNumber: boolean;
};

export default function MetricsViewer() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [retrieveData, setRetrieveData] = useState(false);
  const [metricTypes, setMetricTypes] = useState<Array<MetricItem>>([]);
  const [primaryKeyList, setPrimaryKeyList] = useState<Array<string>>([]);
  const [metricsData, setMetricsData] = useState<Array<MetricRow>>([]);
  const [dynamicColumns, setDynamicColumns] = useState<Array<DynamicColumn>>([]);
  const [allColumns, setAllColumns] = useState<Array<string>>([]);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultMetricsQueryProperties);
  const [metricsQueryProperties, dispatchMetricsQueryProperties] = useReducer(
    auditTrailActionReducer,
    defaultMetricsQueryProperties
  );
  const { data: newMetricTypes, isSuccess: isMetricTypesSuccess } = useMetricTypes();
  const { data: newPrimaryKeys, isSuccess: isPrimaryKeysSuccess } = useMetricsPrimaryKeys(
    metricsQueryProperties.metricType
  );
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const {
    data: newMetricsData,
    isFetching,
    isSuccess,
    refetch,
  } = UseQueryMetrics({
    enabled: retrieveData,
    metricsQueryProperties: runningQueryProperties,
    refetchSeconds: autoRefreshSeconds,
  });

  useEffect(() => {
    if (newMetricTypes) {
      setMetricTypes(newMetricTypes);
    }
  }, [isMetricTypesSuccess, newMetricTypes]);

  useEffect(() => {
    if (newPrimaryKeys) {
      setPrimaryKeyList(newPrimaryKeys.sort());
    }
  }, [isPrimaryKeysSuccess, newPrimaryKeys]);

  useEffect(() => {
    //disable query unless auto refresh is set
    if (autoRefreshSeconds == 0) {
      setRetrieveData(false);
    }

    if (newMetricsData) {
      setMetricsData(newMetricsData);

      if (newMetricsData.length > 0) {
        //setup all columns, the fixed ones plus the dynamic ones
        const allColumns = ["PartitionKey", "RowKey", "Timestamp"];

        //setup dynamic columns, including isNumber flag
        const newColumns = new Array<DynamicColumn>();
        Object.entries(newMetricsData[0])
          .sort()
          .forEach(([key, value]) => {
            if (key !== "PartitionKey" && key !== "RowKey" && key !== "Timestamp") {
              newColumns.push({
                key: key,
                isNumber: typeof value === "number",
              } as DynamicColumn);

              allColumns.push(key);
            }
          });

        setDynamicColumns(newColumns);
        setAllColumns(allColumns);
      }
    }
  }, [isSuccess, newMetricsData]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetch();
      setRetrieveData(true);
    }
  }, [autoRefreshSeconds]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.query(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      setRetrieveData(true);
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, metricsQueryProperties)) {
        setRunningQueryProperties(metricsQueryProperties);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns: Array<ColDef> = [
      {
        headerName: "Partition Key",
        field: "PartitionKey",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 120,
      },
      {
        headerName: "Row Key",
        field: "RowKey",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 120,
        sort: "asc",
      },
      {
        headerName: "Timestamp",
        field: "Timestamp",
        valueFormatter: LocalTimestampFormatter,
        width: 210,
      },
    ];

    //now get all the other properties that aren't in the base set
    dynamicColumns.forEach((c) => {
      columns.push({
        headerName: c.key,
        field: c.key,
        filter: c.isNumber ? "agNumberColumnFilter" : "agTextColumnFilter",
        filterParams: {
          filterOptions: c.isNumber
            ? ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"]
            : ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      });
    });

    return columns;
  }, [dynamicColumns]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns(false);
  }, []);

  const getExportFileName = useCallback(() => {
    const dataType = runningQueryProperties.metricType;
    const dateSpan =
      runningQueryProperties.timeframe == 0
        ? `${runningQueryProperties.startTime ? FormatDateYMD(runningQueryProperties.startTime) : ""}_${
            runningQueryProperties.endTime ? FormatDateYMD(runningQueryProperties.endTime) : ""
          }`
        : `Past_${runningQueryProperties.timeframe}_hours`;

    return `${dataType}_Metrics_${dateSpan}`;
  }, [runningQueryProperties]);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `${getExportFileName()}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "Timestamp" || colId == "MetricsTimestamp"
            ? FormatTimestampLocal(params.value)
            : params.value?.toString();
        }
      },
    });
  }, [runningQueryProperties]);

  return (
    <ContentView>
      <ContentHeader title={"Metrics Viewer"} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Metric Type"
          value={metricsQueryProperties.metricType}
          onChange={(e) =>
            dispatchMetricsQueryProperties({
              type: MetricsActionTypeEnum.SetMetricType,
              metricType: e.target.value,
            })
          }
          sx={{ width: "40ch" }}
        >
          {metricTypes.map((mt, inx) => (
            <MenuItem key={inx} value={mt.name}>
              {mt.displayName}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          autoHighlight
          size="small"
          options={primaryKeyList}
          value={metricsQueryProperties.primaryKey}
          onChange={(event, value) => {
            if (value) {
              dispatchMetricsQueryProperties({
                type: MetricsActionTypeEnum.SetPrimaryKey,
                primaryKey: value,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Primary Key" />}
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Max Row Count"
          value={metricsQueryProperties.maxCount}
          onChange={(e) =>
            dispatchMetricsQueryProperties({
              type: MetricsActionTypeEnum.SetMaxCount,
              maxCount: +e.target.value,
            })
          }
          sx={{ width: "12ch" }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Timeframe"
          value={metricsQueryProperties.timeframe}
          onChange={(e) =>
            dispatchMetricsQueryProperties({
              type: MetricsActionTypeEnum.SetTimeframe,
              timeframe: +e.target.value,
            })
          }
          sx={{ width: "16ch" }}
        >
          <MenuItem value={0}>Custom</MenuItem>
          <MenuItem value={1}>Past hour</MenuItem>
          <MenuItem value={8}>Past 8 hours</MenuItem>
          <MenuItem value={24}>Today</MenuItem>
          <MenuItem value={48}>Past 2 days</MenuItem>
          <MenuItem value={168}>This week</MenuItem>
        </TextField>
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Time"
          value={metricsQueryProperties.startTime}
          disabled={0 !== metricsQueryProperties.timeframe}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              dispatchMetricsQueryProperties({
                type: MetricsActionTypeEnum.SetStartTime,
                startTime: newValue,
              });
            }
          }}
        />
        <DateTimePicker
          disabled={0 !== metricsQueryProperties.timeframe}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Time"
          value={metricsQueryProperties.endTime}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              dispatchMetricsQueryProperties({
                type: MetricsActionTypeEnum.SetEndTime,
                endTime: newValue,
              });
            }
          }}
        />
        <FontAwesomeIconButton
          icon={faLineColumns}
          iconSize={32}
          title="Size all columns to fit data"
          onClick={() => autoSizeColumns()}
          sx={{ marginLeft: "24px !important" }}
        />
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "24px",
          }}
        >
          <Button
            onClick={handleExportCSVClick}
            variant="contained"
            disabled={!(metricsData && metricsData.length > 0)}
          >
            Export CSV
          </Button>
        </Box>
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: "1em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Count: {metricsData?.length ?? 0}
        </Typography>
      </Stack>
      <MetricsFilter
        filterRows={metricsQueryProperties.filterItems}
        fieldList={allColumns}
        dispatchQueryProperties={dispatchMetricsQueryProperties}
      />
      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={metricsData}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            onFirstDataRendered={autoSizeColumns}
            columnMenu="legacy"
            //onModelUpdated={autoSizeColumns}
          />
        </div>
      </Box>
    </ContentView>
  );
}
