import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentContainer from "../../components/content-container/ContentContainer";
import getDefaultLogQueryProperties from "./DefaultLogQueryProperties";
import { logViewerActionReducer, LogViewerActionTypeEnum } from "./LogViewerActions";
import { makeQueryKey, useLogData } from "../../queries/log-data/UseLogData";
import { useQueryClient } from "react-query";
import { ILogItem } from "../../queries/system-jobs/Models";
import SideSheet from "../../components/side-sheet/side-sheet/SideSheet";
import { Tune } from "@mui/icons-material";
import isEqual from "react-fast-compare";
import SystemJobList from "./SystemJobList";
import { CellClickedEvent, ColDef, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import LogMessageCellRenderer from "../ag-grid-extensions/renderers/LogMessageCellRenderer";
import useWindowSize from "../../hooks/use-window-size/UseWindowSize";
import LogItemDialog from "../log-item-dialog/LogItemDialog";
import { LocalTimestampFormatter } from "../ag-grid-extensions/utilities/LocalTimestampFormatter";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";

export type LogDataViewerProps = {
  onlyLoggerName?: string;
};

//turn a log level number into a string for the UI
export const FormatLogLevel = (level: number): string =>
  0 === level
    ? "Trace"
    : 1 === level
    ? "Debug"
    : 2 === level
    ? "Info"
    : 3 === level
    ? "Warning"
    : 4 === level
    ? "Error"
    : 5 === level
    ? "Fatal"
    : 6 === level
    ? "Off"
    : "Unknown";
const logLevelFormatter = (params: ValueFormatterParams): string => FormatLogLevel(params.value);

export default function LogDataViewer({ onlyLoggerName }: LogDataViewerProps) {
  const gridRef = useRef<AgGridReact>(null);
  const logItemRef = useRef<ILogItem | null>(null);
  const [open, setDialogOpen] = React.useState(false);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const defaultLogQueryProperties = getDefaultLogQueryProperties(onlyLoggerName);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [sideSheetOpen, setSideSheetOpen] = useState(false);
  const [, setRetrieveData] = useState(false);
  const [logData, setLogData] = useState<Array<ILogItem>>([]);
  const [runningQueryProperties, setRunningQueryProperties] = useState(
    onlyLoggerName ? { ...defaultLogQueryProperties, loggerNames: [onlyLoggerName] } : defaultLogQueryProperties
  );
  const [logQueryProperties, dispatchLogQueryProperties] = useReducer(
    logViewerActionReducer,
    defaultLogQueryProperties
  );
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);

  //use the log data query to retrieve data for the currently running set of properties
  const {
    data: newLogData,
    isFetching,
    isSuccess,
    refetch,
  } = useLogData({
    //enabled: retrieveData,
    enabled: true,
    logQueryProperties: runningQueryProperties,
    autoRefreshSeconds: autoRefreshSeconds,
  });

  //setup the queryStatus string based on the log data query results
  let queryStatus = "";
  if (isSuccess && newLogData) {
    queryStatus = newLogData.queryDurationExceeded === true ? "Query duration exceeded." : "";
    queryStatus = queryStatus.concat(
      newLogData.queryMaxCountExceeded === true
        ? ` Found more than ${newLogData.logEntries.length} log entries in timeframe.`
        : ""
    );
  }

  //turn off the query if it succeeded
  //will need to update for auto-update if that is ever implemented
  useEffect(() => {
    if (isSuccess) {
      setRetrieveData(false);
    }
    if (newLogData) {
      setLogData(newLogData.logEntries);
    }
  }, [isSuccess, newLogData]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetch();
    }
  }, [autoRefreshSeconds]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(makeQueryKey(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, logQueryProperties)) {
        setRunningQueryProperties(logQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  //if the row contains data,
  //put the data into a ref and open the dialog to show it
  const handleMessageCellClick = (event: CellClickedEvent) => {
    const selection = document.getSelection();
    if (selection && selection.type === "Range") {
      event.event?.stopPropagation();
    } else if (event.data) {
      logItemRef.current = event.data;
      setDialogOpen(true);
    }
  };

  //we don't want the logger name to crowd out everything else
  //so get the viewport size and limit the logger name to
  //a portion of the viewport width
  const { width: viewportWidth } = useWindowSize();
  const maxLoggerNameWidth = !viewportWidth ? 300 : viewportWidth / 5;
  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Logger Name",
        field: "loggerName",
        maxWidth: maxLoggerNameWidth,
        hide: onlyLoggerName,
      },
      {
        headerName: "Timestamp",
        field: "timestamp",
        width: 200,
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Severity",
        field: "logLevel",
        width: 90,
        valueFormatter: logLevelFormatter,
      },
      {
        headerName: "Message",
        field: "message",
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
        flex: 2,
        cellRenderer: LogMessageCellRenderer,
        onCellClicked: handleMessageCellClick,
      },
      {
        headerName: "Machine Name",
        field: "machineName",
        width: 270,
      },
    ] as Array<ColDef>;
  }, [viewportWidth]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  //auto-size all columns except message to fit their content
  //message is a flex column that will use the remaining space
  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeColumns(["loggerName"]);
  }, []);

  //hide the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleExportCSVClick = useCallback(() => {
    const firstLoggerName = logQueryProperties?.loggerNames ? logQueryProperties.loggerNames[0] : "";
    const defaultFileName = `LogData-${firstLoggerName}_${FormatDateYMD(new Date())}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "timestamp"
            ? FormatTimestampLocal(params.value)
            : colId == "logLevel"
            ? FormatLogLevel(params.value)
            : params.value?.toString();
        }
      },
    });
  }, [logQueryProperties]);

  const onSideSheetClose = () => {
    setSideSheetOpen(false);
  };

  const setLoggerNames = (loggerNames: Array<string>) => {
    dispatchLogQueryProperties({
      type: LogViewerActionTypeEnum.SetLoggerNames,
      loggerNames: loggerNames,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Max Row Count"
          value={logQueryProperties.maxCount}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetMaxCount,
              maxCount: +e.target.value,
            })
          }
          sx={{ width: "12ch" }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Timeframe"
          value={logQueryProperties.timeframe}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetTimeframe,
              timeframe: +e.target.value,
            })
          }
          sx={{ width: "16ch" }}
        >
          <MenuItem value={0}>Custom</MenuItem>
          <MenuItem value={1}>Past hour</MenuItem>
          <MenuItem value={8}>Past 8 hours</MenuItem>
          <MenuItem value={24}>Today</MenuItem>
          <MenuItem value={48}>Past 2 days</MenuItem>
          <MenuItem value={168}>This week</MenuItem>
        </TextField>
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Time"
          value={logQueryProperties.startTime}
          disabled={0 !== logQueryProperties.timeframe}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              dispatchLogQueryProperties({
                type: LogViewerActionTypeEnum.SetStartTime,
                startTime: newValue,
              });
            }
          }}
        />
        <DateTimePicker
          disabled={0 !== logQueryProperties.timeframe}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Time"
          value={logQueryProperties.endTime}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              dispatchLogQueryProperties({
                type: LogViewerActionTypeEnum.SetEndTime,
                endTime: newValue,
              });
            }
          }}
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Severity Level"
          value={logQueryProperties.severityLevel}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetSeverityLevel,
              level: +e.target.value,
            })
          }
          sx={{ width: "12ch" }}
        >
          <MenuItem value={0}>Trace</MenuItem>
          <MenuItem value={1}>Debug</MenuItem>
          <MenuItem value={2}>Info</MenuItem>
          <MenuItem value={3}>Warn</MenuItem>
          <MenuItem value={4}>Error</MenuItem>
          <MenuItem value={5}>Fatal</MenuItem>
        </TextField>
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={logQueryProperties.severityMinLevel}
              onChange={(e) =>
                dispatchLogQueryProperties({
                  type: LogViewerActionTypeEnum.SetSeverityMinLevel,
                  isMinLevel: e.target.checked,
                })
              }
            />
          }
          label="Minimum Level"
          labelPlacement="end"
        />
        <TextField
          size="small"
          variant="filled"
          label="Include Message Text"
          value={logQueryProperties.message}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetMessageText,
              messageText: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={logQueryProperties.regExMessage}
              onChange={(e) =>
                dispatchLogQueryProperties({
                  type: LogViewerActionTypeEnum.SetRegexMessage,
                  isRegEx: e.target.checked,
                })
              }
            />
          }
          label="RegEx"
          labelPlacement="end"
        />
        <TextField
          size="small"
          variant="filled"
          label="Exclude Message Text"
          value={logQueryProperties.excludeMessage}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetExcludeMessage,
              excludeText: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={logQueryProperties.regExExcludeMessage}
              onChange={(e) =>
                dispatchLogQueryProperties({
                  type: LogViewerActionTypeEnum.SetRegexExcludeMessage,
                  isRegEx: e.target.checked,
                })
              }
            />
          }
          label="RegEx"
          labelPlacement="end"
        />
        <TextField
          size="small"
          variant="filled"
          label="Machine Name Filter"
          value={logQueryProperties.machineName}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetMachineName,
              machineName: e.target.value,
            })
          }
          sx={{ width: "20ch" }}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={logQueryProperties.regExMachineName}
              onChange={(e) =>
                dispatchLogQueryProperties({
                  type: LogViewerActionTypeEnum.SetRegexMachineName,
                  isRegEx: e.target.checked,
                })
              }
            />
          }
          label="RegEx"
          labelPlacement="end"
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Request Duration"
          value={logQueryProperties.querySeconds}
          onChange={(e) =>
            dispatchLogQueryProperties({
              type: LogViewerActionTypeEnum.SetRequestDuration,
              duration: +e.target.value,
            })
          }
          sx={{ width: "14ch" }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={120}>120</MenuItem>
          <MenuItem value={180}>180</MenuItem>
          <MenuItem value={300}>300</MenuItem>
        </TextField>
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: "1em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Count: {newLogData?.logEntries?.length ?? 0}
        </Typography>
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1em",
          }}
        >
          {queryStatus}
        </Typography>
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "36px !important",
          }}
        >
          <Button onClick={handleExportCSVClick} variant="contained" disabled={!(logData && logData.length > 0)}>
            Export CSV
          </Button>
        </Box>
        {!onlyLoggerName && (
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto !important",
              marginRight: "2em !important",
            }}
          >
            <Tooltip title="Services">
              <IconButton
                size="large"
                aria-label="options"
                edge="end"
                sx={{
                  marginLeft: "auto",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
                onClick={() => setSideSheetOpen(!sideSheetOpen)}
              >
                <Tune />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={logData}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              onFirstDataRendered={autoSizeColumns}
              onGridReady={autoSizeColumns}
              onModelUpdated={autoSizeColumns}
            />
          </div>
        </Box>

        {/* This dialog shows the message text and exception text if a message cell with an exception is clicked */}
        {logItemRef.current && (
          <LogItemDialog logItem={logItemRef.current} dialogOpen={open} onClose={handleDialogClose} />
        )}
        {!onlyLoggerName && (
          <SideSheet title="System Services" open={sideSheetOpen} onClose={onSideSheetClose}>
            <SystemJobList setLoggerNames={setLoggerNames} />
          </SideSheet>
        )}
      </ContentContainer>
    </>
  );
}
