import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import {
  MsgQueueInfo,
  UseSendEmailMsgParameters,
  UseSendTextMsgParameters,
  UseSendVoiceMsgParameters,
} from "../../queries/outbound-msg/Models";
import { useSendEmailMsg } from "../../queries/outbound-msg/UseSendEmailMsg";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputCheckbox } from "../../components/form-components/FormInputCheckbox";
import { useSendTextMsg } from "../../queries/outbound-msg/UseSendTextMsg";
import { useSendVoiceMsg } from "../../queries/outbound-msg/UseSendVoiceMsg";

export type SendMessageProperties = {
  open: boolean;
  onClose: () => void;
  msgQueue: MsgQueueInfo;
};

type SendMessageFormInput = {
  conversationID: string;
  singleEmail: boolean;
  toAddress: string;
  fromAddress: string;
  ccAddress: string;
  replyToAddress: string;
  subject: string;
  body: string;
  attachmentList: string;
  preferredVoiceProvider: string;
  preferredTextProvider: string;
};

export default function SendMessage({ open, onClose, msgQueue }: SendMessageProperties) {
  const { control, handleSubmit, reset } = useForm<SendMessageFormInput>();
  const { displayToast } = useToastNotification();
  const { mutate: sendEmail } = useSendEmailMsg();
  const { mutate: sendVoice } = useSendVoiceMsg();
  const { mutate: sendText } = useSendTextMsg();

  //whenever the message queue changes, reset the values of our fields
  useEffect(() => {
    reset();
  }, [msgQueue]);

  const msgTypeName =
    msgQueue.msgType == 1 ? "Email" : msgQueue.msgType == 2 ? "Voice" : msgQueue.msgType == 4 ? "Text" : "Unknown";

  const onClick = useCallback(
    (data: SendMessageFormInput) => {
      if (data) {
        if (msgQueue.msgType == 1) {
          //create a list of strings from the comma separated attachmentList
          let attachments = new Array<string>();

          if (data.attachmentList && data.attachmentList != "") {
            const re = /[,;]/;
            attachments = data.attachmentList.split(re);
          }

          const emailMsg = {
            To: data.toAddress,
            SingleEmailToAllRecipients: data.singleEmail,
            SequenceID: data.conversationID,
            From: data.fromAddress,
            ReplyTo: data.replyToAddress,
            Cc: data.ccAddress,
            Subject: data.subject,
            Message: data.body,
            Attachments: attachments,
          } as UseSendEmailMsgParameters;

          sendEmail(emailMsg, {
            onSuccess: () =>
              displayToast({
                message: `Queued ${msgTypeName} successfully to ${data.toAddress}`,
                severity: "success",
              }),
            onError: (error) =>
              displayToast({
                message: `Error sending ${msgTypeName} to ${data.toAddress}: ${error}`,
                severity: "error",
                duration: 15000,
              }),
          });
        } else if (msgQueue.msgType == 2) {
          const voiceMsg = {
            To: data.toAddress,
            SequenceID: data.conversationID,
            Message: data.body,
            PreferredCallProviderType: data.preferredVoiceProvider,
          } as UseSendVoiceMsgParameters;

          sendVoice(voiceMsg, {
            onSuccess: () =>
              displayToast({
                message: `Queued ${msgTypeName} successfully to ${data.toAddress}`,
                severity: "success",
              }),
            onError: (error) =>
              displayToast({
                message: `Error sending ${msgTypeName} to ${data.toAddress}: ${error}`,
                severity: "error",
                duration: 15000,
              }),
          });
        } else if (msgQueue.msgType == 4) {
          const textMsg = {
            To: data.toAddress,
            SequenceID: data.conversationID,
            Message: data.body,
            PreferredProviderType: data.preferredTextProvider,
          } as UseSendTextMsgParameters;

          sendText(textMsg, {
            onSuccess: () =>
              displayToast({
                message: `Queued ${msgTypeName} successfully to ${data.toAddress}`,
                severity: "success",
              }),
            onError: (error) =>
              displayToast({
                message: `Error sending ${msgTypeName} to ${data.toAddress}: ${error}`,
                severity: "error",
                duration: 15000,
              }),
          });
        }

        //reset();
        onClose();
      }
    },
    [msgQueue]
  );

  return (
    (<Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Send {msgTypeName} Message</DialogTitle>
      <DialogContent dividers={true}>
        {msgQueue.msgType == 1 && (
          <FormInputCheckbox
            name="singleEmail"
            label="Single email to all recipients"
            inputType="email"
            control={control}
          />
        )}
        <FormInputText
          name="toAddress"
          label="To Address"
          inputType="text"
          helperText="List of to: addresses (comma separated)"
          control={control}
          rules={{
            required: { value: true, message: "To Address is required" },
          }}
        />
        <FormInputText
          name="conversationID"
          label="Conversation ID"
          helperText="ID to keep messages in a given conversation in order"
          control={control}
        />
        {msgQueue.msgType == 1 && (
          <>
            <FormInputText
              name="fromAddress"
              label="From Address"
              inputType="email"
              control={control}
              rules={{
                minLength: { value: 6, message: "From Address must contain at least 6 characters" },
                maxLength: { value: 256, message: "From Address cannot contain more than 256 characters" },
                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Invalid From address" },
              }}
            />
            <FormInputText
              name="ccAddress"
              label="cc Address"
              inputType="text"
              helperText="List of cc: addresses (comma separated)"
              control={control}
            />
            <FormInputText
              name="replyToAddress"
              label="Reply To Address"
              inputType="email"
              control={control}
              rules={{
                minLength: { value: 6, message: "Reply To Address must contain at least 6 characters" },
                maxLength: { value: 256, message: "Reply To Address cannot contain more than 256 characters" },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid Reply To address",
                },
              }}
            />
            <FormInputText
              name="subject"
              label="Subject"
              control={control}
              rules={{
                required: { value: true, message: "Subject is required" },
              }}
            />
          </>
        )}
        <FormInputText
          name="body"
          label="Body"
          control={control}
          rules={{
            required: { value: true, message: "Body is required" },
          }}
        />
        {msgQueue.msgType == 1 && (
          <FormInputText
            name="attachmentList"
            label="Attachment List"
            helperText="List of attachment names (comma separated)"
            control={control}
          />
        )}
        {msgQueue.msgType == 2 && (
          <FormInputText
            name="preferredVoiceProvider"
            label="Preferred Voice Provider"
            helperText="Preferred provider (Twilio or Esendex)"
            control={control}
          />
        )}
        {msgQueue.msgType == 4 && (
          <FormInputText
            name="preferredTextProvider"
            label="Preferred Text Provider"
            helperText="Preferred provider (Twilio or Plivo)"
            control={control}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Send
        </Button>
      </DialogActions>
    </Dialog>)
  );
}
