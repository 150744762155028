import React, { PropsWithChildren } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const FormInputSelect = ({
  name,
  label,
  helperText,
  control,
  rules,
  sx,
  children,
}: PropsWithChildren<FormInputProps>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={
            error && error.message && error.message.length > 0 ? error.message : helperText ? helperText : " "
          }
          select
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="filled"
          sx={sx}
        >
          {children}
        </TextField>
      )}
    />
  );
};
