export function distinctBy<TElement, TBy>(src: Array<TElement>, bySelector: (b: TElement) => TBy): Array<TElement> {
  const returnData = [] as Array<TElement>;
  const map = new Map();
  for (const item of src) {
    const key = bySelector(item);
    if (!map.has(key)) {
      map.set(key, true); // set any value to Map
      returnData.push(item);
    }
  }

  return returnData;
}
