import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker } from "@mui/x-date-pickers";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useQueryClient } from "react-query";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { useNonPolledDataConfig } from "../../queries/non-polled-data/UseNonPolledDataConfig";
import {
  NonPolledDataConfiguration,
  NonPolledDataItem,
  NonPolledDataQueryProperties,
} from "../../queries/non-polled-data/Models";
import { useNonPolledDataFileList } from "../../queries/non-polled-data/UseNonPolledDataFileList";
import isEqual from "react-fast-compare";
import { keys } from "../../queries/non-polled-data/Keys";
import { ColDef } from "ag-grid-community";
import IconsCellRenderer, {
  IconsCellRendererProps,
} from "../../components/ag-grid-extensions/renderers/IconsCellRenderer";
import { faRetweet, faDownload, faCodeBranch, faUpload } from "@elynx/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import { useResubmitFile } from "../../queries/non-polled-data/UseResubmitFile";
import { useGetBlobUrl } from "../../queries/non-polled-data/UseGetBlobUrl";
import { useCopyBlob } from "../../queries/non-polled-data/UseCopyBlob";
import { useUploadFile } from "../../queries/non-polled-data/UseUploadFile";
import { useResubmitFiles } from "../../queries/non-polled-data/UseResubmitFiles";
import StringIsNullOrWhitespace from "../../utilities/StringIsNullOrWhitespace";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import FileDownloader, { FileDownloaderType } from "../../components/file-downloader/FileDownloader";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";

const defaultNonPolledDataQueryProperties = {
  container: "",
  status: "",
  timeframe: 1,
  startDate: null,
  endDate: null,
  extension: "",
  filter: "",
  acquisitor: "",
  importer: "",
  maxCount: 25,
  replayTimespan: "01:00:00",
} as NonPolledDataQueryProperties;

export default function NonPolledData() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { displayToast } = useToastNotification();
  const [nonPolledDataQueryProperties, setNonPolledDataQueryProperties] = useState(defaultNonPolledDataQueryProperties);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultNonPolledDataQueryProperties);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const [refreshButtonEnabled, setRefreshButtonEnabled] = useState(false);
  const [resubmitAllButtonEnabled, setResubmitAllButtonEnabled] = useState(false);
  const fileDownloaderRef = useRef<FileDownloaderType>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const currentItemRef = useRef<NonPolledDataItem | null>(null);

  //non-polled data configuration
  const [nonPolledDataConfig, setNonPolledDataConfig] = useState<NonPolledDataConfiguration | null>();
  const { data: newConfig, isSuccess: configSuccess } = useNonPolledDataConfig();
  useEffect(() => {
    if (configSuccess === true && newConfig) {
      setNonPolledDataConfig(newConfig);
    }
  }, [configSuccess, newConfig]);

  //file list for running query
  const [retrieveData, setRetrieveData] = useState(false);
  const [dataFileList, setDataFileList] = useState<Array<NonPolledDataItem>>([]);
  const {
    data: newDataFileList,
    isFetching: dataFileListFetching,
    isSuccess: dataFileListSuccess,
    refetch: refetchDataFileList,
  } = useNonPolledDataFileList({
    enabled: retrieveData,
    autoRefreshSeconds: autoRefreshSeconds,
    fileListQueryProperties: runningQueryProperties,
  });

  //mutations for performing actions on files/blobs
  const { mutate: resubmitFile } = useResubmitFile();
  const { mutate: resubmitFiles } = useResubmitFiles();
  const { mutate: getBlobUrl } = useGetBlobUrl();
  const { mutate: copyBlob } = useCopyBlob();
  const { mutate: uploadFile } = useUploadFile();

  //store the new data when it arrives
  useEffect(() => {
    if (newDataFileList) {
      setDataFileList(newDataFileList);
    }
  }, [dataFileListSuccess, newDataFileList]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetchDataFileList();
    }
  }, [autoRefreshSeconds]);

  //if the UI or running query properties have changed, make changes to the UI
  //based on exactly which properties have changed
  useEffect(() => {
    //if the container is different than the running container
    //clear out the grid to avoid confusion
    if (nonPolledDataQueryProperties.container != runningQueryProperties.container) {
      setRetrieveData(false);
      setDataFileList([]);
      queryClient.removeQueries(keys.dataFileQueryKey(runningQueryProperties));
    }

    //if the container is empty, disable the refresh button
    const refreshEnabled = !StringIsNullOrWhitespace(nonPolledDataQueryProperties.container);
    setRefreshButtonEnabled(refreshEnabled);

    //if the refresh button is enabled
    //and the UI query and running query do not differ
    //(excluding properties specific to resubmission)
    //enable the resubmit all button
    setResubmitAllButtonEnabled(
      refreshEnabled &&
        isEqual(
          { ...nonPolledDataQueryProperties, maxCount: 0, replayTimespan: "" },
          { ...runningQueryProperties, maxCount: 0, replayTimespan: "" }
        )
    );
  }, [nonPolledDataQueryProperties, runningQueryProperties]);

  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (dataFileListFetching) {
      queryClient.cancelQueries(keys.dataFileQueryKey(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      setRetrieveData(true);
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, nonPolledDataQueryProperties)) {
        setRunningQueryProperties(nonPolledDataQueryProperties);
      }
      //if not, just refetch
      else {
        refetchDataFileList();
      }
    }
  };

  const handleResubmitAllButtonClick = () => {
    resubmitFiles(nonPolledDataQueryProperties, {
      onSuccess: () =>
        displayToast({
          message: "Successfully requested file resubmission",
          severity: "success",
        }),
      onError: (error) =>
        displayToast({
          message: `Error requesting file resubmission: ${error}`,
          severity: "error",
          duration: 15000,
        }),
    });
  };

  const handleResubmitFileClick = useCallback((item: NonPolledDataItem) => {
    if (item) {
      resubmitFile(item, {
        onSuccess: () =>
          displayToast({
            message: `Resubmitted ${item.name} successfully`,
            severity: "success",
          }),
        onError: (error) =>
          displayToast({
            message: `Error resubmitting ${item.name}: ${error}`,
            severity: "error",
            duration: 15000,
          }),
      });
    }
  }, []);

  const handleDownloadFileClick = useCallback((item: NonPolledDataItem) => {
    if (item) {
      getBlobUrl(item, {
        onSuccess: (data) => fileDownloaderRef.current?.download(data, data),
        onError: (error) =>
          displayToast({
            message: `Error getting blob Url for ${item.name}: ${error}`,
            severity: "error",
            duration: 15000,
          }),
      });
    }
  }, []);

  const handleForkFileClick = useCallback((item: NonPolledDataItem) => {
    if (item) {
      copyBlob(item, {
        onSuccess: (data) => fileDownloaderRef.current?.download(data, data),
        onError: (error) =>
          displayToast({
            message: `Error copying blob ${item.name}: ${error}`,
            severity: "error",
            duration: 15000,
          }),
      });
    }
  }, []);

  const handleUploadFileClick = useCallback((item: NonPolledDataItem) => {
    if (item && fileUploadRef.current) {
      currentItemRef.current = item;
      fileUploadRef.current.click();
    }
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Actions",
        colId: "actions",
        cellRenderer: IconsCellRenderer,
        cellRendererParams: {
          iconDescriptions: [
            {
              trueIcon: faRetweet,
              fontSize: "medium",
              onClick: handleResubmitFileClick,
              trueTooltipText: "Resubmit file",
              shouldDisplay: true,
            },
            {
              trueIcon: faDownload,
              fontSize: "medium",
              onClick: handleDownloadFileClick,
              trueTooltipText: "Download file",
              shouldDisplay: true,
            },
            {
              trueIcon: faCodeBranch,
              fontSize: "medium",
              onClick: handleForkFileClick,
              trueTooltipText: "Copy and download file",
              shouldDisplay: true,
            },
            {
              trueIcon: faUpload,
              fontSize: "medium",
              onClick: handleUploadFileClick,
              trueTooltipText: "Upnload file",
              shouldDisplay: (data: NonPolledDataItem) => data.editable ?? false,
            },
          ],
        } as IconsCellRendererProps,
        width: 200,
        autoHeight: true,
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 120,
      },
      {
        headerName: "Directory",
        field: "directory",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        width: 120,
      },
      {
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        flex: 1,
      },
      {
        headerName: "Last Modified",
        field: "lastModified",
        valueFormatter: LocalTimestampFormatter,
        width: 210,
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //handle the file changed event from the input of type "file" below
  const handleFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (files && currentItemRef.current) {
      const file = files[0];
      uploadFile(
        { item: currentItemRef.current, file: file },
        {
          onSuccess: () =>
            displayToast({
              message: `Uploaded ${file.name} successfully`,
              severity: "success",
            }),
          onError: (error) =>
            displayToast({
              message: `Error uploading file ${file.name}: ${error}`,
              severity: "error",
              duration: 15000,
            }),
        }
      );
      if (fileUploadRef.current) {
        fileUploadRef.current.value = "";
      }
    }
  };

  return (
    <>
      <ContentView>
        <ContentHeader title={"Non-Polled Data Operations"} />
        <Stack
          direction="row"
          spacing={1}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            select
            variant="filled"
            label="Inbound Data Type"
            value={nonPolledDataQueryProperties.container}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, container: e.target.value })
            }
            sx={{ width: "25ch" }}
          >
            {nonPolledDataConfig?.dataTypes.map((idt, inx) => (
              <MenuItem key={inx} value={idt.container}>
                {idt.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            select
            variant="filled"
            label="Status"
            value={nonPolledDataQueryProperties.status}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, status: e.target.value })
            }
            sx={{ width: "15ch" }}
          >
            {nonPolledDataConfig?.statuses.map((stat, inx) => (
              <MenuItem key={inx} value={stat}>
                {stat}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            select
            variant="filled"
            label="Timeframe"
            value={nonPolledDataQueryProperties.timeframe}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, timeframe: +e.target.value })
            }
            sx={{ width: "16ch" }}
          >
            <MenuItem value={-1}>Custom</MenuItem>
            <MenuItem value={1}>Today</MenuItem>
            <MenuItem value={0}>Yesterday</MenuItem>
            <MenuItem value={2}>Past 2 days</MenuItem>
            <MenuItem value={7}>This week</MenuItem>
            <MenuItem value={-2}>Most recent</MenuItem>
          </TextField>
          <DatePicker
            slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
            label="Start Date"
            value={nonPolledDataQueryProperties.startDate}
            disabled={-1 !== nonPolledDataQueryProperties.timeframe}
            onChange={(newValue: Date | null) => {
              if (newValue) {
                setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, startDate: newValue });
              }
            }}
          />
          <DatePicker
            disabled={-1 !== nonPolledDataQueryProperties.timeframe}
            slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
            label="End Date"
            value={nonPolledDataQueryProperties.endDate}
            onChange={(newValue: Date | null) => {
              if (newValue) {
                setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, endDate: newValue });
              }
            }}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="RPI Client"
            value={nonPolledDataQueryProperties.filter}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, filter: e.target.value })
            }
            sx={{ width: "28ch" }}
          >
            {nonPolledDataConfig?.rpiClients.map((rpi, inx) => (
              <MenuItem key={inx} value={rpi.filePrefix}>
                {rpi.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            variant="filled"
            label="Filter"
            value={nonPolledDataQueryProperties.filter}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, filter: e.target.value })
            }
            sx={{ width: "28ch" }}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Acquisitor"
            value={nonPolledDataQueryProperties.acquisitor}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, acquisitor: e.target.value })
            }
            sx={{ width: "28ch" }}
          >
            {nonPolledDataConfig?.acquisitorNames.map((acq, inx) => (
              <MenuItem key={inx} value={acq}>
                {acq}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            select
            variant="filled"
            label="Importer"
            value={nonPolledDataQueryProperties.importer}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, importer: e.target.value })
            }
            sx={{ width: "30ch" }}
          >
            {nonPolledDataConfig?.importerNames.map((imp, inx) => (
              <MenuItem key={inx} value={imp}>
                {imp}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            variant="filled"
            label="Blob text filter"
            value={nonPolledDataQueryProperties.contentFilter}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, contentFilter: e.target.value })
            }
            sx={{ width: "28ch" }}
          />
          <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch" }}>
            <Button
              disabled={!refreshButtonEnabled}
              onClick={() => handleRefreshButtonClick()}
              endIcon={dataFileListFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
          <TextField
            size="small"
            select
            variant="filled"
            label="Auto-refresh"
            value={autoRefreshSeconds}
            onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
            sx={{ width: "10ch", marginRight: "1em !important" }}
          >
            <MenuItem value={0}>Off</MenuItem>
            <MenuItem value={30}>30s</MenuItem>
            <MenuItem value={60}>1m</MenuItem>
            <MenuItem value={300}>5m</MenuItem>
            <MenuItem value={900}>15m</MenuItem>
          </TextField>
          <Typography
            variant="body2"
            noWrap
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Count: {dataFileList?.length ?? 0}
          </Typography>
          <TextField
            size="small"
            select
            variant="filled"
            label="Replay Timespan"
            title="Timespan to replay files"
            value={nonPolledDataQueryProperties.replayTimespan}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, replayTimespan: e.target.value })
            }
            sx={{ width: "18ch", marginLeft: "auto !important" }}
          >
            <MenuItem value={"00:00:01"}>ASAP</MenuItem>
            <MenuItem value={"01:00:00"}>1 Hour</MenuItem>
            <MenuItem value={"02:00:00"}>2 Hours</MenuItem>
            <MenuItem value={"04:00:00"}>4 hours</MenuItem>
            <MenuItem value={"08:00:00"}>8 hours</MenuItem>
          </TextField>
          <TextField
            size="small"
            select
            variant="filled"
            label="Max Files"
            title="Max Files To Resubmit"
            value={nonPolledDataQueryProperties.maxCount}
            onChange={(e) =>
              setNonPolledDataQueryProperties({ ...nonPolledDataQueryProperties, maxCount: +e.target.value })
            }
            sx={{ width: "12ch" }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={2500}>2500</MenuItem>
          </TextField>
          <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "20ch", marginRight: ".25em !important" }}>
            <Button
              disabled={!resubmitAllButtonEnabled}
              onClick={() => handleResubmitAllButtonClick()}
              endIcon={<FontAwesomeIcon icon={faRetweet} />}
              variant="contained"
            >
              Resubmit All
            </Button>
          </Box>
        </Stack>

        {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={dataFileList}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              suppressClickEdit={true}
              suppressCellFocus={true}
              alwaysMultiSort={true}
              columnMenu="legacy"
            />
          </div>
        </Box>
      </ContentView>
      <FileDownloader ref={fileDownloaderRef} />
      <input ref={fileUploadRef} hidden type="file" onChange={handleFileChanged} />
    </>
  );
}
