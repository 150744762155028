import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import InfoIcon from "@mui/icons-material/Info";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentSection from "../../components/content-section/ContentSection";
import ContentView from "../../components/content-view/ContentView";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import {
  defaultReportTestConfiguration,
  FtpConfiguration,
  ReportSchedulingDeliveryOverrideTypeEnum,
  ReportTask,
  ReportTestConfiguration,
} from "../../queries/report-scheduler/Models";
import {
  getRunScheduledReportsRequest,
  RunScheduledReportsRequest,
} from "../../queries/report-scheduler/RunScheduledReportsRequest";
import { testReportingActionReducer, TestReportingActionTypeEnum } from "./TestReportingActions";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useReportTasks } from "../../queries/report-scheduler/UseReportTasks";
import { useReportTestingConfig } from "../../queries/report-scheduler/UseReportTestingConfig";
import { useRunScheduledReport } from "../../queries/report-scheduler/UseRunScheduledReports";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { useTDTCustomerList } from "../../queries/tag-data-testing/UseTDTCustomerList";
import useDeepCompareEffect from "use-deep-compare-effect";

export type UIRunScheduledReportRequestTuple = {
  request: RunScheduledReportsRequest;
  selectedConfigName: string;
  enabled: boolean;
  autoRefreshMinutes: number;
  overrideFtp: boolean;
  overrideDelivery: boolean;
  selectSpecificTasks: boolean;
  retrieveTasksWithInactiveSchedules: boolean;
};

const GridItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.lowContrast,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
}));

export default function TestReporting() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { displayToast } = useToastNotification();
  const [configList, setConfigList] = useState<Array<ReportTestConfiguration>>([]);
  const { data: newTestingConfig, isSuccess: isConfigSuccess } = useReportTestingConfig();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useTDTCustomerList();
  const [ftpConfigList, setFtpConfigList] = useState<Array<FtpConfiguration>>([]);
  const [emailAddressList, setEmailAddressList] = useState<Array<string>>([]);
  const [uiRunScheduledReportRequest, dispatchUIRunScheduledReportRequest] = useReducer(testReportingActionReducer, {
    request: getRunScheduledReportsRequest(defaultReportTestConfiguration),
    selectedConfigName: "Custom",
    enabled: false,
    autoRefreshMinutes: 0,
    overrideFtp: false,
    overrideDelivery: true,
    selectSpecificTasks: false,
    retrieveTasksWithInactiveSchedules: false,
  });
  const [taskList, setTaskList] = useState<Array<ReportTask>>([]);
  const { data: newTaskList, isSuccess: isTaskSuccess } = useReportTasks({
    enabled: true,
    autoRefreshSeconds: 0,
    reportTaskQueryProperties: {
      customerID: uiRunScheduledReportRequest.request.customerID,
      includeTasksWithInactiveSchedules: uiRunScheduledReportRequest.retrieveTasksWithInactiveSchedules,
    },
  });
  useRunScheduledReport({
    enabled: uiRunScheduledReportRequest.enabled,
    autoRefreshSeconds: uiRunScheduledReportRequest.autoRefreshMinutes * 60,
    scheduledReportRequest: uiRunScheduledReportRequest.request,
    onSuccess: () =>
      displayToast({
        message: "Request to run report queued successfully",
        severity: "success",
      }),
    onError: (error: Error) =>
      displayToast({
        message: `Error sending request to run report: ${error}`,
        severity: "error",
        duration: 15000,
      }),
  });

  useEffect(() => {
    if (isConfigSuccess === true && newTestingConfig) {
      if (newTestingConfig.testConfigurations.length > 0) {
        setConfigList([defaultReportTestConfiguration, ...newTestingConfig.testConfigurations]);
      }
      if (newTestingConfig.ftpConfigurations.length > 0) {
        setFtpConfigList(newTestingConfig.ftpConfigurations);
      }
      if (newTestingConfig.emailAddresses.length > 0) {
        setEmailAddressList(newTestingConfig.emailAddresses);
      }
    }
  }, [isConfigSuccess, newTestingConfig]);

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      //prepend a special entry for all customers
      const allCustomers = [{ id: -1, label: "-- All Customers --" }];
      const newCustomers = newCustomerList.map((c) => {
        return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
      });

      setCustomerList([...allCustomers, ...newCustomers]);
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    if (isTaskSuccess === true && newTaskList) {
      setTaskList(newTaskList);
    }
  }, [isTaskSuccess, newTaskList]);

  useDeepCompareEffect(() => {
    if (uiRunScheduledReportRequest.request.taskIDs && uiRunScheduledReportRequest.request.taskIDs.length == 0) {
      gridRef.current?.api?.deselectAll();
    }
  }, [uiRunScheduledReportRequest.request.taskIDs]);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Selected",
        colId: "selected",
        checkboxSelection: true,
        minWidth: 100,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.isSelected() === nodeB.isSelected()) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else {
            return nodeA.isSelected() ? 1 : -1;
          }
        },
      },
      {
        headerName: "Task ID",
        field: "id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Task Name",
        field: "name",
        sort: "asc",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows: Array<ReportTask> | undefined = gridRef.current?.api.getSelectedRows();
    if (selectedRows) {
      dispatchUIRunScheduledReportRequest({
        type: TestReportingActionTypeEnum.SetTaskIDs,
        taskIDs: selectedRows.map((t) => t.id),
      });
    }
  }, []);

  const onRowDataChanged = useCallback(() => {
    if (gridRef.current != null && gridRef.current.api) {
      gridRef.current.api.forEachNode((node) => {
        const sel = !!node.data && uiRunScheduledReportRequest.request.taskIDs.includes(node.data.id);
        //console.log(`ID: ${node.data?.id}, sel: ${sel}`);
        node.setSelected(sel);
      });
    }
  }, [uiRunScheduledReportRequest.request.taskIDs]);

  const handleEmailAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const addresses: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        addresses.push(options[i].value);
      }
    }
    dispatchUIRunScheduledReportRequest({
      type: TestReportingActionTypeEnum.SetEmailAddresses,
      addresses: addresses,
    });
  };

  const handleRunReportButtonClick = () => {
    dispatchUIRunScheduledReportRequest({
      type: TestReportingActionTypeEnum.SetRunEnabled,
      flag: true,
    });
  };

  return (
    <ContentView>
      <ContentHeader title="Report Scheduler Test Utility" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Test Configuration"
          value={uiRunScheduledReportRequest.selectedConfigName}
          onChange={(e) =>
            dispatchUIRunScheduledReportRequest({
              type: TestReportingActionTypeEnum.SetTestingConfig,
              testConfig: configList.find((c) => c.name == e.target.value),
            })
          }
          sx={{ width: "30ch" }}
        >
          {configList.map((c, inx) => {
            return (
              <MenuItem key={inx} value={c.name}>
                {c.name}
              </MenuItem>
            );
          })}
        </TextField>
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          value={
            customerList.find((c) => c.id === uiRunScheduledReportRequest.request.customerID) ?? {
              id: -1,
              label: "-- All Customers --",
            }
          }
          onChange={(event, value) => {
            if (value) {
              dispatchUIRunScheduledReportRequest({
                type: TestReportingActionTypeEnum.SetCustomer,
                customerID: +value.id,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={uiRunScheduledReportRequest.request.isTestMessage}
              onChange={(e) =>
                dispatchUIRunScheduledReportRequest({
                  type: TestReportingActionTypeEnum.SetTestMode,
                  flag: e.target.checked,
                })
              }
            />
          }
          label="Messages test mode"
          title="Flag messages as test mode"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={uiRunScheduledReportRequest.request.deleteReportAfterDelivery}
              onChange={(e) =>
                dispatchUIRunScheduledReportRequest({
                  type: TestReportingActionTypeEnum.SetDeleteReport,
                  flag: e.target.checked,
                })
              }
            />
          }
          label="Delete report"
          title="Delete report blob after delivery"
          labelPlacement="end"
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={uiRunScheduledReportRequest.request.disableDBUpdate}
              onChange={(e) =>
                dispatchUIRunScheduledReportRequest({
                  type: TestReportingActionTypeEnum.SetDisableUpdateDB,
                  flag: e.target.checked,
                })
              }
            />
          }
          label="Disable DB Update"
          title="Disable DB Update"
          labelPlacement="end"
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "19ch", marginLeft: "auto !important" }}>
          <Button onClick={() => handleRunReportButtonClick()} variant="contained">
            Send Request
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto resend request"
          value={uiRunScheduledReportRequest.autoRefreshMinutes}
          onChange={(e) =>
            dispatchUIRunScheduledReportRequest({
              type: TestReportingActionTypeEnum.SetAutoResend,
              minutes: +e.target.value,
            })
          }
          sx={{ width: "15ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={15}>15m</MenuItem>
          <MenuItem value={30}>30m</MenuItem>
          <MenuItem value={60}>1h</MenuItem>
        </TextField>
      </Stack>
      <ContentSection>
        <Grid
          container
          direction="row"
          columnSpacing={0}
          rowSpacing={0}
          sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
        >
          {/* Tasks */}
          <Grid item md={12} xl={6}>
            {/* <GridItem sx={{ minHeight: "20vh" }}> */}
            <GridItem>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Tasks</Typography>
                <FormControlLabel
                  value="ml"
                  control={
                    <Checkbox
                      sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                      checked={uiRunScheduledReportRequest.request.useSingleSchedule}
                      onChange={(e) =>
                        dispatchUIRunScheduledReportRequest({
                          type: TestReportingActionTypeEnum.SetUseSingleSchedule,
                          flag: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Use single schedule"
                  title="Ensure each report task is only run once, even if it is scheduled more than once"
                  labelPlacement="end"
                />
                <RadioGroup
                  value={uiRunScheduledReportRequest.selectSpecificTasks.toString()}
                  onChange={(e) =>
                    dispatchUIRunScheduledReportRequest({
                      type: TestReportingActionTypeEnum.SetRunSelectedTasks,
                      flag: e.target.value === "true",
                    })
                  }
                >
                  <FormControlLabel value="false" control={<Radio />} label="Run all tasks" />
                  <FormControlLabel
                    disabled={uiRunScheduledReportRequest.request.customerID < 0}
                    value="ml"
                    control={
                      <Checkbox
                        disabled={uiRunScheduledReportRequest.request.customerID < 0}
                        sx={{ paddingLeft: "5ch", paddingRight: "4px" }}
                        checked={uiRunScheduledReportRequest.request.includeUnscheduledTasks}
                        onChange={(e) =>
                          dispatchUIRunScheduledReportRequest({
                            type: TestReportingActionTypeEnum.SetIncludeUnscheduledTasks,
                            flag: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Include unscheduled tasks (when single customer is specified)"
                    labelPlacement="end"
                  />
                  <FormControlLabel value="true" control={<Radio />} label="Run specific tasks" />
                  <FormControlLabel
                    disabled={!uiRunScheduledReportRequest.selectSpecificTasks}
                    value="ml"
                    control={
                      <Checkbox
                        disabled={!uiRunScheduledReportRequest.selectSpecificTasks}
                        sx={{ paddingLeft: "5ch", paddingRight: "4px" }}
                        checked={uiRunScheduledReportRequest.retrieveTasksWithInactiveSchedules}
                        onChange={(e) =>
                          dispatchUIRunScheduledReportRequest({
                            type: TestReportingActionTypeEnum.SetRetrieveTasksWithInactiveSchedules,
                            flag: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Retrieve tasks with inactive schedules"
                    labelPlacement="end"
                  />
                </RadioGroup>
                {/* It appears that in order to make the grid fit into a flex scheme 
        it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
                <Box
                  sx={{
                    display: "flex",
                    flex: "1 1 auto",
                    minHeight: "20vh",
                    "& .ag-theme-alpine .ag-cell-value": {
                      lineHeight: "20px !important",
                      wordBreak: "normal",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    },
                    "& .ag-theme-alpine-dark .ag-cell-value": {
                      lineHeight: "20px !important",
                      wordBreak: "normal",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    },
                  }}
                >
                  <div className={gridTheme} style={{ display: "flex", flex: "1 1 auto" }}>
                    <AgGridReact
                      ref={gridRef}
                      defaultColDef={defaultColDef}
                      rowData={taskList}
                      columnDefs={columnDefs}
                      ensureDomOrder={true}
                      enableCellTextSelection={true}
                      rowSelection={uiRunScheduledReportRequest.selectSpecificTasks ? "multiple" : undefined}
                      rowMultiSelectWithClick={true}
                      onSelectionChanged={onSelectionChanged}
                      onFirstDataRendered={autoSizeColumns}
                      onRowDataUpdated={onRowDataChanged}
                      containerStyle={{ width: "100%" }}
                    />
                  </div>
                </Box>
              </Stack>
            </GridItem>
          </Grid>
          {/* Override Dates */}
          <Grid item md={6} xl={3}>
            <GridItem>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Override Dates</Typography>
                <FormControlLabel
                  value="ml"
                  control={
                    <Checkbox
                      sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                      checked={uiRunScheduledReportRequest.request.overrideReportDates}
                      onChange={(e) =>
                        dispatchUIRunScheduledReportRequest({
                          type: TestReportingActionTypeEnum.SetOverrideDatesFlag,
                          flag: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Override Report Dates"
                  labelPlacement="end"
                />
                <DateTimePicker
                  disabled={!uiRunScheduledReportRequest.request.overrideReportDates}
                  slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
                  label="Start"
                  value={uiRunScheduledReportRequest.request.startDateOverride}
                  onChange={(newValue: Date | null) => {
                    if (newValue) {
                      dispatchUIRunScheduledReportRequest({
                        type: TestReportingActionTypeEnum.SetStartDate,
                        startDate: newValue,
                      });
                    }
                  }}
                />
                <DateTimePicker
                  disabled={!uiRunScheduledReportRequest.request.overrideReportDates}
                  slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
                  label="End"
                  value={uiRunScheduledReportRequest.request.endDateOverride}
                  onChange={(newValue: Date | null) => {
                    if (newValue) {
                      dispatchUIRunScheduledReportRequest({
                        type: TestReportingActionTypeEnum.SetEndDate,
                        endDate: newValue,
                      });
                    }
                  }}
                />
              </Stack>
            </GridItem>
          </Grid>
          {/* Override Email */}
          <Grid item md={6} xl={3}>
            <GridItem>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Override Email</Typography>
                <FormControlLabel
                  value="ml"
                  control={
                    <Checkbox
                      sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                      checked={uiRunScheduledReportRequest.request.overrideEmail}
                      onChange={(e) =>
                        dispatchUIRunScheduledReportRequest({
                          type: TestReportingActionTypeEnum.SetOverrideEmailFlag,
                          flag: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Override Email Delivery/Options"
                  labelPlacement="end"
                  title="Any email deliveries configured for the task will be redirected to the recipients selected below."
                />
                <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }}>
                  <InputLabel shrink htmlFor="select-addresses">
                    Addresses
                  </InputLabel>
                  <Select
                    disabled={!uiRunScheduledReportRequest.request.overrideEmail}
                    multiple
                    native
                    label="Addresses"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore Typings are not considering `native`
                    value={uiRunScheduledReportRequest.request.emailOverrides}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore Typings are not considering `native`
                    onChange={handleEmailAddressChange}
                    inputProps={{
                      id: "select-addresses",
                    }}
                  >
                    {emailAddressList.map((eml, inx) => (
                      <option key={inx} value={eml}>
                        {eml}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  variant="filled"
                  label="Subject"
                  value={uiRunScheduledReportRequest.request.emailSubject}
                  onChange={(e) =>
                    dispatchUIRunScheduledReportRequest({
                      type: TestReportingActionTypeEnum.SetEmailSubject,
                      subject: e.target.value,
                    })
                  }
                  sx={{ width: "40ch" }}
                />
                <TextField
                  size="small"
                  variant="filled"
                  multiline
                  rows={4}
                  label="Body"
                  value={uiRunScheduledReportRequest.request.emailBody}
                  onChange={(e) =>
                    dispatchUIRunScheduledReportRequest({
                      type: TestReportingActionTypeEnum.SetEmailBody,
                      body: e.target.value,
                    })
                  }
                  sx={{ width: "40ch" }}
                />
              </Stack>
            </GridItem>
          </Grid>
          {/* Override FTP */}
          <Grid item md={6} xl={3}>
            <GridItem>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Override FTP</Typography>
                <FormControlLabel
                  value="ml"
                  control={
                    <Checkbox
                      sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                      checked={uiRunScheduledReportRequest.overrideFtp}
                      onChange={(e) =>
                        dispatchUIRunScheduledReportRequest({
                          type: TestReportingActionTypeEnum.SetOverrideFtpFlag,
                          flag: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Override FTP Delivery"
                  labelPlacement="end"
                />
                <TextField
                  disabled={!uiRunScheduledReportRequest.overrideFtp}
                  size="small"
                  select
                  variant="filled"
                  label="FTP Delivery Override"
                  value={uiRunScheduledReportRequest.request.ftpOverride}
                  onChange={(e) =>
                    dispatchUIRunScheduledReportRequest({
                      type: TestReportingActionTypeEnum.SetFtpOverride,
                      ftpAddress: e.target.value,
                    })
                  }
                  sx={{ width: "30ch" }}
                >
                  {ftpConfigList.map((cfg, inx) => (
                    <MenuItem key={inx} value={cfg.configName}>
                      {cfg.configName}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </GridItem>
          </Grid>
          {/* Override Deliveries */}
          <Grid item md={6} xl={3}>
            <GridItem>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Override Deliveries</Typography>
                <TextField
                  size="small"
                  select
                  variant="filled"
                  label="Delivery Override"
                  value={uiRunScheduledReportRequest.request.deliveryOverrideType}
                  onChange={(e) =>
                    dispatchUIRunScheduledReportRequest({
                      type: TestReportingActionTypeEnum.SetDeliveryOverride,
                      deliveryType: +e.target.value,
                    })
                  }
                  sx={{ width: "20ch" }}
                >
                  <MenuItem value={ReportSchedulingDeliveryOverrideTypeEnum.Default}>No override</MenuItem>
                  <MenuItem value={ReportSchedulingDeliveryOverrideTypeEnum.ForceEmail}>Force Email</MenuItem>
                  <MenuItem value={ReportSchedulingDeliveryOverrideTypeEnum.ForceFTP}>Force FTP</MenuItem>
                  <MenuItem value={ReportSchedulingDeliveryOverrideTypeEnum.NoDelivery}>No delivery</MenuItem>
                </TextField>
                <Typography variant="h6">
                  <br />
                  <InfoIcon fontSize="small" />
                  &nbsp;&nbsp;Note
                </Typography>
                <Typography>
                  Selecting any option other than &quot;No override&quot; will ignore any deliveries configured for the
                  task/schedule. When Force Email is selected, deliveries will be generated for the repcipients selected
                  in Override Email Delivery. When Force FTP is selected, a delivery will be generated for configuration
                  selected in Override FTP Delivery.
                </Typography>
              </Stack>
            </GridItem>
          </Grid>
        </Grid>
      </ContentSection>
    </ContentView>
  );
}
