import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";

//custom query hook
export function useMetricsPrimaryKeys(metricName: string) {
  const { axios: ax } = useAxios();

  return useQuery<Array<string>, Error>(
    keys.primaryKeys(metricName),
    async () => {
      const { data } = await ax.get<Array<string>>(`metrics/getmetricprimarykeyoptions/${metricName}`);
      return data;
    },
    {
      staleTime: 5 * 60 * 1000, //we will use data that is stale because the metrics primary keys don't change much at all
      refetchInterval: 15 * 60 * 1000, //but let's auto-refresh the data
    }
  );
}
