import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { queryClient } from "../../services/query-client-service/QueryClientService";
import { keys } from "./Keys";

export type DeleteDeviceCopyJobParameters = {
  jobID: number;
};

export function useDeleteDeviceCopyJob() {
  const { axios: ax } = useAxios();

  return useMutation<boolean, Error, DeleteDeviceCopyJobParameters>(
    async ({ jobID }) => {
      const { data } = await ax.delete("devcpy/job", {
        params: {
          jobID: jobID,
        },
      });
      return data;
    },
    {
      onSuccess: (_data, variables) => {
        //force a refresh of the list from SQL
        queryClient.invalidateQueries({ queryKey: keys.list() });
        //and wipe out everything related to the deleted job
        queryClient.invalidateQueries({ queryKey: keys.job(variables.jobID) });
      },
    }
  );
}
