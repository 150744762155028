import React from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Divider, IconButton } from "@mui/material";

import { isMobile, isTablet } from "react-device-detect";

export type StepMenuTitleProps = {
  title: string;
  showCancel?: boolean;
  onCancel?: () => void;
  additionalAction?: JSX.Element;
};

export default function StepMenuTitle({ title, showCancel, onCancel, additionalAction }: StepMenuTitleProps) {
  const isPhone = isMobile && !isTablet;

  return (
    <>
      <Box
        sx={{
          fontWeight: 400,
          padding: 1,
          paddingTop: 2,
          paddingBottom: 2,
          display: "flex",
          backgroundColor: isPhone ? "custom.background.1" : undefined,
        }}
      >
        {showCancel && (
          <IconButton
            edge="start"
            sx={{
              marginRight: 1,
            }}
            color="inherit"
            onClick={() => onCancel && onCancel()}
            aria-label="close"
          >
            <ArrowBack />
          </IconButton>
        )}

        <Box display="flex" flex="1 1 auto" marginTop="auto" marginBottom={"auto"}>
          {title}
        </Box>

        <Box>{additionalAction}</Box>
      </Box>
      {!isPhone ? <Divider orientation="vertical" flexItem variant="middle" /> : null}
    </>
  );
}
