import React from "react";
import { DeviceCopyJob } from "../../../queries/copy-device/Models";
import { CopyDeviceJobActionTypeEnum, CopyDeviceJobActions } from "../CopyDeviceJobActions";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

export type CopyOptionsProps = {
  job: DeviceCopyJob;
  dispatchJob: React.Dispatch<CopyDeviceJobActions>;
};

export default function CopyOptions({ job, dispatchJob }: CopyOptionsProps) {
  return (
    <Stack direction="column" sx={{ marginLeft: 2 }}>
      <FormControlLabel
        value="ml"
        control={
          <Checkbox
            sx={{ paddingLeft: "0px", paddingRight: "4px" }}
            checked={job?.copySetpoints}
            disabled={!job}
            onChange={(e) =>
              dispatchJob({
                type: CopyDeviceJobActionTypeEnum.SetCopySetpoints,
                include: e.target.checked,
              })
            }
          />
        }
        label="Copy Setpoints"
        labelPlacement="end"
      />
      <FormControlLabel
        value="ml"
        control={
          <Checkbox
            sx={{ paddingLeft: "0px", paddingRight: "4px" }}
            checked={job?.copyEventLog}
            disabled={!job}
            onChange={(e) =>
              dispatchJob({
                type: CopyDeviceJobActionTypeEnum.SetCopyEventLogs,
                include: e.target.checked,
              })
            }
          />
        }
        label="Copy Event Logs"
        labelPlacement="end"
      />
      <FormControlLabel
        value="ml"
        control={
          <Checkbox
            sx={{ paddingLeft: "0px", paddingRight: "4px" }}
            checked={job?.copyTagValues}
            disabled={!job}
            onChange={(e) =>
              dispatchJob({
                type: CopyDeviceJobActionTypeEnum.SetCopyTagValues,
                include: e.target.checked,
              })
            }
          />
        }
        label="Copy Tag Values and Tag Thread Instances and Details"
        labelPlacement="end"
      />
      <Typography variant="h6" sx={{ marginLeft: "-11px", marginTop: "15px" }}>
        {"Date Range for Copied Data"}
      </Typography>

      <Box sx={{ paddingTop: "5px" }}>
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Copied Data Start Time"
          value={job?.dataStartTime}
          onChange={(newValue: Date | null | undefined) => {
            dispatchJob({
              type: CopyDeviceJobActionTypeEnum.SetDataStartTime,
              startTime: newValue,
            });
          }}
        />
      </Box>
      <Box sx={{ paddingTop: "10px" }}>
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Copied Data End Time"
          value={job?.dataEndTime}
          onChange={(newValue: Date | null | undefined) => {
            dispatchJob({
              type: CopyDeviceJobActionTypeEnum.SetDataEndTime,
              endTime: newValue,
            });
          }}
        />
      </Box>
    </Stack>
  );
}
