import React, { useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import { EventHubStatusData } from "../../queries/event-hub-status/Models";
import { numberComparator } from "../../components/ag-grid-extensions/comparators/NumberComparator";
import AutoWrapHeaderGrid from "../../components/wrap-header-grid/AutoWrapHeaderGrid";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";

export type EventHubPartitionGridProps = {
  partitionData: Array<EventHubStatusData>;
  gridSize: number; //will be 4, 6, or 8 (out of 12 grid slots)
  selectedIndex: number;
};

export default function EventHubPartitionGrid({ partitionData, selectedIndex }: EventHubPartitionGridProps) {
  const gridRef = useRef<AgGridReact>(null);
  const shouldResize = useRef(true);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;

  useEffect(() => {
    //if the selected index has changed, allow a resize
    shouldResize.current = true;
  }, [selectedIndex]);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Partition ID",
        field: "partitionID",
        comparator: numberComparator,
      },
      {
        headerName: "Minutes Behind",
        field: "minutesBehind",
        comparator: numberComparator,
      },
      {
        headerName: "Gain/Loss (Minutes)",
        field: "gainLoss",
        comparator: numberComparator,
      },
      { headerName: "Messages Behind", field: "sequenceDelta", comparator: numberComparator },
      { headerName: "Gain/Loss (Count)", field: "sequenceGainLoss", comparator: numberComparator },
      {
        headerName: "Pod Name",
        field: "processorName",
      },
      {
        headerName: "Hub Sequence Number",
        field: "hubSequence",
        comparator: numberComparator,
      },
      {
        headerName: "Monitor Sequence Number",
        field: "monitorSequence",
        comparator: numberComparator,
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = (columnApi: GridApi) => {
    if (columnApi && true === shouldResize.current) {
      shouldResize.current = false;
      setTimeout(() => {
        //because this is timing based,
        //there are sometimes errors because the grid is already doing something else
        //when this is called
        //that siutation should not be fatal
        try {
          columnApi.autoSizeAllColumns(true);
        } catch (error) {
          console.error(`Error sizing all EventHubPartitionGrid columns: ${error}`);
        }
      }, 500);
    }
  };

  return (
    <>
      {/* It appears that in order to make the grid fit into a flex scheme 
it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AutoWrapHeaderGrid
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={partitionData}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            //onFirstDataRendered={(event) => autoSizeColumns(event?.api)}
            // onGridReady={(event) => autoSizeColumns(event?.api)}
            onModelUpdated={(event) => autoSizeColumns(event?.api)}
            alwaysMultiSort={true}
          />
        </div>
      </Box>
    </>
  );
}
