import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { DeviceCopyJob } from "./Models";
import { keys } from "./Keys";

export type UseRunDeviceCopyJobOptions = {
  onSuccess?: (job: DeviceCopyJob) => void;
};

export function useRunDeviceCopyJob({ onSuccess }: UseRunDeviceCopyJobOptions) {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<DeviceCopyJob, Error, DeviceCopyJob>(
    async (job) => {
      const { data } = await ax.post<DeviceCopyJob>("devcpy/runjob", job);
      return data;
    },
    {
      onSuccess: (data) => {
        //force a refresh of the list from SQL
        queryClient.invalidateQueries({ queryKey: [keys.list] });

        //save the returned job status in the cache
        queryClient.setQueryData(keys.job(data.jobID), data);

        //and pass the saved job status object to the callback
        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
}
