import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";

//include an error icon if there is an exception
export default function LogMessageCellRenderer({ value, valueFormatted, data }: ICellRendererParams) {
  return (
    <Box
      sx={{
        display: "-webkit-box",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        "-webkit-line-clamp": "8",
        "-webkit-box-orient": "vertical",
      }}
    >
      {data && data.exception && data.exception.length > 0 ? (
        <ErrorIcon
          sx={{
            height: ".7em",
            width: ".7em",
            verticalAlign: "text-top",
            marginRight: ".2em",
          }}
        />
      ) : (
        ""
      )}
      {valueFormatted || value}
    </Box>
  );
}
