import { Checkbox, FormControlLabel } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";

export type CheckboxRendererProps = {
  disabled?: boolean;
  label?: string | null | ((data: unknown) => string);
  onChecked?: ((data: unknown, checked: boolean) => void) | null;
} & ICellRendererParams<unknown, boolean | null>;

export default function CheckboxCellRenderer({
  value,
  node,
  column,
  disabled,
  label,
  onChecked,
}: CheckboxRendererProps) {
  function onCheckedInternal(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (onChecked) {
      onChecked(node.data, checked);
    } else {
      const colId = column?.getColId();
      if (colId) {
        node.setDataValue(colId, checked);
      }
    }
  }

  const labelResult = typeof label === "function" ? label(node.data) : label ?? "";
  const shouldDisplayLabel = null != value && labelResult.length > 0;
  const shouldDisplayNoLabel = null != value && labelResult.length === 0;

  return (
    <>
      {shouldDisplayNoLabel && (
        <Checkbox disabled={disabled} size="small" checked={value} onChange={onCheckedInternal} />
      )}
      {shouldDisplayLabel && (
        <FormControlLabel
          control={<Checkbox disabled={disabled} size="small" checked={value} onChange={onCheckedInternal} />}
          label={labelResult}
        />
      )}
    </>
  );
}
