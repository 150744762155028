export type EventProcessingItem = {
  index: number;
  name: string;
  monitorName: string;
  metricName: string;
  statusName: string;
};

const eventProcessingItems: Array<EventProcessingItem> = [
  {
    index: -1,
    name: "",
    statusName: "",
    monitorName: "",
    metricName: "",
  },
  {
    index: 0,
    name: "Data Handler",
    statusName: "tagdata-processor",
    monitorName: "tagdata",
    metricName: "Data Handler Service for tagdata",
  },
  {
    index: 1,
    name: "Data Handler DR",
    statusName: "tagdata-dr-processor",
    monitorName: "tagdata-dr",
    metricName: "Data Handler Service for tagdata-dr",
  },
  {
    index: 2,
    name: "Simple Alarming",
    statusName: "simple-alarm-processor",
    monitorName: "postprocessing-alarmdata",
    metricName: "Data Post Processing Service for postprocessing-alarmdata",
  },
  {
    index: 3,
    name: "Variance Alarming",
    statusName: "variance-alarm-processor",
    monitorName: "postprocessing-variance-alarmdata",
    metricName: "Data Post Processing Service for postprocessing-variance-alarmdata",
  },
  {
    index: 4,
    name: "Tag Calculation",
    statusName: "tagcalculation-processor",
    monitorName: "tagcalculation",
    metricName: "Tag Calculation Consumer Service",
  },
  {
    index: 5,
    name: "Synthetic Tag Threads",
    statusName: "synthetictagthread-tagdata-processor",
    monitorName: "synthetictagthread-tagdata",
    metricName: "Data Post Processing Service for synthetictagthread-tagdata",
  },
  {
    index: 6,
    name: "Logging Storage",
    statusName: "logging-storage-processor",
    monitorName: "logging-storage",
    metricName: "Event Hub Logging Processor for logging-storage",
  },
  {
    index: 7,
    name: "Metrics Storage",
    statusName: "metrics-storage-processor",
    monitorName: "metrics-storage",
    metricName: "Event Hub Metrics Processor for metrics-storage",
  },
  {
    index: 8,
    name: "Data Forwarding",
    statusName: "forward-tagdata-processor",
    monitorName: "forward-tagdata",
    metricName: "Event Hub Forwarding Service for forward-tagdata",
  },
  {
    index: 9,
    name: "Data Forwarding API",
    statusName: "forward-tagdata-api-processor",
    monitorName: "forward-tagdata-api",
    metricName: "Event Hub Forwarding Service for forward-tagdata-api",
  },
  {
    index: 10,
    name: "Data Forwarding DR",
    statusName: "forward-tagdata-dr-processor",
    monitorName: "forward-tagdata-dr",
    metricName: "Event Hub Forwarding Service for forward-tagdata-dr",
  },
  {
    index: 11,
    name: "Rules Engine",
    statusName: "rulesengine-processor",
    monitorName: "rulesengine",
    metricName: "Rules Engine Consumer Service",
  },
  {
    index: 12,
    name: "Iot Hub 1",
    statusName: "iot-hub-1-processor",
    monitorName: "iot-hub-1",
    metricName: "IoT Hub Data Ingestion Service for iot-hub-1",
  },
];

export default eventProcessingItems;
