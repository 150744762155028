import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { UseClearCacheParameters } from "./Models";

export function useClearCache() {
  const { axios: ax } = useAxios();

  return useMutation<boolean, Error, UseClearCacheParameters>(async ({ customerID, cacheConfiguration }) => {
    const { data } = await ax.delete(`cache/caches/${cacheConfiguration.cacheID}/customers/${customerID}`);
    return data;
  });
}
