export enum CustomerTypeEnum {
  AllCustomers = 0,
  Energy = 1,
  Water = 2,
}

export type CustomerModel = {
  customerID: number;
  customerName: string;
  customerEnabled: boolean;
  cust_allow_sso: boolean;
  cust_has_scheduled_rules: boolean;
  cust_has_calculated_tags: boolean;
  cust_created_timestamp: Date | undefined;
  cust_modified_timestamp: Date | undefined;
  cust_disabled_timestamp: Date | undefined;
  cust_has_apim_users: boolean;
  cust_max_days_in_report: number | undefined;
  cust_max_concurrent_reports: number | undefined;
  cust_type: string;
};
