import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from "@mui/material";
import { eLynxUserModel } from "../../queries/elynx-user/Models";
import { useForm } from "react-hook-form";
import { FormInputSelect } from "../../components/form-components/FormInputSelect";

export type EnableUserProperties = {
  user: eLynxUserModel;
  open: boolean;
  setRole: boolean;
  onClose: () => void;
  enableUser: (user: eLynxUserModel) => void;
};

type EnableUserFormInput = {
  agentRole: string;
};

export default function EnableUser({ user, open, setRole, onClose, enableUser }: EnableUserProperties) {
  const { control, handleSubmit, reset } = useForm<EnableUserFormInput>({
    defaultValues: { agentRole: "" },
  });

  useEffect(() => {
    //reset the form each time it is opened
    if (true === open) {
      reset();
    }
  }, [open]);

  const onClick = (data: EnableUserFormInput) => {
    //create the user and send it to the enable user function
    const newRoleId = data.agentRole.startsWith("All") ? 2 : 3;
    const newUser = { ...user, agent_role: data.agentRole, agent_role_id: newRoleId } as eLynxUserModel;

    enableUser(newUser);

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>{setRole ? "Set Role" : "Enable User"}</DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="body2">{`Role in which to enable eLynx user ${user.gl_login_name}?`}</Typography>
        <FormInputSelect
          name="agentRole"
          label="Agent Role"
          control={control}
          sx={{ width: "25ch" }}
          rules={{
            required: { value: true, message: "Agent Role is required" },
          }}
        >
          <MenuItem value="All Customers">All Customers</MenuItem>
          <MenuItem value="internal Databases Only">Internal Databases Only</MenuItem>
        </FormInputSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
