import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { TDTTagValue } from "./Models";

export type UseTDTTagValueParameters = {
  customerID: number;
  tagID: number;
  enabled: boolean;
  autoRefreshSeconds: number;
};

//custom query hook
export function useTDTTagValue({ customerID, tagID, enabled, autoRefreshSeconds }: UseTDTTagValueParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.tagValue(customerID, tagID);

  return useQuery<TDTTagValue, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<TDTTagValue>(`testtagdata/tagvalue/${customerID}/${tagID}`);
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
}
