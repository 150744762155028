import { format } from "date-fns";
import { ExportTagDataRequest } from "../../queries/tag-data-export/ExportTagDataRequest";

//these are the strings used by the reducer
export enum ExportTagDataActionTypeEnum {
  SetCustomer = "set-customer",
  SetStartDate = "set-start-time",
  SetEndDate = "set-end-time",
  SetIncludeDisabledDevices = "set-disabled_devices",
  SetIncludeHiddenDevices = "set-hidden_devices",
  SetIncludeDynacards = "set-dynacards",
  SetCompressFiles = "set-compress-files",
  SetOrganization = "set-organization",
  SetGroup = "set-group",
  SetDevices = "set-devices",
  ResetDeviceSelection = "reset-device-selection",
}

//define a type per action to enforce typing very strictly
export type SetCustomer = {
  type: ExportTagDataActionTypeEnum.SetCustomer;
  customerID: number;
};

export type SetStartDateAction = {
  type: ExportTagDataActionTypeEnum.SetStartDate;
  startTime: Date;
};

export type SetEndDateAction = {
  type: ExportTagDataActionTypeEnum.SetEndDate;
  endTime: Date;
};

export type SetDisabledDevicesAction = {
  type: ExportTagDataActionTypeEnum.SetIncludeDisabledDevices;
  include: boolean;
};

export type SetHiddenDevicesAction = {
  type: ExportTagDataActionTypeEnum.SetIncludeHiddenDevices;
  include: boolean;
};

export type SetIncludeDynacardsAction = {
  type: ExportTagDataActionTypeEnum.SetIncludeDynacards;
  include: boolean;
};

export type SetCompressFilesAction = {
  type: ExportTagDataActionTypeEnum.SetCompressFiles;
  compress: boolean;
};

export type SetOrganization = {
  type: ExportTagDataActionTypeEnum.SetOrganization;
  organizationID: number;
};

export type SetGroup = {
  type: ExportTagDataActionTypeEnum.SetGroup;
  groupID: number;
};

export type SetDevices = {
  type: ExportTagDataActionTypeEnum.SetDevices;
  deviceGuids: Array<string>;
};

export type ResetDeviceSelection = {
  type: ExportTagDataActionTypeEnum.ResetDeviceSelection;
};

//an action sent to the reducer can be any of the above individual actions
export type ExportTagDataActions =
  | SetCustomer
  | SetStartDateAction
  | SetEndDateAction
  | SetDisabledDevicesAction
  | SetHiddenDevicesAction
  | SetIncludeDynacardsAction
  | SetCompressFilesAction
  | SetOrganization
  | SetGroup
  | SetDevices
  | ResetDeviceSelection;

//and now the reducer itself
export const exportTagDataActionReducer = (
  state: ExportTagDataRequest,
  action: ExportTagDataActions
): ExportTagDataRequest => {
  const newState = createNewState(state, action);
  newState.requestDescription = createDescription(newState);
  return newState;
};

const createNewState = (state: ExportTagDataRequest, action: ExportTagDataActions): ExportTagDataRequest => {
  switch (action.type) {
    //if the customer has changed, reset the device selection properties
    case ExportTagDataActionTypeEnum.SetCustomer:
      return {
        ...state,
        customerID: action.customerID,
        organizationID: null,
        groupID: null,
        deviceIDs: [],
      };
    case ExportTagDataActionTypeEnum.SetStartDate:
      return {
        ...state,
        startDate: action.startTime,
      };
    case ExportTagDataActionTypeEnum.SetEndDate:
      return {
        ...state,
        endDate: action.endTime,
      };
    case ExportTagDataActionTypeEnum.SetIncludeDisabledDevices:
      return {
        ...state,
        disabledDevices: action.include,
      };
    case ExportTagDataActionTypeEnum.SetIncludeHiddenDevices:
      return {
        ...state,
        hiddenDevices: action.include,
      };
    case ExportTagDataActionTypeEnum.SetIncludeDynacards:
      return {
        ...state,
        includeDynacards: action.include,
      };
    case ExportTagDataActionTypeEnum.SetCompressFiles:
      return {
        ...state,
        compressFiles: action.compress,
      };
    case ExportTagDataActionTypeEnum.SetOrganization:
      return {
        ...state,
        organizationID: action.organizationID,
      };
    case ExportTagDataActionTypeEnum.SetGroup:
      return {
        ...state,
        groupID: action.groupID,
        organizationID: action.groupID && action.groupID > 0 ? null : state.organizationID,
      };
    case ExportTagDataActionTypeEnum.SetDevices:
      return {
        ...state,
        deviceIDs: action.deviceGuids,
        groupID: action.deviceGuids && action.deviceGuids.length > 0 ? null : state.groupID,
        organizationID: action.deviceGuids && action.deviceGuids.length > 0 ? null : state.organizationID,
      };
    case ExportTagDataActionTypeEnum.ResetDeviceSelection:
      return {
        ...state,
        organizationID: null,
        groupID: null,
        deviceIDs: [],
      };
    default:
      return state;
  }
};

const createDescription = (request: ExportTagDataRequest): string => {
  let description = "Export tag data";

  let startDateString = "<earliest>";
  if (request.startDate) {
    startDateString = format(request.startDate, "P");
  }
  let endDateString = "<latest>";
  if (request.endDate) {
    endDateString = format(request.endDate, "P");
  }
  description = `${description} from ${startDateString} to ${endDateString}`;

  if (request.deviceIDs && request.deviceIDs.length > 0) {
    description =
      request.deviceIDs.length == 1
        ? `${description} for device ${request.deviceIDs[0]}`
        : `${description} for multiple devices`;
  } else {
    let containerSelected = false;
    if (request.groupID && request.groupID > 0) {
      description = `${description} for all devices in group ID ${request.groupID}`;
      containerSelected = true;
    } else if (request.organizationID && request.organizationID > 0) {
      description = `${description} for all devices in organization ID ${request.organizationID}`;
      containerSelected = true;
    }
    if (containerSelected) {
      const includeText =
        request.hiddenDevices && request.disabledDevices
          ? " including disabled and hidden devices"
          : request.disabledDevices
          ? " including disabled devices"
          : request.hiddenDevices
          ? " including hidden devices"
          : "";
      description = `${description}${includeText}`;
    }
  }
  if (request.customerID && request.customerID > 0) {
    description = `${description} in customer ID ${request.customerID}`;
  }
  if (request.includeDynacards === true) {
    description = `${description} including dynacards`;
  }
  if (request.compressFiles === true) {
    description = `${description} into compressed files`;
  }

  return description;
};
