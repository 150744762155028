export type ExportTagDataRequest = {
  customerID: number | null;
  startDate: Date | null;
  endDate: Date | null;
  disabledDevices: boolean;
  hiddenDevices: boolean;
  includeDynacards: boolean;
  compressFiles: boolean;
  organizationID: number | null;
  groupID: number | null;
  deviceIDs: Array<string>;
  requestDescription: string;
};

export const defaultExportTagDataRequest = {
  customerID: null,
  startDate: null,
  endDate: null,
  disabledDevices: false,
  hiddenDevices: false,
  includeDynacards: true,
  compressFiles: true,
  organizationID: null,
  groupID: null,
  deviceIDs: [],
  requestDescription: "Export tag data...",
} as ExportTagDataRequest;
