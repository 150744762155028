import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { UseControlSystemJobParameters } from "./Models";

export function useControlSystemJob() {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UseControlSystemJobParameters>(
    async (parameters) => {
      const isTopshelf = parameters.job.topshelfService != null;      
      const jobText = isTopshelf ? parameters.job.topshelfService : parameters.job.manifestText;
      const url = isTopshelf
                    ? `topshelfservice/control/${jobText}/${parameters.action}`
                    : `workerrole/control/${jobText}/${parameters.action}`;

      await ax.post(url);
    },
    {
      //if the call succeeds, invalidate the jobs data a little ways into the future
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries(keys.all), 5000);
      },
    }
  );
}
