import React, { useEffect, useReducer, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "react-query";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { keys } from "../../queries/audit-trail/Keys";
import { AuditTrailItem } from "../../queries/audit-trail/Models";
import { useAuditTrail } from "../../queries/audit-trail/UseAuditTrail";
import { auditTrailActionReducer, AuditTrailActionTypeEnum } from "./AuditTrailActions";
import AuditTrailGrid from "./AuditTrailGrid";
import defaultAuditTrailQueryProperties from "./DefaultAuditTrailProperties";
import isEqual from "react-fast-compare";

export default function AuditTrail() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [retrievData, setRetrieveData] = useState(false);
  const [auditTrailData, setAuditTrailData] = useState<Array<AuditTrailItem>>([]);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultAuditTrailQueryProperties);
  const [auditTrailQueryProperties, dispatchAuditTrailQueryProperties] = useReducer(
    auditTrailActionReducer,
    defaultAuditTrailQueryProperties
  );

  //use the log data query to retrieve data for the currently running set of properties
  const {
    data: newAuditTrailData,
    isFetching,
    isSuccess,
    refetch,
  } = useAuditTrail({
    enabled: retrievData,
    auditTrailQueryProperties: runningQueryProperties,
  });

  //turn off the query if it succeeded
  //will need to update for auto-update if that is ever implemented
  useEffect(() => {
    if (isSuccess) {
      setRetrieveData(false);
    }
    if (newAuditTrailData) {
      setAuditTrailData(newAuditTrailData);
    }
  }, [isSuccess, newAuditTrailData]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.all);
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, auditTrailQueryProperties)) {
        setRunningQueryProperties(auditTrailQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  return (
    <ContentView>
      <ContentHeader title={"Audit Trail"} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Max Row Count"
          value={auditTrailQueryProperties.ocat_max_count}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetMaxCount,
              maxCount: +e.target.value,
            })
          }
          sx={{ width: "12ch" }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Timeframe"
          value={auditTrailQueryProperties.timeframe}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetTimeframe,
              timeframe: +e.target.value,
            })
          }
          sx={{ width: "16ch" }}
        >
          <MenuItem value={0}>Custom</MenuItem>
          <MenuItem value={1}>Past hour</MenuItem>
          <MenuItem value={8}>Past 8 hours</MenuItem>
          <MenuItem value={24}>Today</MenuItem>
          <MenuItem value={48}>Past 2 days</MenuItem>
          <MenuItem value={168}>This week</MenuItem>
        </TextField>
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Time"
          value={auditTrailQueryProperties.ocat_start_timestamp}
          disabled={0 !== auditTrailQueryProperties.timeframe}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              dispatchAuditTrailQueryProperties({
                type: AuditTrailActionTypeEnum.SetStartTime,
                startTime: newValue,
              });
            }
          }}
        />
        <DateTimePicker
          disabled={0 !== auditTrailQueryProperties.timeframe}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Time"
          value={auditTrailQueryProperties.ocat_end_timestamp}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              dispatchAuditTrailQueryProperties({
                type: AuditTrailActionTypeEnum.SetEndTime,
                endTime: newValue,
              });
            }
          }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Username"
          value={auditTrailQueryProperties.ocat_username}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetUsername,
              username: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="IP Address"
          value={auditTrailQueryProperties.ocat_ip_address}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetIpAddress,
              ipAddress: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Action"
          value={auditTrailQueryProperties.ocat_action}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetAction,
              action: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="View"
          value={auditTrailQueryProperties.ocat_view}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetView,
              view: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Object Type"
          value={auditTrailQueryProperties.ocat_object_type_id}
          //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetObjectType,
              objectType: +e.target.value,
            })
          }
          sx={{ width: "14ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Object ID"
          value={auditTrailQueryProperties.ocat_object_id}
          //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetObjectID,
              objectID: +e.target.value,
            })
          }
          sx={{ width: "12ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Object Name"
          value={auditTrailQueryProperties.ocat_object_name}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetObjectName,
              objectName: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Old Value"
          value={auditTrailQueryProperties.ocat_old_value}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetOldValue,
              oldValue: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="New Value"
          value={auditTrailQueryProperties.ocat_new_value}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetNewValue,
              newValue: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <TextField
          size="small"
          variant="filled"
          label="Description"
          value={auditTrailQueryProperties.ocat_description}
          onChange={(e) =>
            dispatchAuditTrailQueryProperties({
              type: AuditTrailActionTypeEnum.SetDescription,
              description: e.target.value,
            })
          }
          sx={{ width: "22ch" }}
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Count: {newAuditTrailData?.length ?? 0}
        </Typography>
      </Stack>
      <AuditTrailGrid auditTrailData={auditTrailData} />
    </ContentView>
  );
}
