import React, { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

//input and output types
export type ToastNotificationProps = {
  message: string;
  severity?: AlertColor;
  duration?: number | null;
};

export type ToastNotificationContextType = {
  displayToast: ({ message, severity, duration }: ToastNotificationProps) => void;
};

export type ToastNotificationProviderProps = {
  children: ReactNode;
};

//internal type
type ToastNotificationState = {
  open: boolean;
  message: string;
  severity: AlertColor;
  duration: number | null;
};

const defaultNotificationState = {
  open: false,
  message: "",
  severity: "success",
  duration: 5000,
} as ToastNotificationState;

//the context itself
const ToastNotificationContext = createContext<ToastNotificationContextType | undefined>(undefined);

//the provider component
export function ToastNotificationProvider({ children }: ToastNotificationProviderProps) {
  const [notificationState, setNotificationState] = useState<ToastNotificationState>(defaultNotificationState);

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    const newState = {
      ...notificationState,
      open: false,
    };

    setNotificationState(newState);
  };

  const displayToast = useCallback(({ message, severity, duration }: ToastNotificationProps) => {
    //display starts a new state, so we don't need the previous state
    const newState = {
      ...defaultNotificationState,
      open: true,
      message: message,
      severity: severity ? severity : defaultNotificationState.severity,
      duration: duration !== undefined ? duration : defaultNotificationState.duration,
    } as ToastNotificationState;
    setNotificationState(newState);
  }, []);

  const providerValue = {
    displayToast: displayToast,
  };

  return (
    <>
      <ToastNotificationContext.Provider value={providerValue}>{children}</ToastNotificationContext.Provider>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notificationState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={notificationState.duration}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={notificationState.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </>
  );
}

//the hook to use the ToastNotificationContext
export function useToastNotification() {
  const context = useContext(ToastNotificationContext);
  if (!context) {
    throw new Error("useToastNotification must be used within a ToastNotificationProvider");
  }

  return context;
}
