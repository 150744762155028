import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { ISystemJob } from "../../queries/system-jobs/Models";
import { faSquare, faClock, faOctagon, faLightbulb, faInfoCircle, faCircle } from "@elynx/pro-solid-svg-icons";
import { faSquareList } from "@elynx/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLogData } from "../../queries/log-data/UseLogData";
import { ILogItem } from "../../queries/log-data/Models";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { useControlSystemJob } from "../../queries/system-jobs/UseControlSystemJob";
import { isMobile, isTablet } from "react-device-detect";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";
import { FormatLogLevel } from "../../components/log-data-viewer/LogDataViewer";

export type ServicePanelProps = {
  job: ISystemJob;
  smallRowCount: number;
  openLogItemDialog: (logItem: ILogItem) => void;
  maxLinesPerLogRow: number;
  autoRefreshSeconds: number;
  openPodListDialog: (podList: Array<string>) => void;
  openLogDataViewer: (job: ISystemJob) => void;
  openRunJobDialog: (job: ISystemJob) => void;
};

const largeRowCount = 50;

export default function ServicePanel({
  job,
  smallRowCount,
  openLogItemDialog,
  maxLinesPerLogRow,
  autoRefreshSeconds,
  openPodListDialog,
  openLogDataViewer,
  openRunJobDialog,
}: ServicePanelProps) {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const confirm = useConfirm();
  const { displayToast } = useToastNotification();
  const [logData, setLogData] = useState<Array<ILogItem>>([]);
  const [rowCount, setRowCount] = useState(smallRowCount);
  const [maxLines, setMaxLines] = useState(maxLinesPerLogRow);
  const {
    data: newLogData,
    isFetching: isFetchingLogData,
    refetch: refetchLogData,
  } = useLogData({
    enabled: true,
    logQueryProperties: { loggerNames: [job.loggerName], maxCount: rowCount },
    autoRefreshSeconds: autoRefreshSeconds,
  });
  const { mutate: controlJob } = useControlSystemJob();

  //put new log data into our state
  useEffect(() => {
    if (newLogData) {
      setLogData(newLogData.logEntries);
    }
  }, [newLogData]);

  //put a new small row count into our state
  //unless we are currently expanded
  useEffect(() => {
    if (rowCount !== largeRowCount) {
      setRowCount(smallRowCount);
    }
  }, [smallRowCount]);

  //put new max lines per log item into our state
  useEffect(() => {
    if (maxLinesPerLogRow) {
      setMaxLines(maxLinesPerLogRow);
    }
  }, [maxLinesPerLogRow]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetchLogData();
    }
  }, [autoRefreshSeconds]);

  const jobStatusValid = job.status && null !== job.status;

  const getColor = (status: string | undefined) => {
    const statusValid = Boolean(status);
    const isRunning = !statusValid || status === "Running";
    const isStopped = !statusValid || status === "Stopped";

    return statusValid ? (isStopped || isRunning ? (isRunning ? "green" : "red") : "silver") : "goldenrod";
  };

  const startJob = () => {
    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Start Service Confirmation",
      description: `Are you sure you want to start ${job.name}?`,
      allowClose: false,
    })
      .then(() => {
        console.log(`Starting service ${job.name}`);

        //make the actual call to start the service
        controlJob({ job: job, action: "start" });

        //let the user know that succeeded
        displayToast({ message: `Requesting job ${job.name} to start.` });

        //and queue up refreshes of the log data and job data
        setTimeout(() => refetchLogData(), 1000);
        setTimeout(() => refetchLogData(), 5000);
      })
      .catch(() => {
        console.log(`Starting service ${job.name} has been cancelled!`);
      });
  };

  const stopJob = () => {
    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Stop Service Confirmation",
      description: `Are you sure you want to stop ${job.name}?`,
      allowClose: false,
    })
      .then(() => {
        console.log(`Stopping service ${job.name}`);

        //make the actual call to stop the service
        controlJob({ job: job, action: "stop" });

        //let the user know that succeeded
        displayToast({ message: `Requesting job ${job.name} to stop.` });

        setTimeout(() => refetchLogData(), 1000);
        setTimeout(() => refetchLogData(), 5000);
      })
      .catch(() => {
        console.log(`Stopping service ${job.name} has been cancelled!`);
      });
  };

  return (
    <Card variant="outlined" sx={{ overflowX: "auto" }}>
      <CardContent>
        {/* Main row container for entire card */}
        <Stack direction="row" spacing={1}>
          {/* Large icon in first column indicating service status */}
          <Tooltip title={job.status || ""}>
            <span className="fa-layers fa-fw fa-3x" style={{ display: "flex", minWidth: "60px", marginTop: "6px" }}>
              {jobStatusValid ? (
                <>
                  <FontAwesomeIcon icon={faOctagon} color={getColor(job.status)} />
                  <FontAwesomeIcon icon={faLightbulb} transform="shrink-6" inverse />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSquare} color={getColor(job.status)} />
                  <FontAwesomeIcon icon={faClock} transform="shrink-6" inverse />
                </>
              )}
            </span>
          </Tooltip>

          {/* Stack of action icons in second column */}
          <Stack direction="column">
            {job?.runJobEndpoint && (
              <Tooltip title={`Run iteration of job '${job.name}'`}>
                <IconButton onClick={() => openRunJobDialog(job)}>
                  <SendIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            )}

            {isFetchingLogData ? (
              <CircularProgress color="inherit" size={51} sx={{ padding: "8px" }} />
            ) : (
              <Tooltip title={`Reload log entries for job '${job.name}'`}>
                <IconButton onClick={() => refetchLogData()}>
                  <RefreshIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title={rowCount === largeRowCount ? "Show fewer log entries" : "Show more log entries"}>
              <IconButton onClick={() => setRowCount(rowCount === largeRowCount ? smallRowCount : largeRowCount)}>
                {rowCount === largeRowCount ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              </IconButton>
            </Tooltip>

            {!isPhone && (
              <Tooltip title={`Search log entries for job '${job.name}'`}>
                <IconButton onClick={() => openLogDataViewer(job)}>
                  <SearchIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            )}

            {job?.canControl && job.canControl === true && (
              <>
                <Tooltip title={`Start service '${job.name}'`}>
                  <IconButton onClick={startJob}>
                    <PlayCircleFilledIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Stop service '${job.name}'`}>
                  <IconButton onClick={stopJob}>
                    <StopCircleIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>

          {/* Stack containing service info and log grid in third column */}
          <Stack direction="column" alignItems="flex-start" width="100%">
            {/* Flex row for service name, logger copy button, and deployment info */}
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
              <Box display="flex">{job.name}</Box>
              <Box display="flex">
                <CopyToClipboard text={job.loggerName} onCopy={() => displayToast({ message: "Copied to clipboard" })}>
                  <Tooltip title="Copy logger name to clipboard" placement="top-start">
                    <IconButton>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </Box>

              {/* Flex row for the deployment info box */}
              {job?.deployments && job.deployments.length > 0 && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.neutral.lowContrast,
                    border: "1px solid",
                    borderRadius: 1.5,
                    padding: "3px",
                    minWidth: "350px",
                  }}
                >
                  <Box display="flex" paddingTop="5px">
                    <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                  </Box>
                  <Box display="flex">Deployments:</Box>
                  <Box display="flex">
                    <List disablePadding>
                      {job?.deployments?.map((d, inx) => {
                        return (
                          <ListItem key={inx} disablePadding>
                            <Tooltip title={d.deploymentStatus || ""}>
                              <ListItemIcon sx={{ minWidth: "20px" }}>
                                <FontAwesomeIcon icon={faCircle} size="sm" color={getColor(d.deploymentStatus)} />
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ marginTop: 0, marginBottom: 0 }} primary={d.deploymentName} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  {job?.podNames && job.podNames.length > 0 && (
                    <Tooltip title="Click to see pod/node list">
                      <Chip
                        icon={<FontAwesomeIcon icon={faSquareList} size="sm" />}
                        label="Pods"
                        size="small"
                        sx={{ minWidth: "6em" }}
                        onClick={() => openPodListDialog(job.podNames || new Array<string>())}
                      />
                    </Tooltip>
                  )}
                </Stack>
              )}
            </Stack>
            <Box display="flex" width="100%" marginTop="3px">
              <Table
                sx={{
                  //minWidth: 650,
                  width: "100%",
                  "& .MuiTableCell-root": {
                    border: `1px solid ${theme.palette.neutral.lowContrast}`,
                  },
                  "& .MuiTableCell-head": {
                    backgroundColor: `${theme.palette.neutral.lowContrast}`,
                    fontWeight: 700,
                    border: `1px solid ${theme.palette.neutral.mediumContrast}`,
                  },
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={185} sx={{ whiteSpace: "nowrap" }}>
                      Timestamp
                    </TableCell>
                    <TableCell width={100}>Severity</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell width={260} sx={{ whiteSpace: "nowrap" }}>
                      Machine Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData.map((row, inx) => (
                    <TableRow key={inx}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {FormatTimestampLocal(row.timestamp.toString())}
                      </TableCell>
                      <TableCell>{FormatLogLevel(row.logLevel)}</TableCell>
                      <TableCell
                        onClick={(e) => {
                          const selection = document.getSelection();
                          if (selection && selection.type === "Range") {
                            e.stopPropagation();
                          } else {
                            openLogItemDialog(row);
                          }
                        }}
                      >
                        <Box
                          sx={{
                            display: "-webkit-box",
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            "-webkit-line-clamp": `${maxLines}`,
                            "-webkit-box-orient": "vertical",
                            cursor: "pointer",
                          }}
                        >
                          {row.message}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>{row.machineName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>{" "}
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
