import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputText } from "../form-components/FormInputText";
import { ConfigurationSettingModel } from "../../queries/environment-configuration/Models";

export type AddConfigItemProperties = {
  open: boolean;
  onClose: () => void;
  saveConfigItem: (configItem: ConfigurationSettingModel) => void;
  defaultSettingValue: string | null;
};

type AddConfigItemFormInput = {
  settingId: string;
  settingValue: string;
};

export default function AddConfigItem({ open, onClose, saveConfigItem, defaultSettingValue }: AddConfigItemProperties) {
  const { control, handleSubmit, reset, setValue } = useForm<AddConfigItemFormInput>({
    defaultValues: { settingId: "", settingValue: "" },
  });

  useEffect(() => {
    //reset the form each time it is opened
    if (true === open) {
      reset();
      setValue("settingValue", defaultSettingValue ?? "");
    }
  }, [open, defaultSettingValue]);

  const onClick = useCallback((data: AddConfigItemFormInput) => {
    //create the configuration item and send it to the save  function
    const newConfigItem = {
      settingId: data.settingId,
      settingValue: data.settingValue,
    } as ConfigurationSettingModel;

    saveConfigItem(newConfigItem);
    onClose();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Add Configuration Item</DialogTitle>
      <DialogContent dividers={true}>
        <FormInputText
          name="settingId"
          label="Configuration Item Key"
          control={control}
          rules={{
            required: { value: true, message: "Configuration Item Key is required" },
            minLength: { value: 3, message: "Configuration Item Key must contain at least 3 characters" },
            maxLength: { value: 256, message: "Configuration Item Key cannot contain more than 256 characters" },
          }}
        />
        <FormInputText
          name="settingValue"
          label="Configuration Item Value"
          control={control}
          rules={{
            required: { value: true, message: "Configuration Item Value is required" },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
