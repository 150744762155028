import React, { PropsWithChildren } from "react";
import { Stack } from "@mui/material";

export default function SideSheetContent({
  children,
}: PropsWithChildren<unknown>) {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        overflowY: "auto",
        flexGrow: 1,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {children}
    </Stack>
  );
}
