export type EventHubProcessingMonitorQueryProperties = {
  configurationKey: string;
  timeframe: string;
};

export type EventHubProcessingMonitorData = {
  timestamp: string;
  maxMinutesBehind: number;
  avgMinutesBehind: number;
  maxMessagesBehind: number;
  avgMessagesBehind: number;
  tsEpoch?: number;
};

export type MetricFilterQueryParam = {
  id: number;
  field: string;
  type: string;
  operator: string;
  value: string;
};

export function getEmptyMetricFilterQueryParam() {
  return {
    field: "",
    type: "",
    operator: "",
    value: "",
  } as MetricFilterQueryParam;
}

export type MetricsQueryProperties = {
  maxCount?: number;
  startTime: Date | null;
  endTime: Date | null;
  primaryKey: string | null;
  metricType: string;
  filterItems: Array<MetricFilterQueryParam>;
  timeframe: number;
};

export type MetricRowBase = {
  PartitionKey: string;
  RowKey: string;
  Timestamp: Date;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MetricRow = MetricRowBase & any;

export type MetricItem = {
  index: number;
  name: string;
  displayName: string;
};
