import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IconRendererProps } from "./IconCellRenderer";
import FontAwesomeIconButton from "../../font-awesome-icon-button/FontAwesomeIconButton";

export type IconsCellRendererProps = {
  iconDescriptions: Array<IconRendererProps>;
} & ICellRendererParams;

export default function IconsCellRenderer({ iconDescriptions, node }: IconsCellRendererProps) {
  return (
    <>
      {iconDescriptions.map(
        (d, inx) =>
          true === (typeof d.shouldDisplay === "function" ? d.shouldDisplay(node.data) : d.shouldDisplay) && (
            <FontAwesomeIconButton
              key={inx}
              icon={d.trueIcon}
              fontSize={d.fontSize}
              iconSize={d.iconSize}
              onClick={() => d.onClick(node.data)}
              disabled={d.disabled}
              title={d.trueTooltipText}
            />
          )
      )}
    </>
  );
}
