import React, { ReactElement, PropsWithChildren } from "react";
import { useAxios } from "../../contexts/axios-context/AxiosContext";

//simple template to show children if we have an access token
//and return null otherwise
export default function HasTokenTemplate({ children }: PropsWithChildren<unknown>): ReactElement | null {
  const { hasAccessToken } = useAxios();

  if (hasAccessToken) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }

  return null;
}