import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ConsolidatedEventHubStatusData, EventHubStatusQueryProperties } from "./Models";

export type UseEventHubStatusParameters = {
  enabled: boolean;
  eventHubStatusQueryProperties: EventHubStatusQueryProperties;
};

export function useEventHubStatus({ enabled, eventHubStatusQueryProperties }: UseEventHubStatusParameters) {
  const previousData = useRef<ConsolidatedEventHubStatusData>();
  const { axios: ax } = useAxios();

  useEffect(() => {
    previousData.current = undefined;
  }, [eventHubStatusQueryProperties]);

  const createDeltasAndSummary = (data: ConsolidatedEventHubStatusData) => {
    const combinedData = { ...data } as ConsolidatedEventHubStatusData;

    //iterate the partitions getting summary info
    //and possibly deltas
    let gainLoss = 0;
    let minutesBehind = 0;
    let sequenceDelta = 0;
    let sequenceGainLoss = 0;
    let maxMinutesBehind: number | null = null;

    combinedData.partitionData.map((d) => {
      const previous = previousData.current?.partitionData.find((p) => p.partitionID === d.partitionID);
      if (previous) {
        d.gainLoss = previous.minutesBehind - d.minutesBehind;
        d.sequenceGainLoss = previous.sequenceDelta - d.sequenceDelta;
      }
      d.formattedHubSequence = d.hubSequence.toLocaleString();
      d.formattedMonitorSequence = d.monitorSequence.toLocaleString();
      gainLoss += d.gainLoss;
      minutesBehind += d.minutesBehind;
      sequenceDelta += d.sequenceDelta;
      sequenceGainLoss += d.sequenceGainLoss;
      maxMinutesBehind = null != maxMinutesBehind ? Math.max(maxMinutesBehind, d.minutesBehind) : d.minutesBehind;
    });

    //build the summary object
    combinedData.summaryData = {
      totalGainLoss: isNaN(gainLoss) ? null : gainLoss,
      totalMinutesBehind: isNaN(minutesBehind) ? null : minutesBehind,
      totalSequenceDelta: isNaN(sequenceDelta) ? null : sequenceDelta,
      totalSequenceGainLoss: isNaN(sequenceGainLoss) ? null : sequenceGainLoss,
      totalMaxMinutesBehind: null !== maxMinutesBehind ? (isNaN(maxMinutesBehind) ? null : maxMinutesBehind) : null,
      totalAvgMinutesBehind:
        combinedData.partitionData.length > 0 && false === isNaN(minutesBehind)
          ? +(minutesBehind / combinedData.partitionData.length).toFixed(2)
          : null,
      lastUpdateTimestamp: new Date(),
    };

    previousData.current = data;
    return combinedData;
  };

  //create the query key from the supplied logQueryProperties
  const queryKey = keys.eventHubStatusQuery(eventHubStatusQueryProperties);

  return useQuery<ConsolidatedEventHubStatusData, Error>(
    queryKey,
    async ({ signal }) => {
      const { data } = await ax.get<ConsolidatedEventHubStatusData>(
        `eventhubmonitor/getstatusdata3/${eventHubStatusQueryProperties.configurationKey}`,
        { signal }
      );
      return createDeltasAndSummary(data);
    },
    {
      enabled: enabled,
      refetchInterval: 30 * 1000,
      refetchIntervalInBackground: true,
      //select: (data) => createDeltasAndSummary(data),
    }
  );
}
