import { DataTypeEnum } from "./DataTypeEnum";

export type FirstLastTagValueData = {
  obj_name: string;
  obj_id: number;
  obj_guid: string;
  obj_type: string;
  tag_name: string;
  tag_id: number;
  tag_guid: string;
  tat_name: string;
  tat_id: number;
  tat_datatype: DataTypeEnum;
  fkv_data_value: string;
  fkv_timestamp: Date | null;
  lkv_data_value: string;
  lkv_timestamp: Date | null;
  obj_enabled: boolean | null;
  obj_visible: boolean | null;
  tag_enabled: boolean;
  tag_visible: boolean;
};

export type CalcFKVsRequest = {
  customerId: number;
  replaceExisting: boolean;
};

export type AffectedValuesRequest = {
  customerId: number;
  tagIDs: Array<number>;
  startTime: Date;
  endTime: Date;
};

export type AffectedValue = {
  timestamp: Date;
  value: string;
  contractDay: Date;
};

export type AffectedValueData = {
  tagID: number;
  valueCount: number;
  firstValues: Array<AffectedValue>;
  lastValues: Array<AffectedValue>;
};

export enum TagValueOp {
  ReCalculateFormula = 0,
  ResetTagValues = 1,
  ReCalculateContractDay = 2,
}

export type TagValueChangeRequest = {
  tagValueOp: TagValueOp;
  customerID: number;
  deviceIDs: Array<number>;
  tagIDs: Array<number>;
  startTime: Date;
  endTime: Date;
  newTagValue: number;
  newTextValue: string | null;
};
