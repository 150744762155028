import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Link } from "@mui/material";

export type FIleDownloaderProps = Record<string, unknown>;

export type FileDownloaderType = {
  download: (url: string, fileName?: string) => void;
};

const FileDownloader = forwardRef((_props: FIleDownloaderProps, ref) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useImperativeHandle<unknown, FileDownloaderType>(ref, () => ({
    download: (url: string, fileName?: string) => {
      if (linkRef.current) {
        linkRef.current.download = fileName ?? "true";
        linkRef.current.href = url;
      }

      linkRef.current?.click();
    },
  }));

  return (
    <Link
      sx={{ display: "none" }}
      ref={linkRef}
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
});

FileDownloader.displayName = "FileDownloader";
export default FileDownloader;
