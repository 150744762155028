import React, { useEffect, useRef, useState } from "react";
import { OutlinedInput, InputAdornment, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../hooks/use-debounce/UseDebounce";

export type ListFilterProps = {
  onFilterValueChange: (value: string) => void;
  debounceDelay: number;
  placeholder?: string;
  width?: string;
};

export default function ListFilter({ onFilterValueChange, debounceDelay, placeholder, width }: ListFilterProps) {
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, debounceDelay);

  const filterChangeRef = useRef(onFilterValueChange);

  useEffect(() => {
    filterChangeRef.current && filterChangeRef.current(debouncedFilter);
  }, [debouncedFilter]);

  return (
    <OutlinedInput
      size="small"
      placeholder={placeholder}
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Filter list">
            <SearchIcon />
          </Tooltip>
        </InputAdornment>
      }
      onChange={(e) => setFilter(e.target.value)}
      sx={{ width: width }}
    />
  );
}
