import { EventHubProcessingMonitorQueryProperties, MetricsQueryProperties } from "./Models";

export const keys = {
  all: ["metrics"],
  eventHubProcessingMonitorMetrics: () => [...keys.all, "eventHubProcessingMonitor"],
  eventHubProcessingMonitorMetric: (queryProperties: EventHubProcessingMonitorQueryProperties) => [
    ...keys.eventHubProcessingMonitorMetrics(),
    queryProperties,
  ],
  query: (queryProperties: MetricsQueryProperties) => [...keys.all, queryProperties],
  metricTypes: ["metrics", "metricTypes"],
  primaryKeys: (metricType: string) => ["metrics", metricType],
};
