import React, { PropsWithChildren } from "react";
import { Paper, useTheme } from "@mui/material";
import SideSheetContainer from "./SideSheetContainer";

export type SideSheetProps = {
  open: boolean;
  title: string;
  onClose?: () => void;
};

export default function SideSheet({
  open,
  title,
  onClose,
  children,
}: PropsWithChildren<SideSheetProps>) {
  const theme = useTheme();

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          width: open ? theme.spacing(40) : 0,
          minWidth: open ? theme.spacing(40) : 0,
          maxWidth: open ? theme.spacing(40) : 0,
          transition: theme.transitions.create(
            ["width", "min-width", "max-width"],
            {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }
          ),
          display: "flex",
          overflow: "hidden",
        }}
      >
        <SideSheetContainer
          title={title}
          onClose={onClose}
          showCloseButton={!!onClose}
        >
          {children}
        </SideSheetContainer>
      </Paper>
    </>
  );
}
