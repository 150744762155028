import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { eLynxUserModel } from "./Models";

//custom query hook
export function useElynxUser() {
  const { axios: ax } = useAxios();

  return useMutation<eLynxUserModel, Error, number>(async (userID) => {
    const { data } = await ax.get<eLynxUserModel>(`elynxusers/${userID}`);
    return data;
  });
}
