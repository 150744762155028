import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { AppBar, Toolbar, Typography, useTheme, styled } from "@mui/material";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0),
}));

export type ContentHeaderProps = {
  title: string;
};

export default function ContentHeader({ title }: ContentHeaderProps) {
  const isPhone = isMobile && !isTablet;
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      color={isPhone ? "primary" : "default"}
      sx={{ paddingBottom: theme.spacing(1), minHeight: 32 }}
    >
      <StyledToolbar variant="dense">
        {!isPhone && (
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              alignSelf: "center",
            }}
          >
            {title}
          </Typography>
        )}

        {isPhone && (
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: theme.spacing(7),
            }}
          >
            {title}
          </Typography>
        )}
      </StyledToolbar>
    </AppBar>
  );
}
