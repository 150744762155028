import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { CallUrlRequest } from "./CallUrlRequest";

export function useCallUrl() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, CallUrlRequest>(async (request) => {
    await ax.post(request.url, request.data);
  });
}
