import { SupportCenterAuditTrailQueryProperties } from "../../queries/support-center-audit-trail/Models";

//these are the strings used by the reducer
export enum SupportCenterAuditTrailActionTypeEnum {
  SetMaxCount = "set-max-count",
  SetTimeframe = "set-timeframe",
  SetStartTime = "set-start-time",
  SetEndTime = "set-end-time",
  SetCustomerID = "set-customer-id",
  SetOrganizationID = "set-organization_id",
  SetUsername = "set-user-name",
  SetMachineName = "set-machine-name",
  SetAction = "set-action",
  SetPageName = "set-page-name",
  SetObjectType = "set-object-type",
  SetObjectID = "set-object-id",
  SetOldValue = "set-old-value",
  SetNewValue = "set-new-value",
  SetDescription = "set-description",
}

//define a type per action to enforce typing very strictly
export type SetMaxCountAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetMaxCount;
  maxCount: number;
};

export type SetTimeframeAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetTimeframe;
  timeframe: number;
};

export type SetStartTimeAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetStartTime;
  startTime: Date;
};

export type SetEndTimeAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetEndTime;
  endTime: Date;
};

export type SetCustomerIDAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetCustomerID;
  customerID: number;
};

export type SetOrganizationIDAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetOrganizationID;
  organizationID: number;
};

export type SetUserNameAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetUsername;
  username: string;
};

export type SetMachineNameAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetMachineName;
  machineName: string;
};

export type SetActionAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetAction;
  action: string;
};

export type SetPageNameAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetPageName;
  pageName: string;
};

export type SetObjectTypeAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetObjectType;
  objectType: number;
};

export type SetObjectIDAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetObjectID;
  objectID: number;
};

export type SetOldValueAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetOldValue;
  oldValue: string;
};

export type SetNewValueAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetNewValue;
  newValue: string;
};

export type SetDescriptionAction = {
  type: SupportCenterAuditTrailActionTypeEnum.SetDescription;
  description: string;
};

//an action sent to the reducer can be any of the above individual actions
export type SupportCenterAuditTrailActions =
  | SetMaxCountAction
  | SetTimeframeAction
  | SetStartTimeAction
  | SetEndTimeAction
  | SetCustomerIDAction
  | SetOrganizationIDAction
  | SetUserNameAction
  | SetMachineNameAction
  | SetActionAction
  | SetPageNameAction
  | SetObjectTypeAction
  | SetObjectIDAction
  | SetOldValueAction
  | SetNewValueAction
  | SetDescriptionAction;

const undefinedOnBlank = (val: string) => {
  return val?.length > 0 ? val : undefined;
};

const undefinedOnZero = (val: number) => {
  return val !== 0 ? val : undefined;
};

//and now the reducer itself
export const supportCenterAuditTrailActionReducer = (
  state: SupportCenterAuditTrailQueryProperties,
  action: SupportCenterAuditTrailActions
): SupportCenterAuditTrailQueryProperties => {
  switch (action.type) {
    case SupportCenterAuditTrailActionTypeEnum.SetMaxCount:
      return {
        ...state,
        scat_max_count: action.maxCount,
      };
    case SupportCenterAuditTrailActionTypeEnum.SetTimeframe:
      return {
        ...state,
        timeframe: action.timeframe,
      };
    case SupportCenterAuditTrailActionTypeEnum.SetStartTime:
      return {
        ...state,
        scat_start_timestamp: action.startTime,
      };
    case SupportCenterAuditTrailActionTypeEnum.SetEndTime:
      return {
        ...state,
        scat_end_timestamp: action.endTime,
      };
    case SupportCenterAuditTrailActionTypeEnum.SetCustomerID:
      return {
        ...state,
        scat_customer_id: undefinedOnZero(action.customerID),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetOrganizationID:
      return {
        ...state,
        scat_organization_id: undefinedOnZero(action.organizationID),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetUsername:
      return {
        ...state,
        scat_username: undefinedOnBlank(action.username),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetMachineName:
      return {
        ...state,
        scat_machine_name: undefinedOnBlank(action.machineName),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetAction:
      return {
        ...state,
        scat_action: undefinedOnBlank(action.action),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetPageName:
      return {
        ...state,
        scat_page_name: undefinedOnBlank(action.pageName),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetObjectType:
      return {
        ...state,
        scat_object_type_id: undefinedOnZero(action.objectType),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetObjectID:
      return {
        ...state,
        scat_object_id: undefinedOnZero(action.objectID),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetOldValue:
      return {
        ...state,
        scat_old_value: undefinedOnBlank(action.oldValue),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetNewValue:
      return {
        ...state,
        scat_new_value: undefinedOnBlank(action.newValue),
      };
    case SupportCenterAuditTrailActionTypeEnum.SetDescription:
      return {
        ...state,
        scat_description: undefinedOnBlank(action.description),
      };

    default:
      return state;
  }
};
