import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { TagValueChangeRequest } from "./Models";

export function useRecalcContractDays() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, TagValueChangeRequest>(async (request) => {
    await ax.post("tagvalueaction/recalccontractday", request);
  });
}
