import React, { useEffect, useState } from "react";
import { UomActionEnum, UomInfoItem } from "../../../queries/copy-device/Models";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";

export type SelectUomProperties = {
  uomInfo: UomInfoItem;
  uomList: Array<AutocompleteOption>;
  open: boolean;
  onClose: () => void;
  selectUom: (uomInfo: UomInfoItem) => void;
};

export default function SelectUom({ uomInfo, uomList, open, onClose, selectUom }: SelectUomProperties) {
  const [selectedUom, setSelectedUom] = useState<AutocompleteOption>({ id: -1, label: "" });

  //if we have just been opened, reset our selected UoM
  useEffect(() => {
    setSelectedUom(
      null != uomInfo.dst_uom_name && null != uomInfo.dst_uom_id
        ? { id: uomInfo.dst_uom_id, label: uomInfo.dst_uom_name }
        : { id: -1, label: "" }
    );
  }, [open]);

  const onClick = () => {
    //create a new UomInfoItem with the selected ID
    //and send it to the passed in function and close the dialog
    const newUomInfo = {
      ...uomInfo,
      dst_uom_id: selectedUom.id > 0 ? selectedUom.id : null,
      dst_uom_name: selectedUom.label,
      uom_action: selectedUom.id > -1 ? UomActionEnum.Copy : UomActionEnum.DoNotCopy,
    } as UomInfoItem;
    selectUom(newUomInfo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Select Destination UoM</DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          autoHighlight
          size="small"
          options={uomList}
          getOptionLabel={(option) => (option.id > 0 ? `${option.label} [${option.id}]` : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedUom}
          onChange={(event, value) => {
            if (value) {
              setSelectedUom(value);
            } else {
              setSelectedUom({ id: -1, label: "" });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Destination UoM" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
