import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";

export default function ContentView({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          overflow: "hidden",
          height: "100%",
          position: "relative",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
