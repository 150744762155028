import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  useTheme,
} from "@mui/material";
import { ILogItem } from "../../queries/log-data/Models";

export type LogItemDialogProps = {
  logItem: ILogItem;
  dialogOpen: boolean;
  onClose: () => void;
};

export default function LogItemDialog({ logItem, dialogOpen, onClose }: LogItemDialogProps) {
  const theme = useTheme();

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="xl"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Log Item Detail</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText variant="h6" sx={{ color: `${theme.palette.neutral.contrastText}` }}>
          Message Text:
        </DialogContentText>
        <DialogContentText id="scroll-dialog-message" tabIndex={-1} sx={{ whiteSpace: "pre-wrap" }}>
          {logItem.message}
        </DialogContentText>
        {logItem.exception && (
          <>
            <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
            <DialogContentText variant="h6" sx={{ color: `${theme.palette.neutral.contrastText}` }}>
              Exception:
            </DialogContentText>
            <DialogContentText id="scroll-dialog-exception" tabIndex={-1} sx={{ whiteSpace: "pre-wrap" }}>
              {logItem.exception}
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
