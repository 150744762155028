import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { MetricRow, MetricsQueryProperties } from "./Models";
import { addHours } from "date-fns";

export type UseQueryMetricsParameters = {
  enabled: boolean;
  metricsQueryProperties: MetricsQueryProperties;
  refetchSeconds: number;
};

export function UseQueryMetrics({ enabled, metricsQueryProperties, refetchSeconds }: UseQueryMetricsParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = keys.query(metricsQueryProperties);

  return useQuery<Array<MetricRow>, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done
      let queryProperties = {
        ...metricsQueryProperties,
      } as MetricsQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (metricsQueryProperties && metricsQueryProperties.timeframe && metricsQueryProperties.timeframe > 0) {
        const endTime = new Date();
        const startTime = addHours(endTime, 0 - metricsQueryProperties.timeframe);
        queryProperties = {
          ...queryProperties,
          startTime: startTime,
          endTime: endTime,
        };
      }

      //remove any blank filter items
      queryProperties.filterItems = queryProperties.filterItems.filter(
        (f) => f.field.length > 0 && f.type.length > 0 && f.operator.length > 0 && f.value.length > 0
      );

      //now make the actual data call
      const { data } = await ax.post<Array<MetricRow>>("metrics/query", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: refetchSeconds > 0 ? refetchSeconds * 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
}
