import {
  ReportSchedulingDeliveryOverrideTypeEnum,
  ReportTestConfiguration,
} from "../../queries/report-scheduler/Models";
import { getRunScheduledReportsRequest } from "../../queries/report-scheduler/RunScheduledReportsRequest";
import { ParseTimeSpanString } from "../../utilities/ParseTimeSpanString";
import { UIRunScheduledReportRequestTuple } from "./TestReporting";

//these are the strings used by the reducer
export enum TestReportingActionTypeEnum {
  SetTestingConfig = "set-test-config",
  SetCustomer = "set-customer",
  SetAutoResend = "set-auto-resend",
  SetTestMode = "set-test-mode",
  SetDeleteReport = "set-delete-report",
  SetDisableUpdateDB = "set-disable-update-db",
  SetRunSelectedTasks = "set-run-selected-tasks",
  SetUseSingleSchedule = "set-use-single-schedule",
  SetIncludeUnscheduledTasks = "set-include-unscheduled-tasks",
  SetRetrieveTasksWithInactiveSchedules = "set-retrieve-tasks-inactive-schedules",
  SetOverrideFtpFlag = "set-override-ftp-flag",
  SetFtpOverride = "set-ftp-override",
  SetOverrideDatesFlag = "set-override-dates-flag",
  SetOverrideEmailFlag = "set-override-email-flag",
  SetDeliveryOverride = "set-delivery-override",
  SetTaskIDs = "set-task-ids",
  SetStartDate = "set-start-date",
  SetEndDate = "set-end-date",
  SetEmailSubject = "set-email-subject",
  SetEmailBody = "set-email-body",
  SetEmailAddresses = "set-email-addresses",
  SetRunEnabled = "set-run-enabled",
}

//define a type per action to enforce typing very strictly
export type SetTestConfig = {
  type: TestReportingActionTypeEnum.SetTestingConfig;
  testConfig: ReportTestConfiguration | undefined;
};

export type SetCustomer = {
  type: TestReportingActionTypeEnum.SetCustomer;
  customerID: number;
};

export type SetAutoResend = {
  type: TestReportingActionTypeEnum.SetAutoResend;
  minutes: number;
};

export type SetTestMode = {
  type: TestReportingActionTypeEnum.SetTestMode;
  flag: boolean;
};

export type SetDeleteReport = {
  type: TestReportingActionTypeEnum.SetDeleteReport;
  flag: boolean;
};

export type SetDisableUpdateDB = {
  type: TestReportingActionTypeEnum.SetDisableUpdateDB;
  flag: boolean;
};

export type SetRunSelectedTasks = {
  type: TestReportingActionTypeEnum.SetRunSelectedTasks;
  flag: boolean;
};

export type SetUseSingleSchedule = {
  type: TestReportingActionTypeEnum.SetUseSingleSchedule;
  flag: boolean;
};

export type SetIncludeUnscheduledTasks = {
  type: TestReportingActionTypeEnum.SetIncludeUnscheduledTasks;
  flag: boolean;
};

export type SetRetrieveTasksWithInactiveSchedules = {
  type: TestReportingActionTypeEnum.SetRetrieveTasksWithInactiveSchedules;
  flag: boolean;
};

export type SetOverrideFtpFlag = {
  type: TestReportingActionTypeEnum.SetOverrideFtpFlag;
  flag: boolean;
};

export type SetFtpOverride = {
  type: TestReportingActionTypeEnum.SetFtpOverride;
  ftpAddress: string;
};

export type SetOverrideDatesFlag = {
  type: TestReportingActionTypeEnum.SetOverrideDatesFlag;
  flag: boolean;
};

export type SetOverrideEmailFlag = {
  type: TestReportingActionTypeEnum.SetOverrideEmailFlag;
  flag: boolean;
};

export type SetDeliveryOverride = {
  type: TestReportingActionTypeEnum.SetDeliveryOverride;
  deliveryType: ReportSchedulingDeliveryOverrideTypeEnum;
};

export type SetTaskIDs = {
  type: TestReportingActionTypeEnum.SetTaskIDs;
  taskIDs: Array<number>;
};

export type SetStartDate = {
  type: TestReportingActionTypeEnum.SetStartDate;
  startDate: Date;
};

export type SetEndDate = {
  type: TestReportingActionTypeEnum.SetEndDate;
  endDate: Date;
};

export type SetEmailSubject = {
  type: TestReportingActionTypeEnum.SetEmailSubject;
  subject: string;
};

export type SetEmailBody = {
  type: TestReportingActionTypeEnum.SetEmailBody;
  body: string;
};

export type SetEmailAddresses = {
  type: TestReportingActionTypeEnum.SetEmailAddresses;
  addresses: Array<string>;
};

export type SetRunEnabled = {
  type: TestReportingActionTypeEnum.SetRunEnabled;
  flag: boolean;
};

//an action sent to the reducer can be any of the above individual actions
export type TestReportingActions =
  | SetTestConfig
  | SetCustomer
  | SetAutoResend
  | SetTestMode
  | SetDeleteReport
  | SetDisableUpdateDB
  | SetRunSelectedTasks
  | SetUseSingleSchedule
  | SetIncludeUnscheduledTasks
  | SetRetrieveTasksWithInactiveSchedules
  | SetOverrideFtpFlag
  | SetFtpOverride
  | SetOverrideDatesFlag
  | SetOverrideEmailFlag
  | SetDeliveryOverride
  | SetTaskIDs
  | SetStartDate
  | SetEndDate
  | SetEmailSubject
  | SetEmailBody
  | SetEmailAddresses
  | SetRunEnabled;

//and now the reducer itself
export const testReportingActionReducer = (
  state: UIRunScheduledReportRequestTuple,
  action: TestReportingActions
): UIRunScheduledReportRequestTuple => {
  //console.log(`Reducer received message ${action.type}`);
  const newState = createNewState(state, action);
  return newState;
};

const createNewState = (
  state: UIRunScheduledReportRequestTuple,
  action: TestReportingActions
): UIRunScheduledReportRequestTuple => {
  const newState = { ...state };

  //always set run report state to disabled if anything has changed
  newState.enabled = false;

  switch (action.type) {
    case TestReportingActionTypeEnum.SetTestingConfig:
      if (action.testConfig) {
        newState.selectedConfigName = action.testConfig.name;
        newState.request = getRunScheduledReportsRequest(action.testConfig);
        newState.selectSpecificTasks = (action.testConfig.selectedTaskIDs?.length ?? 0) > 0;
        if (
          (action.testConfig.shouldAutoResendMessage ?? false) &&
          action.testConfig.autoResendInterval &&
          action.testConfig.autoResendInterval.length >= 5
        ) {
          const d = ParseTimeSpanString(action.testConfig.autoResendInterval);
          if (d) {
            const minutes = (d.hours ?? 0) * 60 + (d.minutes ?? 0);
            newState.autoRefreshMinutes = minutes;
          }
        } else {
          newState.autoRefreshMinutes = 0;
        }
      }
      return newState;
    case TestReportingActionTypeEnum.SetCustomer:
      newState.request.customerID = action.customerID;
      return newState;
    case TestReportingActionTypeEnum.SetAutoResend:
      newState.autoRefreshMinutes = action.minutes;
      newState.enabled = action.minutes > 0;
      return newState;
    case TestReportingActionTypeEnum.SetTestMode:
      newState.request.isTestMessage = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetDeleteReport:
      newState.request.deleteReportAfterDelivery = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetDisableUpdateDB:
      newState.request.disableDBUpdate = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetRunSelectedTasks:
      newState.selectSpecificTasks = action.flag;
      newState.request.taskIDs = [];
      return newState;
    case TestReportingActionTypeEnum.SetUseSingleSchedule:
      newState.request.useSingleSchedule = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetIncludeUnscheduledTasks:
      newState.request.includeUnscheduledTasks = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetRetrieveTasksWithInactiveSchedules:
      newState.retrieveTasksWithInactiveSchedules = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetOverrideFtpFlag:
      newState.overrideFtp = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetFtpOverride:
      newState.request.ftpOverride = action.ftpAddress;
      return newState;
    case TestReportingActionTypeEnum.SetOverrideDatesFlag:
      newState.request.overrideReportDates = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetOverrideEmailFlag:
      newState.request.overrideEmail = action.flag;
      return newState;
    case TestReportingActionTypeEnum.SetDeliveryOverride:
      newState.request.deliveryOverrideType = action.deliveryType;
      return newState;
    case TestReportingActionTypeEnum.SetTaskIDs:
      newState.request.taskIDs = action.taskIDs;
      return newState;
    case TestReportingActionTypeEnum.SetStartDate:
      newState.request.startDateOverride = action.startDate;
      return newState;
    case TestReportingActionTypeEnum.SetEndDate:
      newState.request.endDateOverride = action.endDate;
      return newState;
    case TestReportingActionTypeEnum.SetEmailSubject:
      newState.request.emailSubject = action.subject;
      return newState;
    case TestReportingActionTypeEnum.SetEmailBody:
      newState.request.emailBody = action.body;
      return newState;
    case TestReportingActionTypeEnum.SetEmailAddresses:
      newState.request.emailOverrides = action.addresses;
      return newState;
    case TestReportingActionTypeEnum.SetRunEnabled:
      newState.enabled = action.flag;
      return newState;
    default:
      return state;
  }
};
