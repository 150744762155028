import { addDays } from "date-fns";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { SystemUsageQueryProperties, SystemUsageData } from "./Models";

export type UseSystemUsageDataParameters = {
  enabled: boolean;
  systemUsageQueryProperties: SystemUsageQueryProperties;
};

export function useSystemUsageData({ enabled, systemUsageQueryProperties }: UseSystemUsageDataParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = keys.queryKey(systemUsageQueryProperties);

  return useQuery<Array<SystemUsageData>, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done
      let queryProperties = { ...systemUsageQueryProperties } as SystemUsageQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (systemUsageQueryProperties?.timeframe && systemUsageQueryProperties.timeframe > 0) {
        const endDate = new Date();
        const startDate = addDays(endDate, 0 - systemUsageQueryProperties.timeframe);
        queryProperties = {
          ...systemUsageQueryProperties,
          startDate: startDate,
          endDate: endDate,
        };
      }

      //now make the actual data call
      const { data } = await ax.post<Array<SystemUsageData>>("systemusage/query", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
