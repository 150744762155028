import React from "react";
import { Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";

export type ActionButtonRendererProps = {
  disabled: boolean | ((data: unknown) => boolean);
  buttonText: string | ((data: unknown) => string);
  shouldDisplay: boolean | ((data: unknown) => boolean);
  onClick: (data: unknown) => void;
} & ICellRendererParams;

export default function ActionButtonCellRenderer({
  disabled,
  buttonText,
  node,
  shouldDisplay,
  onClick,
}: ActionButtonRendererProps) {
  return (
    <>
      {true === (typeof shouldDisplay === "function" ? shouldDisplay(node.data) : shouldDisplay) && (
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => onClick(node.data)}
          disabled={true === (typeof disabled === "function" ? disabled(node.data) : disabled)}
        >
          {(typeof buttonText === "function" ? buttonText(node.data) : buttonText)}
        </Button>
      )}
    </>
  );
}
