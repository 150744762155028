import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { MetricItem } from "./Models";

//custom query hook
export function useMetricTypes() {
  const { axios: ax } = useAxios();

  return useQuery<Array<MetricItem>, Error>(
    keys.metricTypes,
    async () => {
      const { data } = await ax.get<Array<MetricItem>>("metrics/getmetrictypes");
      return data;
    },
    {
      staleTime: 15 * 60 * 1000, //we will use data that is stale because the metric types don't change much at all
      refetchInterval: 60 * 60 * 1000, //but let's auto-refresh the data
    }
  );
}
