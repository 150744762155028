export const keys = {
  all: ["test-tag-data"],
  devices: (customerID: number) => [...keys.all, "devices", customerID.toString()],
  tags: (customerID: number, deviceID: number) => [...keys.all, "tags", customerID.toString(), deviceID.toString()],
  tagValue: (customerID: number, tagID: number) => [...keys.all, "tagvalue", customerID.toString(), tagID.toString()],
  setpoints: (customerID: number, tagID: number) => [...keys.all, "setpoints", customerID.toString(), tagID.toString()],
  setpoint: (customerID: number, setpointID: number) => [
    ...keys.all,
    "setpoint",
    customerID.toString(),
    setpointID.toString(),
  ],
};
