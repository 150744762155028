import { PublishTagValueRequest } from "../../queries/tag-data-testing/PublishTagValueRequest";

//these are the strings used by the reducer
export enum TestTagDataActionTypeEnum {
  SetCustomer = "set-customer",
  SetDevice = "set-device",
  SetTag = "set-tag",
  SetSetpoint = "set-setpoint",
  SetNewValue = "set-new-value",
  SetNewValueTimestamp = "set-new-value-timestamp",
  SetSetpointType = "set-setpoint-type",
  SetBypassFormula = "set-bypass-formula",
  SetUseTimestampsUnaltered = "set-use-timestamps-unaltered",
}

//define a type per action to enforce typing very strictly
export type SetCustomer = {
  type: TestTagDataActionTypeEnum.SetCustomer;
  customerID: number;
};

export type SetDevice = {
  type: TestTagDataActionTypeEnum.SetDevice;
  deviceID: number;
};

export type SetTag = {
  type: TestTagDataActionTypeEnum.SetTag;
  tagID: number;
};

export type SetSetpoint = {
  type: TestTagDataActionTypeEnum.SetSetpoint;
  setpointID: number;
};

export type SetNewValue = {
  type: TestTagDataActionTypeEnum.SetNewValue;
  newValue: string;
};

export type SetNewValueTimestamp = {
  type: TestTagDataActionTypeEnum.SetNewValueTimestamp;
  newValueTimestamp: Date;
};

export type SetSetpointType = {
  type: TestTagDataActionTypeEnum.SetSetpointType;
  setpointType: number;
};

export type SetBypassFormula = {
  type: TestTagDataActionTypeEnum.SetBypassFormula;
  bypassFormula: boolean;
};

export type SetUseTimestampsUnaltered = {
  type: TestTagDataActionTypeEnum.SetUseTimestampsUnaltered;
  useTimestampsUnaltered: boolean;
};

//an action sent to the reducer can be any of the above individual actions
export type TestTagDataActions =
  | SetCustomer
  | SetDevice
  | SetTag
  | SetSetpoint
  | SetNewValue
  | SetNewValueTimestamp
  | SetSetpointType
  | SetBypassFormula
  | SetUseTimestampsUnaltered;

//and now the reducer itself
export const testTagDataActionReducer = (
  state: PublishTagValueRequest,
  action: TestTagDataActions
): PublishTagValueRequest => {
  const newState = createNewState(state, action);
  return newState;
};

const createNewState = (state: PublishTagValueRequest, action: TestTagDataActions): PublishTagValueRequest => {
  switch (action.type) {
    //if the customer has changed, reset the device selection properties
    case TestTagDataActionTypeEnum.SetCustomer:
      return {
        ...state,
        customerID: action.customerID,
        parentID: null,
        tagID: null,
        setpointID: null,
        dataValue: "",
        timestamp: null,
        contractDay: null,
        setPointEventType: null,
      };
    case TestTagDataActionTypeEnum.SetDevice:
      return {
        ...state,
        parentID: action.deviceID,
        tagID: null,
        setpointID: null,
        dataValue: "",
        timestamp: null,
        contractDay: null,
        setPointEventType: null,
      };
    case TestTagDataActionTypeEnum.SetTag:
      return {
        ...state,
        tagID: action.tagID,
        setpointID: null,
        dataValue: "",
        timestamp: null,
        contractDay: null,
        setPointEventType: null,
      };

    case TestTagDataActionTypeEnum.SetSetpoint:
      return {
        ...state,
        setpointID: action.setpointID,
      };

    case TestTagDataActionTypeEnum.SetNewValue:
      return {
        ...state,
        dataValue: action.newValue,
      };

    case TestTagDataActionTypeEnum.SetNewValueTimestamp:
      return {
        ...state,
        timestamp: action.newValueTimestamp,
      };

    case TestTagDataActionTypeEnum.SetSetpointType:
      return {
        ...state,
        setPointEventType: action.setpointType,
      };

    case TestTagDataActionTypeEnum.SetBypassFormula:
      return {
        ...state,
        bypassFormula: action.bypassFormula,
      };

    case TestTagDataActionTypeEnum.SetUseTimestampsUnaltered:
      return {
        ...state,
        useTimestampsUnaltered: action.useTimestampsUnaltered,
      };

    default:
      return state;
  }
};
