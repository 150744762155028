import { parse, format, addDays } from "date-fns";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { NonPolledDataItem, NonPolledDataQueryProperties } from "./Models";

export type UseNonPolledDataFileListParameters = {
  enabled: boolean;
  fileListQueryProperties: NonPolledDataQueryProperties;
  autoRefreshSeconds: number;
};

export function useNonPolledDataFileList({
  enabled,
  fileListQueryProperties,
  autoRefreshSeconds,
}: UseNonPolledDataFileListParameters) {
  const { axios: ax } = useAxios();

  return useQuery<Array<NonPolledDataItem>, Error>(
    keys.dataFileQueryKey(fileListQueryProperties),
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done
      const queryProperties = { ...fileListQueryProperties } as NonPolledDataQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (fileListQueryProperties && fileListQueryProperties.timeframe > -1) {
        let endDate = parse(format(new Date(), "P"), "P", new Date());
        if (fileListQueryProperties.timeframe != 0) {
          endDate = addDays(endDate, 1);
        }
        const startDate =
          fileListQueryProperties.timeframe != 0
            ? addDays(endDate, 0 - fileListQueryProperties.timeframe)
            : addDays(endDate, -1);

        queryProperties.startDate = startDate;
        queryProperties.endDate = endDate;
      }

      //if the user selected the pseudo timeframe "most recent", set the flag for that option
      if (fileListQueryProperties && fileListQueryProperties.timeframe === -2) {
        queryProperties.getMostRecentFile = true;
      }

      const { data } = await ax.get<Array<NonPolledDataItem>>("nonpolleddataops/files", {
        signal: signal,
        params: queryProperties,
      });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
}
