import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../../contexts/app-context/AppContext";
import { Box } from "@mui/system";
import { ConfigureJobStepProps } from "./ConfigureCopyJob";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { LookupListActionEnum, LookupListInfoItem } from "../../../queries/copy-device/Models";
import { useDeviceCopyLookupListInfo } from "../../../queries/copy-device/UseDeviceCopyLookupListInfo";
import { faFileImport } from "@elynx/pro-regular-svg-icons";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import { CopyDeviceJobActionTypeEnum } from "../CopyDeviceJobActions";
import SideSheet from "../../../components/side-sheet/side-sheet/SideSheet";
import CopyOptions from "./CopyOptions";
import { FormControlLabel, Typography } from "@mui/material";
import FontAwesomeIconButton from "../../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import SelectLookupList from "./SelectLookupList";

export default function MapLookupListsStep({
  dialogOpen,
  job,
  dispatchJob,
  setNextButtonDisabled,
}: ConfigureJobStepProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [destinationLookupLists, setDestinationLookupLists] = useState<Array<AutocompleteOption>>([]);
  const [sourceLookupLists, setSourceLookupLists] = useState<Array<LookupListInfoItem>>([]);
  const currentLookupListInfoRef = useRef<LookupListInfoItem | null>(null);
  const [selectLookupListOpen, setSelectLookupListOpen] = useState(false);
  const { data: newLookupListInfo, isSuccess } = useDeviceCopyLookupListInfo({
    enabled: dialogOpen && (job?.objects?.length ?? 0) > 0,
    jobID: job?.jobID ?? 0,
  });

  //if we have just been opened, clear our data
  useEffect(() => {
    setDestinationLookupLists([]);
    setSourceLookupLists([]);
  }, [dialogOpen]);

  //store source and destination UoMs if they change
  useEffect(() => {
    if (newLookupListInfo?.lst_info_list) {
      setSourceLookupLists(newLookupListInfo.lst_info_list);
    }
  }, [dialogOpen, isSuccess, newLookupListInfo?.lst_info_list]);

  useEffect(() => {
    if (newLookupListInfo?.dst_lst_list) {
      const noLookupList = { id: -1, label: "" } as AutocompleteOption;
      const lookupListList = newLookupListInfo.dst_lst_list.map((t) => {
        return { id: t.lst_id, label: t.lst_name } as AutocompleteOption;
      });
      setDestinationLookupLists([noLookupList, ...lookupListList]);
    }
  }, [dialogOpen, isSuccess, newLookupListInfo?.dst_lst_list]);

  useEffect(() => {
    //if this is our first visit to this step,
    //i.e. dialog is open, job.lookupListMappings is empty)
    //and we have sourceLookupLists,
    //setup the initial mapping in the job
    if (dialogOpen && sourceLookupLists.length > 0 && (job?.lookupListMappings?.length ?? 0) === 0) {
      //then create a new mapping to be saved into the job
      const newLookupListMapping = sourceLookupLists.map((t) => {
        return {
          srcListID: t.src_lst_id,
          dstListID: t.dst_lst_id,
          listAction: t.lst_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetLookupListMap,
        lstMap: newLookupListMapping,
      });
    }
  }, [dialogOpen, sourceLookupLists]);

  //enable the Next button if we have mapped all lookup lists
  useEffect(() => {
    setNextButtonDisabled(
      (job?.lookupListMappings?.length ?? 0) == 0 ||
        job.lookupListMappings.some((u) => LookupListActionEnum.DoNotCopy === u.listAction)
    );
  }, [job?.lookupListMappings]);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onCheckChanged = useCallback(
    (data: LookupListInfoItem, checked: boolean) => {
      //first create a new set of source Lookup Lists so our grid can be updated
      const newSourceLookupLists = sourceLookupLists.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_lst_id === data.src_lst_id) {
          return {
            ...t,
            lst_action: checked
              ? null == t.dst_lst_id
                ? LookupListActionEnum.CreateAndCopy
                : LookupListActionEnum.Copy
              : LookupListActionEnum.DoNotCopy,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceLookupLists(newSourceLookupLists);

      //then create a new mapping to be saved into the job
      const newLookupListMapping = newSourceLookupLists.map((t) => {
        return {
          srcListID: t.src_lst_id,
          dstListID: t.dst_lst_id,
          listAction: t.lst_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetLookupListMap,
        lstMap: newLookupListMapping,
      });
    },
    [sourceLookupLists]
  );

  const onSelectLookupListClick = useCallback((lookupListInfo: LookupListInfoItem) => {
    currentLookupListInfoRef.current = lookupListInfo;
    setSelectLookupListOpen(true);
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Source ID",
        field: "src_lst_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Source Lookup List",
        field: "src_lst_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        sort: "asc",
        sortIndex: 1,
      },
      {
        headerName: "Destination Lookup List",
        field: "dst_lst_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        valueFormatter: ({ data }: ValueFormatterParams<LookupListInfoItem, string>) => {
          return null !== data && null !== data?.dst_lst_id ? `${data?.dst_lst_name} [${data?.dst_lst_id}]` : "";
        },
      },
      {
        colId: "selectLookupList",
        headerName: "Select Lookup List",
        minWidth: 170,
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: false,
          buttonText: "Select Lookup List",
          shouldDisplay: true,
          onClick: onSelectLookupListClick,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Copy LookupList?",
        field: "lst_action",
        minWidth: 200,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: {
          disabled: false,
          onChecked: onCheckChanged,
          label: (data: LookupListInfoItem) => {
            return data
              ? LookupListActionEnum.Copy === data.lst_action
                ? "Copy"
                : LookupListActionEnum.CreateAndCopy === data.lst_action
                ? "Create and Copy"
                : ""
              : "";
          },
        } as CheckboxRendererProps,
      },
    ] as Array<ColDef>;

    return columns;
  }, [onCheckChanged]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const createMissingLookupLists = useCallback(() => {
    //first create a new set of source UoMs so our grid can be updated
    const newSourceLookupLists = sourceLookupLists.map((t) => {
      const newAction = null == t.dst_lst_id ? LookupListActionEnum.CreateAndCopy : t.lst_action;
      return {
        ...t,
        lst_action: newAction,
      };
    });

    setSourceLookupLists(newSourceLookupLists);

    //then create a new mapping to be saved into the job
    const newLookupListMapping = newSourceLookupLists.map((t) => {
      return {
        srcListID: t.src_lst_id,
        dstListID: t.dst_lst_id,
        listAction: t.lst_action,
      };
    });
    dispatchJob({
      type: CopyDeviceJobActionTypeEnum.SetLookupListMap,
      lstMap: newLookupListMapping,
    });
  }, [sourceLookupLists]);

  //called when user selects a UoM in the popup dialog
  const onLookupListSelected = useCallback(
    (lookupListInfo: LookupListInfoItem) => {
      //first create a new set of source UoMs so our grid can be updated
      const newSourceLookupLists = sourceLookupLists.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_lst_id === lookupListInfo.src_lst_id) {
          return {
            ...t,
            dst_lst_id: lookupListInfo.dst_lst_id,
            dst_lst_name: lookupListInfo.dst_lst_name,
            lst_action: lookupListInfo.lst_action,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceLookupLists(newSourceLookupLists);

      //then create a new mapping to be saved into the job
      const newLookupListMapping = newSourceLookupLists.map((t) => {
        return {
          srcListID: t.src_lst_id,
          dstListID: t.dst_lst_id,
          listAction: t.lst_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetLookupListMap,
        lstMap: newLookupListMapping,
      });
    },
    [sourceLookupLists]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={sourceLookupLists}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            suppressScrollOnNewData={true}
          />
        </div>
      </Box>
      <SideSheet title="Options" open={true}>
        <CopyOptions job={job} dispatchJob={dispatchJob} />
        <>
          <Typography variant="h6" sx={{ marginLeft: "-11px", marginTop: "10px" }}>
            {"Actions"}
          </Typography>
          <FormControlLabel
            control={<FontAwesomeIconButton icon={faFileImport} iconSize={24} />}
            onClick={() => createMissingLookupLists()}
            label="Create Missing Lookup Lists"
            labelPlacement="end"
          />
        </>
      </SideSheet>

      {/* Select LookupList */}
      {currentLookupListInfoRef.current && (
        <SelectLookupList
          lookupListInfo={currentLookupListInfoRef.current}
          lookupListList={destinationLookupLists}
          open={selectLookupListOpen}
          onClose={() => setSelectLookupListOpen(false)}
          selectLookupList={onLookupListSelected}
        />
      )}
    </>
  );
}
