import React from "react";
import {
  faBarsStaggered,
  faTableRows,
  faMoneyBill1Wave,
  faPhoneArrowUpRight,
} from "@elynx/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import { IRoute, NavCategoryEnum } from "./routes";
import EventProcessingMonitor from "../views/event-processing-monitor/EventProcessingMonitor";
import SystemServices from "../views/system-services/SystemServices";
import CacheConfigurationList from "../views/cache-configuration/CacheConfigurationList";
import OutboundMsgMonitor from "../views/outbound-msg-monitor/OutboundMsgMonitor";

export const phoneRoutes: IRoute[] = [
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "cache-configuration",
    fullPath: "/cache-configuration",
    exact: true,
    component: CacheConfigurationList,
    name: "Cache Configuration",
    icon: () => <FontAwesomeIcon icon={faMoneyBill1Wave} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "event-processing-monitor",
    fullPath: "/event-processing-monitor",
    exact: true,
    component: EventProcessingMonitor,
    name: "Event Processing Monitor",
    icon: () => <FontAwesomeIcon icon={faBarsStaggered} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "outbound-msg-monitor",
    fullPath: "/outbound-msg-monitor",
    exact: true,
    component: OutboundMsgMonitor,
    name: "Outbound Msg Monitor",
    icon: () => <FontAwesomeIcon icon={faPhoneArrowUpRight} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "system-services",
    fullPath: "/system-services",
    exact: true,
    component: SystemServices,
    name: "System Services",
    icon: () => <FontAwesomeIcon icon={faTableRows} size="lg" />,
  },
];
