import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { LookupListInfo } from "./Models";

export type UseDeviceCopyLookupListInfoParameters = {
  jobID: number;
  enabled: boolean;
};

//custom query hook
export function useDeviceCopyLookupListInfo({ jobID, enabled }: UseDeviceCopyLookupListInfoParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.lookupListInfo(jobID);

  return useQuery<LookupListInfo, Error>(
    queryKey,
    async ({ signal }) => {
      const { data } = await ax.get<LookupListInfo>("devcpy/listinfo", {
        signal,
        params: {
          jobID: jobID,
        },
      });
      return data;
    },
    {
      enabled: enabled,
      cacheTime: 0,
    }
  );
}
