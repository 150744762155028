import React, { PropsWithChildren } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import StepMenuActions from "./core/StepMenuActions";
import StepMenuContainer from "./core/StepMenuContainer";
import StepMenuHeader from "./core/StepMenuHeader";
import StepMenuLandingTitle from "./core/StepMenuLandingTitle";
import StepMenuSteps from "./core/StepMenuSteps";
import StepMenuTitle from "./core/StepMenuTitle";

export type StepDefinition = {
  key: number;
  title: string;
  disabled?: boolean;
  valid?: boolean;
};

export type LandingDefinition = {
  key: number;
  title: string;
  disabled?: boolean;
};

export type StepMenuButtonState = {
  label: string;
  visible: boolean;
  disabled: boolean;
};

export type StepMenuProps = {
  landing?: LandingDefinition;
  steps: Array<StepDefinition>;
  activeStep: number;
  title: string;
  nonLinear?: boolean;

  // Buttons
  cancelButtonState?: StepMenuButtonState;
  prevButtonState?: StepMenuButtonState;
  nextButtonState?: StepMenuButtonState;
  saveAsNewButtonState?: StepMenuButtonState;
  saveChangesButtonState?: StepMenuButtonState;
  previewButtonState?: StepMenuButtonState;

  // Events
  onCancel?: () => void;
  onSaveAsNew?: () => void;
  onSaveChanges?: () => void;
  onStepChange?: (fromStep: number, toStep: number) => void;
  onCancelStep?: () => void;
  onPreview?: () => void;
  onStepClick?: (step: StepDefinition) => void;
};

export type StepMenuLandingProps = Record<PropertyKey, unknown>;
export type StepMenuStepProps = Record<PropertyKey, unknown>;

export default function StepMenu({
  landing,
  steps,
  activeStep,
  title,
  nonLinear,
  cancelButtonState,
  prevButtonState,
  nextButtonState,
  saveAsNewButtonState,
  saveChangesButtonState,
  previewButtonState,
  onCancel,
  onSaveAsNew,
  onSaveChanges,
  onStepChange,
  onCancelStep,
  onPreview,
  onStepClick,
  children,
}: PropsWithChildren<StepMenuProps>): JSX.Element {
  const activeStepIndex = steps.findIndex((step) => step.key === activeStep);

  function handlePrev() {
    const prevStep = activeStepIndex - 1 < 0 ? steps[steps.length - 1].key : steps[activeStepIndex - 1].key;

    onStepChange && onStepChange(activeStep, prevStep);
  }

  function handleNext() {
    const nextStep =
      activeStepIndex + 1 > steps.length - 1 ? (landing ? landing.key : steps[0].key) : steps[activeStepIndex + 1].key;

    onStepChange && onStepChange(activeStep, nextStep);
  }

  function handleCancel() {
    if (landing && activeStepIndex >= 0) {
      onCancelStep && onCancelStep();
    } else {
      onCancel && onCancel();
    }
  }

  return (
    <StepMenuContainer>
      <StepMenuHeader>
        <StepMenuTitle title={title} />

        {landing && activeStepIndex === -1 && <StepMenuLandingTitle title={landing.title} />}

        {(!landing || activeStepIndex > -1) && (
          <Stepper
            sx={{
              flexGrow: 1,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            activeStep={activeStepIndex}
            nonLinear={nonLinear}
          >
            {steps?.map((step, index) => (
              <Step
                key={index}
                disabled={step.disabled || activeStepIndex === -1 || activeStepIndex === index}
                completed={step.valid}
              >
                {steps?.length === 1 ? (
                  <Box>{step.title}</Box>
                ) : nonLinear ? (
                  <StepButton
                    onClick={() => onStepClick && onStepClick(step)}
                    sx={{
                      // base
                      "& .MuiStepLabel-root .MuiStepLabel-label, .MuiStepLabel-root .MuiStepIcon-root": {
                        color: "text.primary",
                      },
                      // active
                      "& .MuiStepLabel-root .MuiStepLabel-label.Mui-active, .MuiStepLabel-root.Mui-disabled .MuiStepLabel-label.Mui-active, .MuiStepLabel-root .MuiStepIcon-root.Mui-active, .MuiStepLabel-root.Mui-disabled .MuiStepIcon-root.Mui-active":
                        {
                          color: "secondary.main",
                        },
                      // disabled
                      "& .MuiStepLabel-root.Mui-disabled .MuiStepLabel-label, .MuiStepLabel-root.Mui-disabled .MuiStepIcon-root":
                        {
                          color: "text.disabled",
                        },
                    }}
                  >
                    {step.title}
                  </StepButton>
                ) : (
                  <StepLabel
                    sx={{
                      cursor: "default",
                      "& .MuiStepLabel-label.Mui-active,  .MuiStepIcon-root.Mui-active": {
                        color: "secondary.main",
                      },
                    }}
                  >
                    {step.title}
                  </StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        )}
      </StepMenuHeader>

      <StepMenuSteps>{children}</StepMenuSteps>

      <StepMenuActions>
        {previewButtonState?.visible && (
          <Button onClick={() => onPreview && onPreview()} disabled={previewButtonState.disabled}>
            {previewButtonState.label}
          </Button>
        )}

        <Box style={{ marginLeft: "auto" }}>
          {prevButtonState?.visible && (
            <Button onClick={handlePrev} disabled={prevButtonState.disabled} startIcon={<ArrowBackIcon />}>
              {prevButtonState.label}
            </Button>
          )}

          {cancelButtonState?.visible && (
            <Button onClick={handleCancel} disabled={cancelButtonState.disabled}>
              {cancelButtonState.label}
            </Button>
          )}

          {nextButtonState?.visible && (
            <Button
              style={{ marginLeft: "8px" }}
              color="secondary"
              autoFocus
              onClick={handleNext}
              disabled={nextButtonState.disabled}
              endIcon={<ArrowForwardIcon />}
            >
              {nextButtonState.label}
            </Button>
          )}

          {saveAsNewButtonState?.visible && (
            <Button
              style={{ marginLeft: "8px" }}
              color="secondary"
              autoFocus
              onClick={() => onSaveAsNew && onSaveAsNew()}
              disabled={saveAsNewButtonState.disabled}
            >
              {saveAsNewButtonState.label}
            </Button>
          )}

          {saveChangesButtonState?.visible && (
            <Button
              style={{ marginLeft: "8px" }}
              color="secondary"
              autoFocus
              onClick={() => onSaveChanges && onSaveChanges()}
              disabled={saveChangesButtonState.disabled}
            >
              {saveChangesButtonState.label}
            </Button>
          )}
        </Box>
      </StepMenuActions>
    </StepMenuContainer>
  );
}
