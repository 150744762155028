import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";

export type StepMenuActionsProps = Record<PropertyKey, unknown>;

export default function StepMenuActions({ children }: PropsWithChildren<StepMenuActionsProps>) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        flexShrink: 0,
        padding: 1,
        borderTop: "1px solid",
        borderTopColor: "divider",
        position: "relative",
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      {children}
    </Paper>
  );
}
