import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ETDGroupData } from "./Models";

export type UseETDGroupDataParameters = {
  customerID: number;
  enabled: boolean;
};

//custom query hook
export function useETDGroupData({ customerID, enabled }: UseETDGroupDataParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.groups(customerID);

  return useQuery<Array<ETDGroupData>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<ETDGroupData>>(`exportdata/groups/${customerID}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
