import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";

export type StepMenuHeaderProps = Record<PropertyKey, unknown>;

export default function StepMenuHeader({ children }: PropsWithChildren<StepMenuHeaderProps>) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        flexShrink: 0,
        alignItems: "center",
        borderBottom: "1px solid",
        borderBottomColor: "divider",
        position: "relative",
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      {children}
    </Paper>
  );
}
