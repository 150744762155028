export enum ReportTypeEnum {
  TextingErrorsLastStatus = 0,
}

export type MessagingReportQueryProperties = {
  reportType: ReportTypeEnum;
  timeframe: number;
  startDate: Date | null;
  endDate: Date | null;
};

export type MessagingReportData = {
  customerID: number;
  customerName: string;
  failedCount: number;
  completedCount: number;
  recipient: string;
  lastSuccess: Date | null;
  lastFailure: Date;
  lastResult: string;
  userLogin: string | null;
  usr_contact_email: string | null;
  userFirstName: string | null;
  userLastName: string | null;
};
