import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import { newTokenRequest, loginRequest } from "../../services/msal-service/MSALService";
import { IPublicClientApplication, AuthenticationResult } from "@azure/msal-browser";

//private function used by custom hook for React Query
const getAccessToken = async (msalInstance: IPublicClientApplication) => {
  let accessToken: string | undefined;
  let result: AuthenticationResult | null;
  try {
    //try to get the access token silently (and force the cache to be bypassed)
    //if that fails, acquireTokenRedirect does not actually return a token, for obvious reasons
    result = await msalInstance.acquireTokenSilent(newTokenRequest);
    if (null === result) {
      await msalInstance.acquireTokenRedirect(loginRequest);
    }
    accessToken = result?.accessToken;
  } catch {
    await msalInstance.acquireTokenRedirect(loginRequest);
  }

  return accessToken;
};

//custom query hook
export function useAccessToken(onSuccess?: (data: string | undefined) => void) {
  const { instance } = useMsal();

  return useQuery<string | undefined, Error>("msal-access-token", () => getAccessToken(instance), {
    staleTime: 1800 * 1000,
    refetchInterval: 600 * 1000,
    refetchIntervalInBackground: true,
    refetchOnReconnect: true,
    cacheTime: Infinity,
    onSuccess: onSuccess,
    onError: (err) => console.log(`Error retrieving access token ${err}`),
  });
}
