import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";
import SideSheetContent from "./SideSheetContent";
import SideSheetHeader from "./SideSheetHeader";

export type SideSheetContainerProps = {
  title: string;
  showCloseButton: boolean;
  onClose?: () => void;
  onReset?: () => void;
};

export default function SideSheetContainer({
  title,
  showCloseButton,
  onClose,
  children,
}: PropsWithChildren<SideSheetContainerProps>) {
  return (
    <Stack
      spacing={1}
      direction="column"
      sx={{
        overflow: "hidden",
        flexGrow: 1,
        padding: 1,
      }}
    >
      <SideSheetHeader
        title={title}
        showCloseButton={showCloseButton}
        onClose={onClose}
      />

      <SideSheetContent>{children}</SideSheetContent>

    </Stack>
  );
}
