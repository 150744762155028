import { Box } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Components, Virtuoso, VirtuosoHandle } from "react-virtuoso";

export type VirtualizedListItem = Record<string, unknown>;

export type VirtualizedListProps<TVirtualizedListItem> = {
  items: Array<TVirtualizedListItem>;
  renderItem: (index: number, item: TVirtualizedListItem) => JSX.Element;
  filter?: (item: TVirtualizedListItem) => boolean;
  scrollTo?: number;
  increaseViewportPixels?: number;
};

export default function VirtualizedList<TVirtualizedListItem extends VirtualizedListItem>({
  items,
  renderItem,
  filter,
  scrollTo,
  increaseViewportPixels,
}: VirtualizedListProps<TVirtualizedListItem>) {
  const [filteredItems, setFilteredItems] = useState<Array<TVirtualizedListItem>>(new Array<TVirtualizedListItem>());
  const virtuoso = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    setFilteredItems(typeof filter === "function" ? items.filter((item) => filter(item)) : items);
  }, [items, filter]);

  useEffect(() => {
    if (scrollTo !== undefined && virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: scrollTo,
        //align,
        //behavior
      });
    }
  }, [scrollTo]);

  const Components = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wrapItem = React.forwardRef(({ style, children }: any, listRef: any) => {
      return (
        <Box style={{ ...style }} ref={listRef}>
          {children}
        </Box>
      );
    });
    wrapItem.displayName = "wrapItem";

    return {
      List: wrapItem,
    } as Components<TVirtualizedListItem, unknown>;
  }, []);

  return (
    <Virtuoso
      style={{ display: "flex", flex: "1 1 auto", height: "" }}
      ref={virtuoso}
      components={Components}
      data={filteredItems}
      itemContent={renderItem}
      increaseViewportBy={increaseViewportPixels}
    />
  );
}
