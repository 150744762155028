import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ReportTask, ReportTaskQueryProperties } from "./Models";

export type UseReportTasksParameters = {
  enabled: boolean;
  autoRefreshSeconds: number;
  reportTaskQueryProperties: ReportTaskQueryProperties;
};

//custom query hook
export function useReportTasks({ enabled, autoRefreshSeconds, reportTaskQueryProperties }: UseReportTasksParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.reportTasksCustomer(reportTaskQueryProperties);

  return useQuery<Array<ReportTask>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<ReportTask>>(
        `schedulingtester/reporttasks/${reportTaskQueryProperties.customerID}/${reportTaskQueryProperties.includeTasksWithInactiveSchedules}`
      );
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
    }
  );
}
