import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IconButton } from "@mui/material";
import { RemoveCircle, Edit, Search, Send, ContentCopy } from "@mui/icons-material";

export type MuiIconRendererProps = {
  disabled: boolean;
  iconName: "delete" | "edit" | "search" | "send" | "clone";
  size: "inherit" | "large" | "medium" | "small" | undefined;
  tooltipText: string | undefined;
  shouldDisplay: boolean | ((data: unknown) => boolean);
  onClick: (data: unknown) => void;
} & ICellRendererParams;

export default function MuiIconCellRenderer({
  disabled,
  iconName,
  size,
  tooltipText,
  node,
  shouldDisplay,
  onClick,
}: MuiIconRendererProps) {
  return (
    <>
      {true === (typeof shouldDisplay === "function" ? shouldDisplay(node.data) : shouldDisplay) && (
        <IconButton
          onClick={() => onClick(node.data)}
          disabled={disabled}
          title={tooltipText}
          sx={{ paddingTop: "4px", paddingBottom: "4px" }}
        >
          {"delete" === iconName ? (
            <RemoveCircle fontSize={size} />
          ) : "edit" === iconName ? (
            <Edit fontSize={size} />
          ) : "search" === iconName ? (
            <Search fontSize={size} />
          ) : "send" === iconName ? (
            <Send fontSize={size} />
          ) : "clone" === iconName ? (
            <ContentCopy fontSize={size} />
          ) : (
            <></>
          )}
        </IconButton>
      )}
    </>
  );
}
