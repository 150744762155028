import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { NonPolledDataItem } from "./Models";

export function useResubmitFile() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, NonPolledDataItem>(async (item) => {
    const postObject = {
      container: item.container,
      directory: item.directory,
      file: item.name,
    };

    await ax.post("nonpolleddataops/file/resubmit", postObject);
  });
}
