import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Fab, Stack, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { eLynxUserModel } from "../../queries/elynx-user/Models";
import { useUpdateElynxUserState } from "../../queries/elynx-user/UseUpdateElynxUserState";
import { useElynxUsers } from "../../queries/elynx-user/UseElynxUsers";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { AgGridReact } from "ag-grid-react";
import { ColDef, CsvExportParams } from "ag-grid-community";
import ElynxUserStatusCellRenderer from "../../components/ag-grid-extensions/renderers/ElynxUserStatusCellRenderer";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import { useElynxUser } from "../../queries/elynx-user/UseElynxUser";
import QueryToast from "../../components/query-toast/QueryToast";
import EditUser from "./EditUser";
import { useSaveElynxUser } from "../../queries/elynx-user/UseSaveElynxUser";
import AddUser from "./AddUser";
import { useAlert } from "react-alert";
import useStateRef from "react-usestateref";
import EnableUser from "./EnableUser";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";

export default function ElynxUserList() {
  const confirm = useConfirm();
  const alert = useAlert();
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [mutateUserStateToastOpen, setMutateUserStateToastOpen] = useState(false);
  const [retrieveUserToastOpen, setRetrieveUserToastOpen] = useState(false);
  const [mutateUserToastOpen, setMutateUserToastOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [enableUserOpen, setEnableUserOpen] = useState(false);
  const { isDevOpsMember } = useUserRole();
  const [users, setUsers, usersRef] = useStateRef<Array<eLynxUserModel>>([]);
  const currentUser = useRef<eLynxUserModel | null>(null);
  const setRoleMode = useRef(false);
  const { data: newUserList, isSuccess } = useElynxUsers();
  const {
    mutate: updateUserState,
    isLoading: isMutatingUserState,
    isSuccess: isMutateUserStateSuccess,
    isError: isMutateUserStateError,
  } = useUpdateElynxUserState();
  const {
    mutate: getUser,
    data: userDetail,
    isLoading: isUserRetrieveLoading,
    isSuccess: isUserRetrievedSuccess,
    isError: isUserRetrievedError,
  } = useElynxUser();
  const {
    mutate: updateUser,
    isLoading: isMutatingUser,
    isSuccess: isMutateUserSuccess,
    isError: isMutateUserError,
  } = useSaveElynxUser();
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;

  const openMutateUserStateToast = () => {
    setRetrieveUserToastOpen(false);
    setMutateUserToastOpen(false);
    setMutateUserStateToastOpen(true);
  };

  const openRetrieveUserToast = () => {
    setMutateUserToastOpen(false);
    setMutateUserStateToastOpen(false);
    setRetrieveUserToastOpen(true);
  };

  const openMutateUserToast = () => {
    setRetrieveUserToastOpen(false);
    setMutateUserStateToastOpen(false);
    setMutateUserToastOpen(true);
  };

  //open the edit user component if a new user detail is available
  useEffect(() => {
    if (true === isUserRetrievedSuccess && userDetail) {
      setEditUserOpen(true);
    }
  }, [isUserRetrievedSuccess, userDetail]);

  const onDisableUserClick = useCallback((user: eLynxUserModel) => {
    changeUserState(user, false);
  }, []);

  const onEnableUserInRoleClick = useCallback((user: eLynxUserModel, setRole = true) => {
    //if we are enabling a user, open the EnableUser dialog
    currentUser.current = user;
    setRoleMode.current = setRole;
    setEnableUserOpen(true);
  }, []);

  const changeUserState = useCallback((user: eLynxUserModel, enable: boolean) => {
    //this method will toggle the current state of the user
    const verb = false === enable ? "Disable" : "Enable";
    const confirmDescription =
      false === enable
        ? `Are you sure you want to ${verb.toLowerCase()} eLynx user ${user.gl_login_name}?`
        : `Are you sure you want to ${verb.toLowerCase()} eLynx user ${user.gl_login_name} in role ${user.agent_role}?`;

    //confirm rejects the promise if the user does not confirm
    confirm({
      title: true === setRoleMode.current ? "Confirm Set Role for eLynx User" : `Confirm ${verb} eLynx User`,
      description: confirmDescription,
      allowClose: false,
    })
      .then(() => {
        console.log(`${verb} eLynx user ${user.gl_login_name}`);

        //create the user with the new state and send that to the update user state function
        const newUser = { ...user, user_is_enabled: enable } as eLynxUserModel;
        openMutateUserStateToast();
        updateUserState(newUser);
      })
      .catch(() => {
        console.log(`${verb} eLynx user canceled!`);
      });
  }, []);

  const enableUser = useCallback((user: eLynxUserModel) => {
    changeUserState(user, true);
  }, []);

  const onEditUser = useCallback((user: eLynxUserModel) => {
    //get current user name info from backend
    openRetrieveUserToast();
    getUser(user.gl_id);
  }, []);

  const handleEditUserClose = useCallback(() => {
    setEditUserOpen(false);
  }, []);

  const onSaveUser = useCallback((user: eLynxUserModel) => {
    //if we were asked to save a new user
    //and we know that global login already exists
    //post an alert
    if (
      (!user.gl_id || user.gl_id == 0) &&
      usersRef?.current &&
      usersRef.current.findIndex((u) => u.gl_login_name === user.gl_login_name) > -1
    ) {
      alert.error(`Global login ${user.gl_login_name} already exists!`);
    } else {
      openMutateUserToast();
      updateUser(user);
    }
  }, []);

  const handleEnableUserClose = useCallback(() => {
    setEnableUserOpen(false);
  }, []);

  const handleAddUserClose = useCallback(() => {
    setAddUserOpen(false);
  }, []);

  const getButtonText = useCallback((user: eLynxUserModel) => {
    return user.user_is_enabled === true ? "disable" : "enable";
  }, []);

  const onUserStateButtonClicked = useCallback((user: eLynxUserModel) => {
    return user.user_is_enabled === true ? onDisableUserClick(user) : onEnableUserInRoleClick(user, false);
  }, []);

  const shouldDisplayEditUser = useCallback((user: eLynxUserModel) => {
    return user.user_is_enabled;
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Status",
        field: "user_is_enabled",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: ElynxUserStatusCellRenderer,
      },
      {
        headerName: "Global Login Name",
        field: "gl_login_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "External Login Name",
        field: "gl_external_signin_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "disableUser",
        headerName: "Enable / Disable",
        field: "user_is_enabled",
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: !isDevOpsMember || isMutatingUserState || isUserRetrieveLoading || isMutatingUser,
          buttonText: getButtonText,
          shouldDisplay: true,
          onClick: onUserStateButtonClicked,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Current Role",
        field: "agent_role",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        colId: "changeRole",
        headerName: "Change / Refresh Role",
        field: "user_is_enabled",
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: !isDevOpsMember || isMutatingUserState || isUserRetrieveLoading || isMutatingUser,
          buttonText: "Set Role",
          shouldDisplay: shouldDisplayEditUser,
          onClick: onEnableUserInRoleClick,
        } as ActionButtonRendererProps,
      },
      {
        colId: "editUser",
        headerName: "Edit User",
        field: "user_is_enabled",
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: !isDevOpsMember || isMutatingUserState || isUserRetrieveLoading || isMutatingUser,
          buttonText: "Edit",
          shouldDisplay: shouldDisplayEditUser,
          onClick: onEditUser,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Global Login ID",
        field: "gl_id",
      },
    ] as Array<ColDef>;

    return columns;
  }, [isDevOpsMember, isMutatingUserState, isUserRetrieveLoading, isMutatingUser]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //auto-size all columns to fit their content
  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  //if we get a new user list, put it in our state
  useEffect(() => {
    if (newUserList) {
      setUsers(newUserList);
    }
  }, [isSuccess, newUserList]);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `eLynxUsers_${FormatDateYMD(new Date())}.csv`;
    const params = {
      fileName: defaultFileName,
      columnKeys: ["user_is_enabled", "gl_login_name", "gl_external_signin_name", "agent_role", "gl_id"],
    } as CsvExportParams;
    gridRef.current?.api.exportDataAsCsv(params);
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"eLynx Users"} />
        <Stack
          direction="row"
          spacing={3}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              "& > button": { m: 1 },
              display: "flex",
              width: "20ch",
              marginLeft: "24px",
            }}
          >
            <Button onClick={handleExportCSVClick} variant="contained" disabled={!(users && users.length > 0)}>
              Export CSV
            </Button>
          </Box>
        </Stack>
        {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={users}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              onFirstDataRendered={autoSizeColumns}
              onGridReady={autoSizeColumns}
              onModelUpdated={autoSizeColumns}
              suppressClickEdit={true}
              suppressCellFocus={true}
              alwaysMultiSort={true}
            />
          </div>
        </Box>
      </ContentView>

      {/* Enable user */}
      {currentUser.current && (
        <EnableUser
          user={currentUser.current}
          open={enableUserOpen}
          setRole={setRoleMode.current}
          onClose={handleEnableUserClose}
          enableUser={enableUser}
        />
      )}

      {/* Edit user name */}
      <EditUser open={editUserOpen} onClose={handleEditUserClose} user={userDetail} saveUser={onSaveUser} />

      {/* Add user */}
      <AddUser open={addUserOpen} onClose={handleAddUserClose} saveUser={onSaveUser} />

      {/* Floating Action Button for adding a new user */}
      <Fab
        color="primary"
        aria-label="add"
        title="Add eLynx Agent"
        onClick={() => setAddUserOpen(true)}
        disabled={!isDevOpsMember || isMutatingUserState || isUserRetrieveLoading || isMutatingUser}
      >
        <AddIcon />
      </Fab>

      {/* Toast for enable/disable user */}
      <QueryToast
        toastOpen={mutateUserStateToastOpen}
        isLoading={isMutatingUserState}
        loadingText={"Updating eLynx user..."}
        isSuccess={isMutateUserStateSuccess}
        successText={"eLynx user updated."}
        isError={isMutateUserStateError}
        errorText={"Error updating eLynx user!"}
      />

      {/* Toast for retrieving user details */}
      <QueryToast
        toastOpen={retrieveUserToastOpen}
        isLoading={isUserRetrieveLoading}
        loadingText={"Retrieving eLynx user details..."}
        isSuccess={isUserRetrievedSuccess}
        successText={"eLynx user retrieved."}
        isError={isUserRetrievedError}
        errorText={"Error retrieving eLynx user details!"}
      />

      {/* Toast for saving user */}
      <QueryToast
        toastOpen={mutateUserToastOpen}
        isLoading={isMutatingUser}
        loadingText={"Saving eLynx user..."}
        isSuccess={isMutateUserSuccess}
        successText={"eLynx user saved."}
        isError={isMutateUserError}
        errorText={"Error saving eLynx user!"}
      />
    </>
  );
}
