import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { eLynxUserModel } from "./Models";

export function useSaveElynxUser() {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, eLynxUserModel>(
    async (user) => {
      //we are adding a user if the global login ID is 0
      const url = user.gl_id > 0 ? "elynxusers/update" : "elynxusers/add";
      const { data } = await ax.post<boolean>(url, user);
      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(keys.all);
      },
    }
  );
}
