import {
  DeviceCopyJob,
  DeviceCopyJobStatusEnum,
  FormulaMapItem,
  LookupListMapItem,
  SingleObjectCopy,
  TatGroupMapItem,
  TatMapItem,
  UomMapItem,
  defaultDeviceCopyJob,
} from "../../queries/copy-device/Models";

//these are the strings used by the reducer
export enum CopyDeviceJobActionTypeEnum {
  Reset = "reset",
  SetSrcCustomer = "set-src-customer",
  SetSrcOrganization = "set-src-organization",
  SetDstCustomer = "set-dst-customer",
  SetDstOrganization = "set-dst-organization",
  SetDevices = "set-devices",
  ResetDeviceSelection = "reset-device-selection",
  SetEndDate = "set-end-time",
  SetCopySetpoints = "set-copy-setpoints",
  SetCopyEventLogs = "set-copy-event-logs",
  SetCopyTagValues = "set-copy-tag-values",
  SetDataStartTime = "set-data-start-time",
  SetDataEndTime = "set-data-end-time",
  SetStatus = "set-status",
  SetTatMap = "set-tat-map",
  SetUomMap = "set-uom-map",
  SetFormulaMap = "set-formula-map",
  SetLookupListMap = "set-lookup-list-map",
  SetTatGroupMap = "set-tat-group-map",
}

//define a type per action to enforce typing very strictly
export type Reset = {
  type: CopyDeviceJobActionTypeEnum.Reset;
  newState?: DeviceCopyJob;
};

export type SetSrcCustomer = {
  type: CopyDeviceJobActionTypeEnum.SetSrcCustomer;
  customerID: number;
};

export type SetSrcOrganization = {
  type: CopyDeviceJobActionTypeEnum.SetSrcOrganization;
  organizationID: number;
};

export type SetDstCustomer = {
  type: CopyDeviceJobActionTypeEnum.SetDstCustomer;
  customerID: number;
};

export type SetDstOrganization = {
  type: CopyDeviceJobActionTypeEnum.SetDstOrganization;
  organizationID: number;
};

export type SetDevices = {
  type: CopyDeviceJobActionTypeEnum.SetDevices;
  devices: Array<SingleObjectCopy>;
};

export type ResetDeviceSelection = {
  type: CopyDeviceJobActionTypeEnum.ResetDeviceSelection;
};

export type SetEndDateAction = {
  type: CopyDeviceJobActionTypeEnum.SetEndDate;
  endTime: Date;
};

export type SetCopySetpointsAction = {
  type: CopyDeviceJobActionTypeEnum.SetCopySetpoints;
  include: boolean;
};

export type SetCopyEventLogsAction = {
  type: CopyDeviceJobActionTypeEnum.SetCopyEventLogs;
  include: boolean;
};

export type SetCopyTagValuesAction = {
  type: CopyDeviceJobActionTypeEnum.SetCopyTagValues;
  include: boolean;
};

export type SetDataStartTimeAction = {
  type: CopyDeviceJobActionTypeEnum.SetDataStartTime;
  startTime: Date | null | undefined;
};

export type SetDataEndTimeAction = {
  type: CopyDeviceJobActionTypeEnum.SetDataEndTime;
  endTime: Date | null | undefined;
};

export type SetStatus = {
  type: CopyDeviceJobActionTypeEnum.SetStatus;
  status: DeviceCopyJobStatusEnum;
};

export type SetTatMap = {
  type: CopyDeviceJobActionTypeEnum.SetTatMap;
  tatMap: Array<TatMapItem>;
};

export type SetUomMap = {
  type: CopyDeviceJobActionTypeEnum.SetUomMap;
  uomMap: Array<UomMapItem>;
};

export type SetFormulaMap = {
  type: CopyDeviceJobActionTypeEnum.SetFormulaMap;
  frmMap: Array<FormulaMapItem>;
};

export type SetLookupListMap = {
  type: CopyDeviceJobActionTypeEnum.SetLookupListMap;
  lstMap: Array<LookupListMapItem>;
};

export type SetTatGroupMap = {
  type: CopyDeviceJobActionTypeEnum.SetTatGroupMap;
  tgMap: Array<TatGroupMapItem>;
};

//an action sent to the reducer can be any of the above individual actions
export type CopyDeviceJobActions =
  | Reset
  | SetSrcCustomer
  | SetSrcOrganization
  | SetDstCustomer
  | SetDstOrganization
  | SetDevices
  | ResetDeviceSelection
  | SetEndDateAction
  | SetCopySetpointsAction
  | SetCopyEventLogsAction
  | SetCopyTagValuesAction
  | SetDataStartTimeAction
  | SetDataEndTimeAction
  | SetStatus
  | SetTatMap
  | SetUomMap
  | SetFormulaMap
  | SetLookupListMap
  | SetTatGroupMap;

//and now the reducer itself
export const copyDeviceJobActionReducer = (state: DeviceCopyJob, action: CopyDeviceJobActions): DeviceCopyJob => {
  const newState = createNewState(state, action);
  return newState;
};

const createNewState = (state: DeviceCopyJob, action: CopyDeviceJobActions): DeviceCopyJob => {
  switch (action.type) {
    //for a full reset, just start with a new default object
    case CopyDeviceJobActionTypeEnum.Reset:
      return action.newState ? action.newState : defaultDeviceCopyJob;
    //if the source customer has changed, reset the source org and device selection properties
    //but leave the destination alone
    case CopyDeviceJobActionTypeEnum.SetSrcCustomer:
      return {
        ...state,
        srcCustID: action.customerID,
        srcOrgID: null,
        objects: [],
      };
    case CopyDeviceJobActionTypeEnum.SetSrcOrganization:
      return {
        ...state,
        srcOrgID: action.organizationID,
      };
    case CopyDeviceJobActionTypeEnum.SetDstCustomer:
      return {
        ...state,
        dstCustID: action.customerID,
        dstOrgID: null,
      };
    case CopyDeviceJobActionTypeEnum.SetDstOrganization:
      return {
        ...state,
        dstOrgID: action.organizationID,
      };
    case CopyDeviceJobActionTypeEnum.SetDevices:
      return {
        ...state,
        objects: action.devices,
      };
    case CopyDeviceJobActionTypeEnum.ResetDeviceSelection:
      return {
        ...state,
        objects: [],
      };
    case CopyDeviceJobActionTypeEnum.SetEndDate:
      return {
        ...state,
        endTimestamp: action.endTime,
      };
    case CopyDeviceJobActionTypeEnum.SetCopySetpoints:
      return {
        ...state,
        copySetpoints: action.include,
      };
    case CopyDeviceJobActionTypeEnum.SetCopyEventLogs:
      return {
        ...state,
        copyEventLog: action.include,
      };
    case CopyDeviceJobActionTypeEnum.SetCopyTagValues:
      return {
        ...state,
        copyTagValues: action.include,
      };
    case CopyDeviceJobActionTypeEnum.SetDataStartTime:
      return {
        ...state,
        dataStartTime: action.startTime ? action.startTime : null,
      };
    case CopyDeviceJobActionTypeEnum.SetDataEndTime:
      return {
        ...state,
        dataEndTime: action.endTime ? action.endTime : null,
      };
    case CopyDeviceJobActionTypeEnum.SetStatus:
      return {
        ...state,
        jobStatus: action.status,
      };
    case CopyDeviceJobActionTypeEnum.SetTatMap:
      return {
        ...state,
        tatMappings: action.tatMap,
      };
    case CopyDeviceJobActionTypeEnum.SetUomMap:
      return {
        ...state,
        uomMappings: action.uomMap,
      };
    case CopyDeviceJobActionTypeEnum.SetFormulaMap:
      return {
        ...state,
        formulaMappings: action.frmMap,
      };
    case CopyDeviceJobActionTypeEnum.SetLookupListMap:
      return {
        ...state,
        lookupListMappings: action.lstMap,
      };
    case CopyDeviceJobActionTypeEnum.SetTatGroupMap:
      return {
        ...state,
        tatGroupMappings: action.tgMap,
      };
    default:
      return state;
  }
};
