import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CustomerModel } from "./Models";

export function useCustomerList() {
  const { axios: ax } = useAxios();

  return useQuery<Array<CustomerModel>, Error>(
    keys.all,
    async () => {
      const { data } = await ax.get<Array<CustomerModel>>("customers");
      return data;
    },
    {
      staleTime: 5 * 60 * 1000, //we will use data that is old because the actual customer list doesn't change much
      refetchInterval: 60 * 1000, //but attributes of the customer, such as APIM users do change more frequently, so automatically refetch periodically
    }
  );
}
