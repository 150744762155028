import { LogQueryProperties } from "../../queries/log-data/Models";

export default function getDefaultLogQueryProperties(loggerName?: string): LogQueryProperties {
  return {
    maxCount: 100,
    querySeconds: 30,
    timeframe: 24,
    severityLevel: 2,
    severityMinLevel: true,
    startTime: null,
    endTime: null,
    loggerNames: loggerName && loggerName.length > 0 ? [loggerName] : [],
  };
}
