import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ConfigurationSettingModel, EnvironmentConfigurationQueryProperties } from "./Models";

export function useSaveConfigurationItem({ configType }: EnvironmentConfigurationQueryProperties) {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<Array<ConfigurationSettingModel>, Error, ConfigurationSettingModel>(
    async (configSetting) => {
      const { data } = await ax.put<Array<ConfigurationSettingModel>>(
        `environments/current/${configType}`,
        configSetting
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(keys.envConfigQuery(configType), data);
      },
    }
  );
}
