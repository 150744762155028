import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { MsgQueueInfo } from "./Models";

export type UseOutboundMsgDataParameters = {
  autoRefreshSeconds: number;
};

//custom query hook
export function useOutboundMsgData({ autoRefreshSeconds }: UseOutboundMsgDataParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.all;

  return useQuery<Array<MsgQueueInfo>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<MsgQueueInfo>>("outboundmsg/topic-data");
      return data;
    },
    {
      staleTime: 10 * 60 * 1000, //we will use data that is slightly stale because the data doesn't change often
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
    }
  );
}
