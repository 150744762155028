import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export type PodListDialogProps = {
  podList: Array<string>;
  dialogOpen: boolean;
  onClose: () => void;
};

export default function PodListDialog({ podList, dialogOpen, onClose }: PodListDialogProps) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Pod List</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <List>
            {podList.map((pod, inx) => {
              return (
                <ListItem key={inx} disablePadding>
                  <ListItemText primary={pod} primaryTypographyProps={{ fontFamily: "monospace" }} />
                </ListItem>
              );
            })}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
