import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export type AutocompleteOption = {
  id: number;
  label: string;
};

export type AutocompleteFormInputProps = {
  options: Array<AutocompleteOption>;
} & FormInputProps;

export const FormInputAutocomplete = ({ name, label, options, control, rules, sx }: AutocompleteFormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          autoHighlight
          size="small"
          //this puts the complete option into the value of the field
          //not quite what you might expect for a form,
          //but it lets us grab the ID as a number when using this form field
          onChange={(event, value) => onChange(value)}
          value={value}
          fullWidth
          sx={sx}
          renderInput={(params) => <TextField {...params} label={label} error={!!error} />}
        />
      )}
    />
  );
};
