import { useCallback, useEffect, useReducer } from "react";
import { LandingDefinition, StepDefinition } from "./StepMenu";

export type StepMenuButtonState = {
  label: string;
  visible: boolean;
  disabled: boolean;
};

export enum StepMenuEditModeEnum {
  Create,
  Edit,
}

export type StepMenuState = {
  editMode: StepMenuEditModeEnum;
  landing: LandingDefinition | undefined;
  steps: Array<StepDefinition>;
  activeStep: number;
  title: string;
  cancelButtonState: StepMenuButtonState;
  prevButtonState: StepMenuButtonState;
  nextButtonState: StepMenuButtonState;
  saveAsNewButtonState: StepMenuButtonState;
  saveChangesButtonState: StepMenuButtonState;
  previewButtonState: StepMenuButtonState;
};

enum StepMenuActionType {
  Reset = "RESET",
  ActiveStepChanged = "ACTIVE_STEP_CHANGED",
  TitleChanged = "TITLE_CHANGED",
  CancelButtonDisabledChanged = "CANCEL_BUTTON_DISABLED_CHANGED",
  PrevButtonDisabledChanged = "PREV_BUTTON_DISABLED_CHANGED",
  NextButtonDisabledChanged = "NEXT_BUTTON_DISABLED_CHANGED",
  NextButtonVisibleChanged = "NEXT_BUTTON_VISIBLE_CHANGED",
  SaveAsNewButtonLabelChanged = "SAVE_AS_NEW_BUTTON_LABEL_CHANGED",
  SaveAsNewButtonVisibleChanged = "SAVE_AS_NEW_BUTTON_VISIBLE_CHANGED",
  SaveAsNewButtonDisabledChanged = "SAVE_AS_NEW_BUTTON_DISABLED_CHANGED",
  SaveChangesButtonLabelChanged = "SAVE_CHANGES_BUTTON_LABEL_CHANGED",
  SaveChangesButtonVisibleChanged = "SAVE_CHANGES_BUTTON_VISIBLE_CHANGED",
  SaveChangesButtonDisabledChanged = "SAVE_CHANGES_BUTTON_DISABLED_CHANGED",
  PreviewButtonVisibleChanged = "PREVIEW_BUTTON_VISIBLE_CHANGED",
  PreviewButtonDisabledChanged = "PREVIEW_BUTTON_DISABLED_CHANGED",
}

type StepMenuAction =
  | {
      type: StepMenuActionType.Reset;
      config: {
        editMode: StepMenuEditModeEnum;
        landing: LandingDefinition | undefined;
        steps: Array<StepDefinition>;
      };
    }
  | {
      type: StepMenuActionType.ActiveStepChanged;
      step: number;
    }
  | {
      type: StepMenuActionType.TitleChanged;
      title: string;
    }
  | {
      type: StepMenuActionType.CancelButtonDisabledChanged;
      disabled: boolean;
    }
  | {
      type: StepMenuActionType.PrevButtonDisabledChanged;
      disabled: boolean;
    }
  | {
      type: StepMenuActionType.NextButtonDisabledChanged;
      disabled: boolean;
    }
  | {
      type: StepMenuActionType.NextButtonVisibleChanged;
      visible: boolean;
    }
  | {
      type: StepMenuActionType.SaveAsNewButtonLabelChanged;
      label: string;
    }
  | {
      type: StepMenuActionType.SaveAsNewButtonVisibleChanged;
      visible: boolean;
    }
  | {
      type: StepMenuActionType.SaveAsNewButtonDisabledChanged;
      disabled: boolean;
    }
  | {
      type: StepMenuActionType.SaveChangesButtonLabelChanged;
      label: string;
    }
  | {
      type: StepMenuActionType.SaveChangesButtonVisibleChanged;
      visible: boolean;
    }
  | {
      type: StepMenuActionType.SaveChangesButtonDisabledChanged;
      disabled: boolean;
    }
  | {
      type: StepMenuActionType.PreviewButtonVisibleChanged;
      visible: boolean;
    }
  | {
      type: StepMenuActionType.PreviewButtonDisabledChanged;
      disabled: boolean;
    };

type InitialStateProps = {
  editMode: StepMenuEditModeEnum;
  landing: LandingDefinition | undefined;
  steps: Array<StepDefinition>;
  activeStep?: number;
};

function initialState({
  editMode = StepMenuEditModeEnum.Create,
  landing = undefined,
  steps = new Array<StepDefinition>(),
  activeStep = landing ? landing.key : steps.length > 0 ? steps[0].key : 0,
}: InitialStateProps) {
  const hasLanding = !!landing;

  const nextStepDefinition = !hasLanding && steps.length > 1 ? steps[1] : undefined;

  const initialState: StepMenuState = {
    editMode,
    landing,
    steps,
    activeStep: activeStep,
    title: "New View",
    cancelButtonState: {
      label: "Cancel",
      visible: true,
      disabled: false,
    },
    prevButtonState: {
      label: "",
      visible: false,
      disabled: false,
    },
    nextButtonState: {
      label: nextStepDefinition?.title ?? "",
      visible: !!nextStepDefinition,
      disabled: true,
    },
    saveAsNewButtonState: {
      label: "Save As New",
      visible: hasLanding || !nextStepDefinition,
      disabled: true,
    },
    saveChangesButtonState: {
      label: "Save Changes",
      visible: editMode === StepMenuEditModeEnum.Edit && (hasLanding || !nextStepDefinition), // only edit mode
      disabled: true,
    },
    previewButtonState: {
      label: "Preview",
      visible: true,
      disabled: true,
    },
  };

  return initialState;
}

function stepMenuReducer(state: StepMenuState, action: StepMenuAction): StepMenuState {
  switch (action.type) {
    case StepMenuActionType.Reset:
      return getActiveStepState(
        {
          ...state,
          editMode: action.config.editMode,
          landing: action.config.landing,
          steps: action.config.steps,
        },
        state.activeStep
      );

    case StepMenuActionType.ActiveStepChanged:
      return getActiveStepState(state, action.step);

    case StepMenuActionType.TitleChanged:
      return {
        ...state,
        title: action.title,
      };

    case StepMenuActionType.CancelButtonDisabledChanged:
      return {
        ...state,
        cancelButtonState: {
          ...state.cancelButtonState,
          disabled: action.disabled,
        },
      };

    case StepMenuActionType.PrevButtonDisabledChanged:
      return {
        ...state,
        prevButtonState: {
          ...state.prevButtonState,
          disabled: action.disabled,
        },
      };

    case StepMenuActionType.NextButtonDisabledChanged:
      return {
        ...state,
        nextButtonState: {
          ...state.nextButtonState,
          disabled: action.disabled,
        },
      };

    case StepMenuActionType.NextButtonVisibleChanged:
      return {
        ...state,
        nextButtonState: {
          ...state.nextButtonState,
          visible: action.visible,
        },
      };

    case StepMenuActionType.SaveAsNewButtonLabelChanged:
      return {
        ...state,
        saveAsNewButtonState: {
          ...state.saveAsNewButtonState,
          label: action.label,
        },
      };

    case StepMenuActionType.SaveAsNewButtonVisibleChanged:
      return {
        ...state,
        saveAsNewButtonState: {
          ...state.saveAsNewButtonState,
          visible: action.visible,
        },
      };

    case StepMenuActionType.SaveAsNewButtonDisabledChanged:
      return {
        ...state,
        saveAsNewButtonState: {
          ...state.saveAsNewButtonState,
          disabled: action.disabled,
        },
      };

    case StepMenuActionType.SaveChangesButtonLabelChanged:
      return {
        ...state,
        saveChangesButtonState: {
          ...state.saveChangesButtonState,
          label: action.label,
        },
      };

    case StepMenuActionType.SaveChangesButtonVisibleChanged:
      return {
        ...state,
        saveChangesButtonState: {
          ...state.saveChangesButtonState,
          visible: action.visible,
        },
      };

    case StepMenuActionType.SaveChangesButtonDisabledChanged:
      return {
        ...state,
        saveChangesButtonState: {
          ...state.saveChangesButtonState,
          disabled: action.disabled,
        },
      };

    case StepMenuActionType.PreviewButtonVisibleChanged:
      return {
        ...state,
        previewButtonState: {
          ...state.previewButtonState,
          visible: action.visible,
        },
      };

    case StepMenuActionType.PreviewButtonDisabledChanged:
      return {
        ...state,
        previewButtonState: {
          ...state.previewButtonState,
          disabled: action.disabled,
        },
      };

    default:
      return state;
  }
}

function getActiveStepState(state: StepMenuState, activeStep: number): StepMenuState {
  const hasLanding = !!state.landing;
  const activeStepIndex = state.steps.findIndex((step) => step.key === activeStep);
  const isLanding = hasLanding && activeStepIndex === -1;
  const prevStepDefinition = !isLanding && activeStepIndex > 0 ? state.steps[activeStepIndex - 1] : undefined;
  const nextStepDefinition =
    !isLanding && activeStepIndex < state.steps.length ? state.steps[activeStepIndex + 1] : undefined;

  return {
    ...state,
    activeStep: activeStep,

    prevButtonState: {
      label: prevStepDefinition?.title ?? "",
      visible: !!prevStepDefinition,
      disabled: false,
    },

    nextButtonState: {
      label: nextStepDefinition ? nextStepDefinition.title : state.landing ? state.landing.title : "",
      visible: (hasLanding && !isLanding) || !!nextStepDefinition,
      // Always start the next button out disabled until the step component enables it
      disabled: true,
    },
    saveAsNewButtonState: {
      ...state.saveAsNewButtonState,
      visible: isLanding || (!hasLanding && !nextStepDefinition),
    },
    saveChangesButtonState: {
      ...state.saveChangesButtonState,
      visible: state.editMode === StepMenuEditModeEnum.Edit && (isLanding || (!hasLanding && !nextStepDefinition)), // only edit mode
    },
  };
}

export type SetActiveStepFunctionType = (activeStep: number) => void;
export type SetTitleFunctionType = (title: string) => void;
export type SetButtonLabelFunctionType = (label: string) => void;
export type SetButtonVisibleFunctionType = (visible: boolean) => void;
export type SetButtonDisabledFunctionType = (disabled: boolean) => void;

export type UseStepMenuStateType = {
  state: StepMenuState;
  setActiveStep: SetActiveStepFunctionType;
  setTitle: SetTitleFunctionType;
  setCancelButtonDisabled: SetButtonDisabledFunctionType;
  setPrevButtonDisabled: SetButtonDisabledFunctionType;
  setNextButtonDisabled: SetButtonDisabledFunctionType;
  setNextButtonVisible: SetButtonVisibleFunctionType;
  setSaveAsNewButtonLabel: SetButtonLabelFunctionType;
  setSaveAsNewButtonVisible: SetButtonVisibleFunctionType;
  setSaveAsNewButtonDisabled: SetButtonDisabledFunctionType;
  setSaveChangesButtonLabel: SetButtonLabelFunctionType;
  setSaveChangesButtonVisible: SetButtonVisibleFunctionType;
  setSaveChangesButtonDisabled: SetButtonDisabledFunctionType;
  setPreviewButtonVisible: SetButtonVisibleFunctionType;
  setPreviewButtonDisabled: SetButtonDisabledFunctionType;
};

export function useStepMenuState(
  editMode: StepMenuEditModeEnum,
  landing: LandingDefinition | undefined,
  steps: Array<StepDefinition>
): UseStepMenuStateType {
  const [state, dispatch] = useReducer(stepMenuReducer, { editMode, landing, steps }, initialState);

  const setActiveStep = useCallback(
    (activeStep: number) =>
      dispatch({
        type: StepMenuActionType.ActiveStepChanged,
        step: activeStep,
      }),
    []
  );

  const setTitle = useCallback(
    (title: string) =>
      dispatch({
        type: StepMenuActionType.TitleChanged,
        title: title,
      }),
    []
  );

  const setCancelButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.CancelButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  const setPrevButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.PrevButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  const setNextButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.NextButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  const setNextButtonVisible = useCallback(
    (visible: boolean) =>
      dispatch({
        type: StepMenuActionType.NextButtonVisibleChanged,
        visible: visible,
      }),
    []
  );

  const setSaveAsNewButtonLabel = useCallback(
    (label: string) =>
      dispatch({
        type: StepMenuActionType.SaveAsNewButtonLabelChanged,
        label: label,
      }),
    []
  );

  const setSaveAsNewButtonVisible = useCallback(
    (visible: boolean) =>
      dispatch({
        type: StepMenuActionType.SaveAsNewButtonVisibleChanged,
        visible: visible,
      }),
    []
  );

  const setSaveAsNewButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.SaveAsNewButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  const setSaveChangesButtonLabel = useCallback(
    (label: string) =>
      dispatch({
        type: StepMenuActionType.SaveChangesButtonLabelChanged,
        label: label,
      }),
    []
  );

  const setSaveChangesButtonVisible = useCallback(
    (visible: boolean) =>
      dispatch({
        type: StepMenuActionType.SaveChangesButtonVisibleChanged,
        visible: visible,
      }),
    []
  );

  const setSaveChangesButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.SaveChangesButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  const setPreviewButtonVisible = useCallback(
    (visible: boolean) =>
      dispatch({
        type: StepMenuActionType.PreviewButtonVisibleChanged,
        visible: visible,
      }),
    []
  );

  const setPreviewButtonDisabled = useCallback(
    (disabled: boolean) =>
      dispatch({
        type: StepMenuActionType.PreviewButtonDisabledChanged,
        disabled: disabled,
      }),
    []
  );

  useEffect(() => {
    dispatch({
      type: StepMenuActionType.Reset,
      config: {
        editMode,
        landing,
        steps,
      },
    });
  }, [editMode, landing, steps]);

  return {
    state,
    setActiveStep,
    setTitle,
    setCancelButtonDisabled,
    setPrevButtonDisabled,
    setNextButtonDisabled,
    setNextButtonVisible,
    setSaveAsNewButtonLabel,
    setSaveAsNewButtonVisible,
    setSaveAsNewButtonDisabled,
    setSaveChangesButtonLabel,
    setSaveChangesButtonVisible,
    setSaveChangesButtonDisabled,
    setPreviewButtonVisible,
    setPreviewButtonDisabled,
  };
}
